import React, {useContext, useEffect, useState} from 'react';
import SupportsContext from "../context/supports/SupportsProvider";
import {getSupports} from "../context/supports/SupportsActions";
import Seo from "../components/Seo";
import SupportsHeadline from "../components/supports/SupportsHeadline";
import Spinner from "../components/Spinner";
import Pagination from "../components/Pagination";
import SupportsGriditem from "../components/supports/SupportsGriditem";
import NewsLetter from "../components/NewsLetter";

function Supports() {
    const {dispatch,loading, supports,totalPage} = useContext(SupportsContext)
    const [page,setPage] = useState(1);
    const [search, setSearch] = useState("")

    const getAllSupports = () => {
        getSupports(search,page).then(res => {
            dispatch({type:'GET_SUPPORTS', payload:res})

        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getAllSupports()
    },[search,page])



    return (
        <>
            <Seo
                title="Supports - PluginPress.net"
            />
            <SupportsHeadline/>
            <div className="section-wrap">
                <div className="section">
                    {/* PRODUCT SHOWCASE */}
                    <div className="product-showcase">
                        {/* PRODUCT LIST */}
                        <div className={`product-list grid v4 column3-wrap`}>

                            {loading ? <Spinner/> :  supports.map(support => (


                                    <SupportsGriditem key={support.id} support={support}/>


                                )
                            )}

                        </div>
                        {/* /PRODUCT LIST */}
                    </div>
                    {/* /PRODUCT SHOWCASE */}
                    <div className="clearfix" />
                    {/* PAGER */}
                    <Pagination page={page} setPage={setPage} totalPage={totalPage}/>
                    {/* /PAGER */}
                </div>
            </div>
            <NewsLetter/>
        </>
    );
}

export default Supports;