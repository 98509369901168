import React, {useContext, useEffect, useState} from 'react';
import BundlesContext from "../context/bundles/BundlesProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getBundle} from "../context/bundles/BundlesActions";
import AuthContext from "../context/auth/AuthProvider";
import {arrUrl, defaultScript} from "../context/script/script";
import Spinner from "../components/Spinner";
import {priceTranslator} from "../context/script/priceTranslator";
import {BiLoader} from "@react-icons/all-files/bi/BiLoader";
import {FaCcStripe} from "@react-icons/all-files/fa/FaCcStripe";
import axios from "axios";
import {toast} from "react-toastify";
import BundleProductItem from "../components/bundle/BundleProductItem";
import Seo from "../components/Seo";

const apiLink = process.env.REACT_APP_API_ROOT;
const rootURL = process.env.REACT_APP_URL_ROOT;

function Bundle() {
    const {header,auth} = useContext(AuthContext)
    const {dispatch, bundle} = useContext(BundlesContext)
    const [loading, setLoading] = useState(true)
    const params = useParams();
    const [price,setPrice] = useState("");
    const [priceCut, setPriceCut] = useState("");
    const [pricePack,setPricePack] = useState({})
    const [scripts, setScripts] = useState([]);
    const [purchasing, setPurchasing] = useState(false)
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)

    const defaultScripts = (url) => {
        const script = defaultScript(url);
        setScripts((prevState) => [...prevState, script]);
    };

    const getBundleBySlug = () => {
        getBundle(params.slug).then(res => {
            dispatch({type:"GET_BUNDLE",payload:res})
        }).catch((err) => console.log(err));
    }

    useEffect(() => {
        getBundleBySlug()
    }, []);

    useEffect(() => {
        if(Object.keys(bundle).length>0){
            setPrice(bundle?.prices?.length>0 ? bundle.prices[0].price.toString() : "Free")
            setPriceCut(bundle?.prices?.length>0 && bundle?.prices[0].cut ? bundle?.prices[0].cut: "")
            setPricePack(bundle?.prices?.length>0 ? bundle.prices[0] :{})
            arrUrl.map((url) => defaultScripts(url));
            setLoading(false)
            if(bundle.publish===0){
                navigate('/');
            }
        }
    }, [bundle]);

    const handlePrice = (e) => {
        const pr = e.target.value
        setPrice(pr)
        let priceSelected = bundle.prices.find(item => parseInt(item.price) === parseInt(pr));
        setPricePack(priceSelected)
        setPriceCut(priceSelected?.cut ? priceSelected.cut : "");

    }

    const getStripe = () => {
        setPurchasing(true)
        const url = `${process.env.REACT_APP_API_ROOT}/stripe/create`
        if(auth?.token){
            axios.post(url, {price:JSON.stringify(pricePack),type:"bundle"},header).then(res => {
                if(res?.data?.url){
                    window.location.href = res.data.url
                    setPurchasing(false)
                }else{
                    if(res.data.message === "exist"){
                        toast.info('You have this product already!')
                        setPurchasing(false)
                    }
                }
            })
        }else{
            toast.info('You have to log in')
            navigate('/login')
        }
    }



    return loading ? <Spinner/> :(
        <>
            <Seo
                title={`${bundle.name} - Bundles - PluginPress.net`}
                keywords={bundle.keywords}
            />

            {/* SECTION HEADLINE */}
            <div className="section-headline-wrap">
                <div className="section-headline">
                    <h2>{bundle.name}</h2>
                    <p>
                        Home<span className="separator">/</span>Products
                        <span className="separator">/</span>
                        <span className="current-section">{bundle.name}</span>
                    </p>
                </div>
            </div>
            {/* /SECTION HEADLINE */}
            {/* SECTION */}
            <div className="section-wrap">
                <div className="section">
                    {/* SIDEBAR */}
                    <div className="sidebar right">
                        {/* SIDEBAR ITEM */}
                        <div className="sidebar-item">
                            <p className="price large">
                                {Object.keys(bundle).length>0 && bundle.prices.length>0 ? (
                                    <>
                                        <span>£</span>{price}{priceCut&&<span style={{textDecorationLine:"line-through"}}>£{priceCut}</span>}
                                    </>
                                ) : "Free"}
                            </p>
                            <hr className="line-separator" />
                            {Object.keys(bundle).length>0&&bundle.prices.length>1&&(
                                <label className="select-block">
                                    <select
                                        id="sub-select"
                                        value={price.toString()}
                                        onChange={handlePrice}
                                        style={{backgroundColor: "#2b373a", color: "#00d6b4"}}
                                    >
                                        {bundle.prices.map(pr => (
                                            <option value={pr.price.toString()} key={pr.id}>{priceTranslator(pr)}</option>
                                        ))}
                                    </select>
                                    <svg className="svg-arrow">
                                        <use xlinkHref="#svg-arrow"/>
                                    </svg>
                                </label>
                            )}
                            <hr className="line-separator" />

                            <button className="button mid dark spaced" onClick={getStripe}>
                                <span className="primary">{purchasing ? <BiLoader className="rotating"/> : <><FaCcStripe style={{fontSize:"30px", position:"relative", top:"5px"}}/> <span style={{position:"relative",bottom:"5px"}}>Purchase Now!</span></> }</span>
                            </button>
                            <div className="clearfix" />
                        </div>
                        {/* /SIDEBAR ITEM */}




                    </div>
                    {/* /SIDEBAR */}
                    {/* CONTENT */}
                    <div className="content left">
                        {/* POST */}
                        <article className="post">
                            {/* POST IMAGE */}
                            <div className="post-image">
                                <figure className="product-preview-image large liquid">
                                    <img src={Object.keys(bundle).length>0&&bundle.images.length>0&&`${rootURL}/${bundle.images[0].thumb}` } alt="" />
                                </figure>
                                {/* SLIDE CONTROLS */}
                                <div className="slide-control-wrap">
                                    <div className="slide-control rounded left">
                                        {/* SVG ARROW */}
                                        <svg className="svg-arrow">
                                            <use xlinkHref="#svg-arrow" />
                                        </svg>
                                        {/* /SVG ARROW */}
                                    </div>
                                    <div className="slide-control rounded right">
                                        {/* SVG ARROW */}
                                        <svg className="svg-arrow">
                                            <use xlinkHref="#svg-arrow" />
                                        </svg>
                                        {/* /SVG ARROW */}
                                    </div>
                                </div>

                            </div>
                            {/* /POST IMAGE */}
                            {/* POST IMAGE SLIDES */}
                            <div className="post-image-slides">
                                {/* IMAGE SLIDES WRAP */}
                                <div className="image-slides-wrap full">
                                    {/* IMAGE SLIDES */}
                                    <div
                                        className="image-slides"
                                        data-slide-visible-full="8"
                                        data-slide-visible-small="2"
                                        data-slide-count={Object.keys(bundle).length>0 && bundle.images.length.toString()}
                                    >
                                        {Object.keys(bundle).length>0 && bundle.images.map((image,key) => (

                                            <div className={`image-slide${key===0?" selected": ""}`} key={image.id} style={{background:`url(${rootURL}/${image.thumb})`}}>
                                                <div className="overlay" />
                                                <figure className="product-preview-image thumbnail liquid">
                                                    <img src={`${rootURL}/${image.path}`} alt="" style={{visibility:"hidden"}}/>
                                                </figure>
                                            </div>

                                        ))}
                                    </div>
                                    {/* IMAGE SLIDES */}
                                </div>
                                {/* IMAGE SLIDES WRAP */}
                            </div>
                            {/* /POST IMAGE SLIDES */}
                            <hr className="line-separator" />
                            {/* POST CONTENT */}
                            <div className="post-content">
                                <div dangerouslySetInnerHTML={{__html:bundle.description}}/>
                                <br/>
                                <div className="clearfix" />
                            </div>
                            <div className="product-showcase">
                                <div className="product-list list" style={{width:"98%"}}>
                                    {bundle?.products?.length>0 && bundle.products.map(product=>{
                                        return <BundleProductItem product={product} key={product.id} setTotal={setTotal}/>
                                    })}
                                </div>
                            </div>
                            <div style={{fontSize:25, fontWeight:"bold", position:"relative", right:"15px", float:"right"}}>
                                Total: <span style={{textDecorationLine:"line-through", fontSize:"15px"}}>{total}</span> £{price}

                            </div>
                            <div className="clearfix" />
                        </article>
                        {/* /POST */}

                    </div>
                    {/* CONTENT */}


                </div>
            </div>

            {/* /SECTION */}
        </>
    );
}

export default Bundle;