import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/auth/AuthProvider";
import { getTickets } from "../context/tickets/TicketsActions";
import TicketsContext from "../context/tickets/TicketsProvider";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";
import TicketsHeadline from "../components/tickets/TicketsHeadline";
import Pagination from "../components/Pagination";
import { Link } from "react-router-dom";
import filledStar from "../assets/star-filled.png";
import emptyStar from "../assets/star-empty.png";
import plug from "../assets/plug.png";
import emptyAvatar from '../assets/empty-avatar.png'

const rootURL = process.env.REACT_APP_URL_ROOT;

function Tickets() {
  const { auth, header } = useContext(AuthContext);
  const { loading, totalPage, tickets, dispatch } = useContext(TicketsContext);
  const [page, setPage] = useState(1);

  const getAllTickets = () => {
    getTickets(page, header)
      .then((res) => {
        dispatch({ type: "GET_TICKETS", payload: res });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllTickets();
  }, [page]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Seo title="Tickets - PluginPress.net" />
      <TicketsHeadline />
      {/*Content*/}
      <div className="dashboard-content">
        {/* HEADLINE */}
        <div className="headline buttons two primary">
          <h4>Tickets</h4>
          <Link to="/supports" className="button mid-short primary">
            Add Support Ticket
          </Link>
        </div>
        {/* /HEADLINE */}
        {/* INBOX MESSAGES */}
        <div className="inbox-messages">
          {/* INBOX MESSAGE */}
          {!tickets.length > 0 ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
                You do not have a valid support ticket at the moment; please visit the <Link to="/supports">supports</Link> page.
            </div>
          ) : (
            tickets.map((ticket) => {
              let user = ticket.user.name;
              let avatar = ticket.user.avatar;
              let solvedTag = ticket.solved === 0 ? "secondary" : "primary";
              let openedTag = ticket.solved === 0 ? "-open" : "";
              let iconLetter = "envelope";
              let dateTag = ticket.created_at;
              if (ticket.solved === 2) {
                solvedTag = "third";
                iconLetter = "lock";
              }
              if (ticket.messages.length > 0) {
                user = ticket.messages[ticket.messages.length - 1].user.name;
                avatar =
                  ticket.messages[ticket.messages.length - 1].user.avatar;
                dateTag =
                  ticket.messages[ticket.messages.length - 1].created_at;
              }

              avatar = avatar ? avatar  : emptyAvatar;

              return (
                <div className="inbox-message">
                  <div className="inbox-message-actions">
                    {/* STARRED */}
                    <div className="starred">
                      <img
                        src={ticket.used === 1 ? emptyStar : filledStar}
                        alt="star-filled"
                        className={!ticket.opened ? "hidden" : "visible"}
                      />
                      <img
                        src={plug}
                        alt="star-filled"
                        className={ticket.opened ? "hidden" : "visible"}
                      />
                    </div>
                    {/* /STARRED */}
                  </div>
                  <div className="inbox-message-author">
                    <figure className="user-avatar">
                      <img src={`${rootURL}/${avatar}`} alt="user-img" />
                    </figure>
                    <p className="text-header">
                      {user}
                      <span className="message-icon icon-envelope secondary" />
                    </p>
                  </div>
                  <Link to={`/ticket/${ticket.id}`} reloadDocument={true}>
                    <div className="inbox-message-content">
                      <p className="text-header">{ticket.support.title}</p>
                      <p className="description">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium
                      </p>
                    </div>
                  </Link>
                  <div className="inbox-message-type">
                    <span
                      className={`message-icon icon-${iconLetter}${openedTag} ${solvedTag}`}
                    />
                  </div>
                  <div className="inbox-message-date">
                    <p>{new Date(dateTag).toDateString()}</p>
                  </div>
                </div>
              );
            })
          )}
          {/* INBOX MESSAGE */}
          {/* PAGER */}

          {/* /PAGER */}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Pagination totalPage={totalPage} setPage={setPage} page={page} />
        </div>

        {/* /INBOX MESSAGES */}
      </div>
    </>
  );
}

export default Tickets;
