import React from 'react';
import {Link} from "react-router-dom";
const rootURL = process.env.REACT_APP_URL_ROOT;
function BlogsItem({blog}) {
    const {title,images, content,categories,slug} = blog;
    let cover = "";
    images.map((image) => (cover = image.thumb));
    let category = {};
    categories.map(cat => category = cat);
    return (

            <div className="blog-post-preview-item column">
                <Link to={`/blog/${slug}`} reloadDocument>
                    <figure className="product-preview-image big liquid">
                        <img src={`${rootURL}/${cover}`} alt={title} />
                    </figure>
                </Link>
                {/* BLOG POST PREVIEW ITEM INFO */}
                <div className="blog-post-preview-item-info">
                    <p className="text-header mid">
                        <Link to={`/blog/${slug}`} reloadDocument>
                            {title}
                        </Link>
                    </p>
                    <div className="meta-line">
                        <Link to={`/category/${category.slug}`} reloadDocument>
                            <p className="category primary">{category.name}</p>
                        </Link>
                        <p>{new Date(blog.created_at).toDateString()}</p>
                    </div>
                    <p className="description-preview" dangerouslySetInnerHTML={{__html:`${content.substring(0,270)}...`}}/>
                    {/* METADATA */}

                    {/* /METADATA */}
                    <Link to={`/blog/${slug}`} className="more-button primary" reloadDocument>
                        Read More...
                    </Link>
                </div>
                {/* /BLOG POST PREVIEW ITEM INFO */}
            </div>

    );
}

export default BlogsItem;