import React from 'react';
import {Link} from "react-router-dom";
const rootURL = process.env.REACT_APP_URL_ROOT;
function LatestPostsItem({post}) {
    const {title,images, content,categories,slug} = post;
    let cover = "";
    images.map((image) => (cover = image.thumb));
    let category = {};
    categories.map(cat => category = cat);

    return (
        <div className="item-preview">
            <Link to={`/blog/${slug}`} reloadDocument>
                <figure className="product-preview-image small liquid">
                    <img src={`${rootURL}/${cover}`} alt={title} />
                </figure>
            </Link>
            <Link to={`/blog/${slug}`} reloadDocument>
                <p className="text-header small">
                    {title}
                </p>
            </Link>
            <p className="category tiny primary">
                <Link to={`/category/${category.slug}`} reloadDocument>
                    {category.name}
                </Link>
            </p>
            {/* METADATA */}
            {/*<div className="metadata">*/}
            {/*    /!* META ITEM *!/*/}
            {/*    <div className="meta-item">*/}
            {/*        <span className="icon-bubble" />*/}
            {/*        <p>05</p>*/}
            {/*    </div>*/}
            {/*    /!* /META ITEM *!/*/}
            {/*    /!* META ITEM *!/*/}
            {/*    <div className="meta-item">*/}
            {/*        <span className="icon-eye" />*/}
            {/*        <p>68</p>*/}
            {/*    </div>*/}
            {/*    /!* /META ITEM *!/*/}
            {/*</div>*/}
            {/* /METADATA */}
        </div>
    );
}

export default LatestPostsItem;