import axios from "axios";

export const getPlugins = async (search,page)=>{
    const url = `${process.env.REACT_APP_API_ROOT}/plugins?page=${page}`
    return await axios.post(url, {search, page})
}

export const getPlugin = async (slug) => {
    const url = `${process.env.REACT_APP_API_ROOT}/plugin/${slug}`
    return await axios.get(url);
}