import axios from "axios";

export const getSupports = async (search,page)=>{
    const url = `${process.env.REACT_APP_API_ROOT}/supports?page=${page}`
    return await axios.post(url, {search, page})
}

export const getSupport = async (slug) => {
    const url = `${process.env.REACT_APP_API_ROOT}/support/${slug}`
    return await axios.get(url);
}