import axios from "axios";

export const getProducts = async (search,page)=>{
    const url = `${process.env.REACT_APP_API_ROOT}/products?page=${page}`
    return await axios.post(url, {search, page})
}

export const getLastProducts = async ()=>{
    const url = `${process.env.REACT_APP_API_ROOT}/lastproducts`
    return await axios.get(url)
}

export const getProduct = async (slug) => {
    const url = `${process.env.REACT_APP_API_ROOT}/product/${slug}`
    return await axios.get(url);
}