import React from 'react';

function BlogsHeadline() {
    return (
        <div className="section-headline-wrap">
            <div className="section-headline">
                <h2>Blogs</h2>
                <p>Home<span className="separator">/</span><span className="current-section">Blogs</span></p>
            </div>
        </div>
    );
}

export default BlogsHeadline;