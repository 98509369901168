import React from 'react';
import blank from '../assets/blank.png'

function Blank() {
    return (
        <div style={{padding:"40px"}}>
            <div style={{textAlign:"center"}}><img src={blank} alt=""/></div>
            <div style={{textAlign:"center", fontWeight:"900", fontSize:"100px", color:"#2b373a"}}>404</div>
            <div style={{textAlign:"center", fontWeight:"900", fontSize:"70px", color:"#2b373a"}}>Page not found!</div>
        </div>
    );
}

export default Blank;