export const priceTranslator = (item) => {
    let title = "";

    if (item.value === "credit") {
        title = `Credit: ${item.credits}`;

    } else if (item.value === "sub") {
        if (item.sub === "monthly") {
            title = "Monthly Subscription";
        } else if (item.sub === "semi") {
            title = "Semi Annual Subscription";

        } else {
            title = "Annual Subscription";

        }
    } else {
        title = "Normal Price";


    }

    return title
}