import {createContext, useState} from "react";
import {toast} from "react-toastify";

const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
    const [auth,setAuth] = useState(localStorage.getItem('PluginPressTokenValue') && {
        token:localStorage.getItem('PluginPressTokenValue'),
        name: localStorage.getItem('PluginPressNameValue'),
        coins: localStorage.getItem('PluginPressCoinsValue'),
        email: localStorage.getItem('PluginPressEmailValue'),
        avatar: localStorage.getItem('PluginPressAvatarValue'),
        countryID:localStorage.getItem('PluginPressCountryID'),
        aboutme: localStorage.getItem('PluginPressAboutMeValue'),
        address: localStorage.getItem('PluginPressAddressValue'),
        address2: localStorage.getItem('PluginPressAddress2Value'),
        city: localStorage.getItem('PluginPressCityValue'),
        postal: localStorage.getItem('PluginPressPostalValue'),
        company: localStorage.getItem('PluginPressCompanyValue'),
        street : localStorage.getItem('PluginPressStreetValue'),
        websites: JSON.parse(localStorage.getItem('PluginPressWebSitesValue')),
        webcount: localStorage.getItem('PluginPressWebCountValue'),
        sub: localStorage.getItem('PluginPressSubValue'),
        apiKey: localStorage.getItem('PluginPressApiKey'),
        id: localStorage.getItem('PluginPressIDValue')

    } )

    const logOutNow = () => {
        localStorage.removeItem(`PluginPressTokenValue`)
        localStorage.removeItem('PluginPressEmailValue')
        localStorage.removeItem('PluginPressCoinsValue')
        localStorage.removeItem('PluginPressNameValue')
        localStorage.removeItem('PluginPressAvatarValue')
        localStorage.removeItem('PluginPressAboutMeValue')
        localStorage.removeItem('PluginPressAddressValue')
        localStorage.removeItem('PluginPressAddress2Value')
        localStorage.removeItem('PluginPressCityValue')
        localStorage.removeItem('PluginPressPostalValue')
        localStorage.removeItem('PluginPressCountryID')
        localStorage.removeItem('PluginPressCompanyValue')
        localStorage.removeItem('PluginPressWebSitesValue')
        localStorage.removeItem('PluginPressWebCountValue')
        localStorage.removeItem('PluginPressIDValue')
        localStorage.removeItem('PluginPressSubValue')

        setAuth(null);
        document.getElementById('hiddenLoginLink').click();
        toast.info("Session expired!");
    };

    const header = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth && auth.token}`,
        },
    }

    return <AuthContext.Provider value={{auth, setAuth, logOutNow,header}}>
        {children}
    </AuthContext.Provider>
}

export default AuthContext