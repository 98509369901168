import { createContext, useContext, useState } from "react";
import emptyAvatar from "../../assets/empty-avatar.png";
import AuthContext from "../auth/AuthProvider";
const GeneralContext = createContext({});
const storageUrl = process.env.REACT_APP_URL_ROOT;
export const GeneralProvider = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const [avatar, setAvatar] = useState(
    auth
      ? auth.avatar
        ? `${storageUrl}/${auth.avatar}`
        : emptyAvatar
      : emptyAvatar
  );
  const [name, setName] = useState(auth ? auth.name : "");
  const [email, setEmail] = useState(auth ? auth.email : "");
  const [sub, setSub] = useState(auth ? auth.sub : 1);
  const [aboutMe, setAboutMe] = useState(auth ? auth.aboutme : "");
  const [websites, setWebsites] = useState(auth?.websites ? auth.websites : []);
  const [webcount, setWebcount] = useState(auth?.webcount ? auth.webcount : 0);
  const [coins, setCoins] = useState(auth?.coins ? auth.coins : 0);
  const [coinTrigger, setCoinTrigger] = useState(0);

  const [billing, setBilling] = useState({
    company: auth && auth.company,
    address: auth && auth.address,
    address2: auth && auth.address2,
    city: auth && auth.city,
    postal: auth && auth.postal,
    country_id: auth && auth.countryID,
    street: auth && auth.street,
  });

  const numberWithDot = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <GeneralContext.Provider
      value={{
        avatar,
        setAvatar,
        name,
        setName,
        email,
        setEmail,
        aboutMe,
        setAboutMe,
        billing,
        setBilling,
        webcount,
        setWebcount,
        websites,
        setWebsites,
        coins,
        setCoins,
        coinTrigger,
        setCoinTrigger,
        numberWithDot,
        sub,
        setSub,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralContext;
