const pluginsReducer = (state, action) => {
    switch (action.type){
        case 'GET_PLUGINS':
            return {
                ...state,
                plugins: action.payload.data.data,
                totalPage: action.payload.data.last_page,
                loading: false
            }
        case 'GET_PLUGIN':
            return {
                ...state,
                plugin:action.payload.data,
                loading:false
            }
        case 'SET_LOADING_FALSE':
            return {
                ...state,
                loading:false,
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading:true,
            }
        default:
            return state
    }
}

export default pluginsReducer