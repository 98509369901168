import React from 'react';
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom";

function Consent() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="GOT IT"
            cookieName="pp-cookie"
            overlay={true}
            containerClasses={"pp-consent"}
            buttonStyle={{
                fontFamily: "Titillium Web",
                backgroundColor: "rgb(60,63,65)",
                fontSize: "16px",
                padding: "10px 20px",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "bold",
            }}
            expires={365}
        >

            <p style={{color: "#fff"}}>This website uses cookies to enhance your user experience and provide our
                services. Cookies are essential for the proper functioning of our website, including critical features
                like our membership system. By using this website, you are giving your consent to the use of
                cookies.</p>
            <br/>
            <h3 style={{textAlign: "left"}}>What Are Cookies?</h3>
            <p style={{color: "#fff"}}>Cookies are small text files placed on your device or browser by the websites you
                visit. These files help us understand how the website functions better and improve our interaction with
                you.</p>
            <br/>
            <h3 style={{textAlign: "left"}}>Vital Importance for Membership System</h3>
            <p style={{color: "#fff"}}>We require cookies to ensure the smooth operation of our membership system and to
                provide you with personalized content. Cookies help us remember your login details, secure your session,
                and deliver customized content to you. Without these cookies, some essential features of our website may
                become non-functional.</p>
            <br/>
            <h3 style={{textAlign: "left"}}>Cookie Consent</h3>
            <p style={{color: "#fff"}}>By continuing to use this website, you are indicating your consent to the use of
                cookies for the purposes stated in this text.</p>
            <br/>
            <p style={{color: "#fff"}}>Please review our <Link to="/privacy-policy" style={{fontWeight:"bold", color:"#fff"}}>Privacy Policy</Link> for more
                information about how we handle your data.</p>
            <br/>
            <h3 style={{textAlign:"left"}}>Thank you</h3>
            <p style={{color: "#fff"}}>This revised text only presents the option to accept cookies, emphasizing their importance for the
                website's functionality, especially the membership system. Users can proceed with using the website by
                simply continuing to browse, implying their consent to cookie usage. You can customize this text to
                align with your website's needs and policies.</p>
        </CookieConsent>
    );
}

export default Consent;