import React, {useEffect} from 'react';
import logoSmall from '../assets/logo_small.png'
import {defaultScript} from "../context/script/script";
import {Link} from "react-router-dom";

function SideMenu() {


    return (
        <div id="mobile-menu" className="side-menu left closed">
            {/* SVG PLUS */}
            <svg className="svg-plus">
                <use xlinkHref="#svg-plus" />
            </svg>
            {/* /SVG PLUS */}
            {/* SIDE MENU HEADER */}
            <div className="side-menu-header">
                <figure className="logo small">
                    <img src={logoSmall} alt="logo" />
                </figure>
            </div>
            {/* /SIDE MENU HEADER */}
            {/* SIDE MENU TITLE */}
            <p className="side-menu-title">Main Links</p>
            {/* /SIDE MENU TITLE */}
            {/* DROPDOWN */}
            <ul className="dropdown dark hover-effect interactive">
                {/* DROPDOWN ITEM */}
                <li className="dropdown-item">
                    <Link to="/">Home</Link>
                </li>
                {/* /DROPDOWN ITEM */}
                {/* DROPDOWN ITEM */}
                <li className="dropdown-item">
                    <Link to="/how-to-shop">How to Shop</Link>
                </li>
                {/* /DROPDOWN ITEM */}
                {/* DROPDOWN ITEM */}
                <li className="dropdown-item">
                    <Link to="/products">Products</Link>
                </li>
                {/* /DROPDOWN ITEM */}
                {/* DROPDOWN ITEM */}
                <li className="dropdown-item">
                    <Link to="/supports">Supports</Link>
                </li>
                {/* /DROPDOWN ITEM */}
                {/* DROPDOWN ITEM */}
                <li className="dropdown-item">
                    <Link to="/plugins">Plugins</Link>
                </li>
                {/* /DROPDOWN ITEM */}
                {/* DROPDOWN ITEM */}
                <li className="dropdown-item">
                    <Link to="/blogs">Blogs</Link>
                </li>
                {/* /DROPDOWN ITEM */}
            </ul>
            {/* /DROPDOWN */}
        </div>

    );
}

export default SideMenu;