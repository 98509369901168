import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "rc-slider/assets/index.css";
import "react-toggle/style.css"
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/auth/AuthProvider";
import { GeneralProvider } from "./context/general/GeneralProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <AuthProvider>
            <GeneralProvider>
                <App />
            </GeneralProvider>
        </AuthProvider>
    </Router>
);
