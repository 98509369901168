import React from 'react';
const rootURL = process.env.REACT_APP_URL_ROOT;
function Author({user}) {
    return (
        <div className="blog-author">
            <a href="#">
                <figure className="user-avatar medium liquid">
                    <img src={`${rootURL}/${user.avatar}`} alt={user.name} />
                </figure>
            </a>
            <p className="text-header">
                <a href="#">{user.name}</a>
            </p>
            <p className="timestamp">Post Author</p>
            <p>
                {user.aboutme}
            </p>
            {/* SHARE LINKS */}
            {/*<ul className="share-links">*/}
            {/*    <li>*/}
            {/*        <a href="#" className="fb" />*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <a href="#" className="twt" />*/}
            {/*    </li>*/}
            {/*</ul>*/}
            {/* /SHARE LINKS */}
        </div>
    );
}

export default Author;