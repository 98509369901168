import React, {useContext, useEffect} from "react";
import Article from "../components/blog/Article";
import Author from "../components/blog/Author";
import Side from "../components/blog/Side";
import { useParams } from "react-router-dom";
import { getBlog } from "../context/article/ArticleActions";
import ArticleContext from "../context/article/ArticleProvider";
import Spinner from "../components/Spinner";

function Blog() {
  const { loading, blog, dispatch } = useContext(ArticleContext);
  const params = useParams();

  const getPost = () => {
    getBlog(params.slug)
      .then((res) => dispatch({ type: "GET_BLOG", payload: res }))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPost()
  },[]);

  return loading ? <Spinner/> : (
    <>
      <div className="section-wrap">
        <div className="section">
          {/* CONTENT */}
          <div className="content left">
            {/* POST */}
            <Article article={blog}/>
            {/* /POST */}
            {/* BLOG AUTHOR */}
            <Author user={blog.user}/>
            {/* /BLOG AUTHOR */}
            {/* BLOG COMMENTS */}
            {/*!!!!!!!Comment here!!!!!!!!!!!*/}
            {/* /BLOG COMMENTS */}
          </div>
          {/* CONTENT */}
          {/* SIDEBAR */}
          <Side blog={blog} />
          {/* /SIDEBAR */}
        </div>
      </div>
    </>
  );
}

export default Blog;
