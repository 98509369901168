import React, { useContext, useEffect, useState } from "react";
import BlogsHeadline from "../components/blogs/BlogsHeadline";
import Seo from "../components/Seo";
import ArticleContext from "../context/article/ArticleProvider";
import { getBlogs } from "../context/article/ArticleActions";
import Spinner from "../components/Spinner";
import Pagination from "../components/Pagination";
import BlogsItem from "../components/blogs/BlogsItem";

function Blogs() {
  const { dispatch, loading, blogs, totalPage } = useContext(ArticleContext);
  const [page, setPage] = useState(1);

  const getAllBlogs = (pg) => {
    getBlogs("pluginpress.net", pg)
      .then((res) => dispatch({ type: "GET_BLOGS", payload: res }))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllBlogs(page);
  }, [page]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Seo title="Blogs - PluginPress.net" />
      <BlogsHeadline />
      <div className="section-wrap">
        <div className="section">
          <div className="blog-post-preview v1 column3-wrap">
            {/* BLOG POST PREVIEW */}
            {blogs.length > 0 &&
              blogs.map((blog) => <BlogsItem blog={blog} key={blog.id} />)}
            {/* /BLOG POST PREVIEW */}
          </div>
          {/* PAGER */}
          <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          {/* /PAGER */}
        </div>
      </div>
    </>
  );
}

export default Blogs;
