import React, {useContext, useEffect, useState} from 'react';
import SeoContext from "../context/seo/SeoProvider";
import {useParams} from "react-router-dom";
import {getCategoryBlogs} from "../context/seo/SeoActions";
import BlogsItem from "../components/blogs/BlogsItem";
import Pagination from "../components/Pagination";
import CategoryHeadline from "../components/category/CategoryHeadline";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";

function Category() {
    const { dispatch, loading, blogs, title, totalPage } = useContext(SeoContext)
    const params = useParams();

    const [page, setPage] = useState(1);

    const getAllBlogs = (pg) => {
        getCategoryBlogs("pluginpress.net", params.slug,pg)
            .then((res) => dispatch({ type: "GET_BLOGS", payload: res }))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllBlogs(page);
    }, [page]);

    return loading ? <Spinner/> : (
        <>
            <Seo title={`Category: ${title} - Blogs`} />
            <CategoryHeadline title={title}/>
            <div className="section-wrap">
                <div className="section">
                    <div className="blog-post-preview v1 column3-wrap">
                        {/* BLOG POST PREVIEW */}
                        {blogs.length > 0 &&
                            blogs.map((blog) => <BlogsItem blog={blog} key={blog.id} />)}
                        {/* /BLOG POST PREVIEW */}
                    </div>
                    {/* PAGER */}
                    <Pagination page={page} setPage={setPage} totalPage={totalPage} />
                    {/* /PAGER */}
                </div>
            </div>
        </>
    );
}

export default Category;