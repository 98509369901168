import React from 'react';

import {Link} from "react-router-dom";

function NewsLetter() {
    // const [email, setEmail] = useState("")

    // const subscribe = () => {
    //     if(isEmail(email)){
    //         const url = `${process.env.REACT_APP_API_ROOT}/newsletter`
    //         axios.post(url,{email}).then(res=>{
    //             if(res.data.status === "ok"){
    //                 toast.success('We added your email address to our NewsLetter, thank you for subscribe!')
    //             }
    //         }).catch(err=>{
    //             if(err.response.status===422){
    //                 return toast.info('You have already registered your email address!')
    //             }
    //             return toast.warning(err.response.data.message)
    //         })
    //     }else{
    //         toast.warning('Please enter a correct email address')
    //     }
    //     setEmail("")
    // }

    return (
        <div id="subscribe-banner-wrap">
            <div id="subscribe-banner">
                {/* SUBSCRIBE CONTENT */}
                <div className="subscribe-content">
                    {/* SUBSCRIBE HEADER */}
                    <div className="subscribe-header">
                        <figure>
                            <img src="/images/news_icon.png" alt="subscribe-icon"/>
                        </figure>
                        {/*<p className="subscribe-title" style={{position:"relative", bottom:"10px"}}>Searching for innovative features to enhance our plugins, or*/}
                        {/*    embarking on exciting new projects?</p>*/}
                        <p  style={{position:"relative", top:"10px", fontSize:"18px", fontWeight:"bold"}}>Searching for innovative features to enhance our plugins, or
                            embarking on exciting new projects?</p>
                    </div>

                     {/*/SUBSCRIBE HEADER*/}
                     {/*SUBSCRIBE FORM*/}
                    <div className="subscribe-form">
                        <Link to="/contact" className="button mid dark" style={{width:"50%"}}>Hire <span className="primary">PluginPress</span>!</Link>
                    </div>
                     {/*/SUBSCRIBE FORM*/}
                </div>
                {/* /SUBSCRIBE CONTENT */}
            </div>
        </div>
    );
}

export default NewsLetter;