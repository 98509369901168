import React from 'react';
import {Link} from "react-router-dom";
const rootURL = process.env.REACT_APP_URL_ROOT;
const storageUrl = process.env.REACT_APP_URL_ROOT;

function PluginsGridItem({plugin}) {
    const {name,description,slug} = plugin;
    let cover = "";
    plugin.images.map((image) => (cover = image.thumb));
    return (
        <div className="product-item column">
            {/* PRODUCT PREVIEW ACTIONS */}
            <div className="product-preview-actions">
                {/* PRODUCT PREVIEW IMAGE */}
                <figure className="product-preview-image">
                    <img src={`${rootURL}/${cover}`} alt="product-image" />
                </figure>
                {/* /PRODUCT PREVIEW IMAGE */}
                {/* PREVIEW ACTIONS */}
                <div className="preview-actions">
                    {/* PREVIEW ACTION */}
                    <div className="preview-action">
                        <Link to={`/plugin/${slug}`} reloadDocument>
                            <div className="circle tiny primary">
                                <span className="icon-tag" />
                            </div>
                        </Link>
                        <Link to={`/plugin/${slug}`} reloadDocument>
                            <p>Go to Item</p>
                        </Link>
                    </div>
                    {/* /PREVIEW ACTION */}
                    {/* PREVIEW ACTION */}
                    <div className="preview-action">
                        <a href="#">
                            <div className="circle tiny secondary">
                                <span className="icon-heart" />
                            </div>
                        </a>
                        <a href="#">
                            <p>Favourites +</p>
                        </a>
                    </div>
                    {/* /PREVIEW ACTION */}
                </div>
                {/* /PREVIEW ACTIONS */}
            </div>
            {/* /PRODUCT PREVIEW ACTIONS */}
            {/* PRODUCT INFO */}
            <div className="product-info">
                <Link to={`/plugin/${slug}`} reloadDocument>
                    <p className="text-header">{name}</p>
                </Link>
                <p className="product-description" dangerouslySetInnerHTML={{__html:`${description.substring(0,50)}...`}}/>
            </div>
            {/* /PRODUCT INFO */}
           
            {/* /USER RATING */}
        </div>
    );
}

export default PluginsGridItem;