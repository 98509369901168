const supportsReducer = (state,action) => {
    switch(action.type){
        case 'GET_SUPPORTS':
            return {
                ...state,
                supports: action.payload.data.data,
                totalPage: action.payload.data.last_page,
                loading: false
            }
        case 'GET_SUPPORT':
            return {
                ...state,
                support:action.payload.data,
                loading:false
            }
        case 'SET_LOADING_FALSE':
            return {
                ...state,
                loading:false,
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading:true,
            }
        default:
            return state
    }
}

export default supportsReducer