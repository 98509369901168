import React from 'react';

function BundlesHeadline() {
    return (
        <div className="section-headline-wrap">
            <div className="section-headline">
                <h2>Bundles</h2>
                <p>
                    Home<span className="separator">/</span>Bundles

                </p>
            </div>
        </div>
    );
}

export default BundlesHeadline;