import React from "react";
import {Link} from "react-router-dom";

function MainMenu() {
  return (
    <div className="main-menu-wrap dark">
      <div className="menu-bar">
        <nav>
          <ul className="main-menu">
            {/* MENU ITEM */}
            <li className="menu-item">
              <Link to='/' reloadDocument>Home</Link>
            </li>
            {/* /MENU ITEM */}
            {/* MENU ITEM */}
            <li className="menu-item">
              <Link to="/how-to-shop">How to shop</Link>
            </li>
            {/* /MENU ITEM */}
            {/* MENU ITEM */}
            <li className="menu-item">
              <Link to="/products">Products</Link>
            </li>
            {/* /MENU ITEM */}
            {/* MENU ITEM */}
            <li className="menu-item">
              <Link to="/supports">Supports</Link>
            </li>
            {/* /MENU ITEM */}
            {/* MENU ITEM */}
            {/*<li className="menu-item">*/}
            {/*  <Link to="/bundles">Bundles</Link>*/}
            {/*</li>*/}
            {/* /MENU ITEM */}
            {/* MENU ITEM */}
            <li className="menu-item">
              <Link to="/plugins">Plugins</Link>
            </li>
            {/* /MENU ITEM */}
            {/* MENU ITEM */}
            <li className="menu-item">
              <Link to="/blogs">Blogs</Link>
            </li>
            {/* /MENU ITEM */}



          </ul>
        </nav>

      </div>
    </div>
  );
}

export default MainMenu;
