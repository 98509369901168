import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import PluginsContext from "../context/plugins/PluginProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {arrUrl, defaultScript} from "../context/script/script";
import {getPlugin} from "../context/plugins/PluginsActions";
import Spinner from "../components/Spinner";
import {FaWordpress} from "@react-icons/all-files/fa/FaWordpress";
import Seo from "../components/Seo";

const apiLink = process.env.REACT_APP_API_ROOT;
const rootURL = process.env.REACT_APP_URL_ROOT;

function Plugin() {
    const {header,auth} = useContext(AuthContext)
    const { dispatch, plugin } = useContext(PluginsContext)
    const [loading, setLoading] = useState(true)
    const params = useParams();
    const [scripts, setScripts] = useState([]);
    const navigate = useNavigate()

    const defaultScripts = (url) => {
        const script = defaultScript(url);
        setScripts((prevState) => [...prevState, script]);
    };

    const getPluginBySlug = () => {
        getPlugin(params.slug).then((res) => dispatch({ type: "GET_PLUGIN", payload: res }))
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getPluginBySlug()
    },[])

    useEffect(() => {
        if(Object.keys(plugin).length>0){
            arrUrl.map((url) => defaultScripts(url));
            setLoading(false)
            if(plugin.publish===0){
                navigate('/');
            }
        }
    }, [plugin]);

    return loading ? <Spinner/> : (
        <>
            <Seo
                title={`${plugin.name} - Plugins - PluginPress.net`}
                keywords={plugin.keywords}
            />
            <div className="section-headline-wrap">
                <div className="section-headline">
                    <h2>{plugin.name}</h2>
                    <p>
                        Home<span className="separator">/</span>Plugins
                        <span className="separator">/</span>
                        <span className="current-section">{plugin.name}</span>
                    </p>
                </div>
                <div className="section-wrap">
                    <div className="section">
                        <div className="sidebar right">
                            <div className="sidebar-item author-items">
                            <h4>Products</h4>
                                {
                                    plugin?.products?.length>0 && plugin.products.map(product => {
                                        let cover = "";
                                        product?.images?.length>0 && product.images.map(img => cover = img.thumb);
                                        return <>
                                            {/* PRODUCT LIST */}
                                            <div className="product-list grid column4-wrap">
                                                {/* PRODUCT ITEM */}
                                                <div className="product-item column">
                                                    {/* PRODUCT PREVIEW ACTIONS */}
                                                    <div className="product-preview-actions">
                                                        {/* PRODUCT PREVIEW IMAGE */}
                                                        <figure className="product-preview-image">
                                                            <img src={`${rootURL}/${cover}`}
                                                                 alt="product-image"/>
                                                        </figure>
                                                        {/* /PRODUCT PREVIEW IMAGE */}
                                                        {/* PREVIEW ACTIONS */}
                                                        <div className="preview-actions">
                                                            {/* PREVIEW ACTION */}
                                                            <div className="preview-action">
                                                                <Link to={`/product/${product.slug}`}>
                                                                    <div className="circle tiny primary">
                                                                        <span className="icon-tag"/>
                                                                    </div>
                                                                </Link>
                                                                <Link to={`/product/${product.slug}`}>
                                                                    <p>Go to Item</p>
                                                                </Link>
                                                            </div>
                                                            {/* /PREVIEW ACTION */}
                                                            {/* PREVIEW ACTION */}
                                                            <div className="preview-action">
                                                                <a href="#">
                                                                    <div className="circle tiny secondary">
                                                                        <span className="icon-heart"/>
                                                                    </div>
                                                                </a>
                                                                <a href="#">
                                                                    <p>Favourites +</p>
                                                                </a>
                                                            </div>
                                                            {/* /PREVIEW ACTION */}
                                                        </div>
                                                        {/* /PREVIEW ACTIONS */}
                                                    </div>
                                                    {/* /PRODUCT PREVIEW ACTIONS */}
                                                    {/* PRODUCT INFO */}
                                                    <div className="product-info">
                                                        <Link to={`/product/${product.slug}`}>
                                                            <p className="text-header">{product.name}</p>
                                                        </Link>
                                                        <p className="product-description" dangerouslySetInnerHTML={{__html:`${product.description.substring(0,50)}...`}}/>

                                                    </div>

                                                </div>
                                                {/* /PRODUCT ITEM */}
                                            </div>
                                            {/* /PRODUCT LIST */}
                                            <div className="clearfix"/>
                                        </>
                                    })
                                }
                        </div>
                        </div>
                        <div className="content left">
                            <article className="post">
                                {/* POST IMAGE */}
                                <div className="post-image">
                                    <figure className="product-preview-image large liquid">
                                        <img src={plugin?.images?.length>0&&plugin.images.length>0&&`${rootURL}/${plugin.images[0].thumb}` } alt="" />
                                    </figure>
                                    {/* SLIDE CONTROLS */}
                                    <div className="slide-control-wrap">
                                        <div className="slide-control rounded left">
                                            {/* SVG ARROW */}
                                            <svg className="svg-arrow">
                                                <use xlinkHref="#svg-arrow" />
                                            </svg>
                                            {/* /SVG ARROW */}
                                        </div>
                                        <div className="slide-control rounded right">
                                            {/* SVG ARROW */}
                                            <svg className="svg-arrow">
                                                <use xlinkHref="#svg-arrow" />
                                            </svg>
                                            {/* /SVG ARROW */}
                                        </div>
                                    </div>
                                    {/* /SLIDE CONTROLS */}
                                    <a href={plugin.demo} className="button mid primary" target="_blank">
                                        <FaWordpress style={{position:"relative", top:"2.5px"}}/> Go to Live Demo
                                    </a>
                                </div>
                                {/* /POST IMAGE */}
                                {/* POST IMAGE SLIDES */}
                                <div className="post-image-slides">
                                    {/* IMAGE SLIDES WRAP */}
                                    <div className="image-slides-wrap full">
                                        {/* IMAGE SLIDES */}
                                        <div
                                            className="image-slides"
                                            data-slide-visible-full="8"
                                            data-slide-visible-small="2"
                                            data-slide-count={plugin?.images?.length>0 && plugin.images.length.toString()}
                                        >
                                            {Object.keys(plugin).length>0 && plugin.images.map((image,key) => (

                                                <div className={`image-slide${key===0?" selected": ""}`} key={image.id} style={{background:`url(${rootURL}/${image.thumb})`}}>
                                                    <div className="overlay" />
                                                    <figure className="product-preview-image thumbnail liquid">
                                                        <img src={`${rootURL}/${image.path}`} alt="" style={{visibility:"hidden"}}/>
                                                    </figure>
                                                </div>

                                            ))}
                                        </div>
                                        {/* IMAGE SLIDES */}
                                    </div>
                                    {/* IMAGE SLIDES WRAP */}
                                </div>
                                {/* /POST IMAGE SLIDES */}
                                <hr className="line-separator" />
                                {/* POST CONTENT */}
                                <div className="post-content">
                                    <div dangerouslySetInnerHTML={{__html:plugin.description}}/>
                                    <div className="clearfix" />
                                </div>

                            </article>
                            <div className="post-tab">
                                {/* TAB HEADER */}
                                <div className="tab-header primary">
                                    {/* TAB ITEM */}
                                    <div className="tab-item selected">
                                        <p className="text-header">Docs</p>
                                    </div>
                                    {/* /TAB ITEM */}
                                    {/* TAB ITEM */}
                                    <div className="tab-item">
                                        <p className="text-header">FAQ</p>
                                    </div>
                                    {/* /TAB ITEM */}
                                </div>
                                {/* /TAB HEADER */}
                                {/* TAB CONTENT */}
                                <div className="tab-content">
                                    {/* ITEM-FAQ */}
                                    <div className="accordion item-faq primary">
                                        {plugin?.documentations?.length>0 ? plugin.documentations.map(doc =>(
                                            <div className="accordion-item" key={doc.id}>
                                                <h6 className="accordion-item-header">
                                                    {doc.title}
                                                </h6>
                                                {/* SVG ARROW */}
                                                <svg className="svg-arrow">
                                                    <use xlinkHref="#svg-arrow" />
                                                </svg>
                                                {/* /SVG ARROW */}
                                                <div className="accordion-item-content">
                                                    <p dangerouslySetInnerHTML={{__html:doc.content}}/>
                                                </div>
                                            </div>
                                        )) : <p>Documentation content is not ready yet....</p>}
                                    </div>
                                    {/* /ITEM-FAQ */}
                                </div>
                                {/* /TAB CONTENT */}
                                {/* TAB CONTENT */}
                                <div className="tab-content">
                                    {/* ITEM-FAQ */}
                                    <div className="accordion item-faq primary">
                                        {plugin?.faqs?.length>0 ? plugin.faqs.map(faq =>(
                                            <div className="accordion-item" key={faq.id}>
                                                <h6 className="accordion-item-header">
                                                    {faq.title}
                                                </h6>
                                                {/* SVG ARROW */}
                                                <svg className="svg-arrow">
                                                    <use xlinkHref="#svg-arrow" />
                                                </svg>
                                                {/* /SVG ARROW */}
                                                <div className="accordion-item-content">
                                                    <p dangerouslySetInnerHTML={{__html:faq.content}}/>
                                                </div>
                                            </div>
                                        )) : <p>FAQ content is not ready yet....</p>}
                                    </div>
                                    {/* /ITEM-FAQ */}
                                </div>
                                {/* /TAB CONTENT */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Plugin;