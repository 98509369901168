import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../context/auth/AuthProvider";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";
import ReactFileReader from "react-file-reader";
import { toast } from "react-toastify";
import AvatarEditor from "react-avatar-editor";
import Slider from "rc-slider";
import { FaWindowClose } from "@react-icons/all-files/fa/FaWindowClose";
import axios from "axios";
import { BiLoaderCircle } from "@react-icons/all-files/bi/BiLoaderCircle";
import GeneralContext from "../context/general/GeneralProvider";
import { confirmAlert } from "react-confirm-alert";
import { FiCopy } from "@react-icons/all-files/fi/FiCopy";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";
const storageUrl = process.env.REACT_APP_URL_ROOT;

function Account() {
  const { auth,setAuth, header, logOutNow } = useContext(AuthContext);
  const {
    avatar,
    setAvatar,
    name,
    setName,
    email,
    setEmail,
    aboutMe,
    setAboutMe,
    billing,
    setBilling,
    webcount,
    websites,
    setWebsites,
      sub,
      setSub
  } = useContext(GeneralContext);
  const [profileUpdate, setProfileUpdate] = useState(false);

  const [avatarUpload, setAvatarUpload] = useState("");
  const [rotate, setRotate] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [avatarEditor, setAvatarEditor] = useState(null);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  //pasword states
  const [passLoading, setPassLoading] = useState(false);
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [old, setOld] = useState("");

  //general states
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [extending,setExtending] = useState(false)

  const getCountries = () => {
    const url = `${process.env.REACT_APP_API_ROOT}/getcountries`;
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCountries(res.data);
        } else {
          logOutNow();
        }
      });
  };

  const getProfile = () => {
    const url = `${process.env.REACT_APP_API_ROOT}/getprofile`;
    axios.get(url,header).then(res => {
      setWebsites(res.data.websites)
      localStorage.setItem('PluginPressWebSitesValue',JSON.stringify(websites))
      setAuth(prevState => ({
          ...prevState,
          name: res.data.name,
          email: res.data.email,
          avatar: res.data.avatar,
          countryID:res.data.countryID,
          aboutme: res.data.aboutme,
          address: res.data.address,
          address2: res.data.address2,
          city: res.data.city,
          postal: res.data.postal,
          company: res.data.company,
          street : res.data.street,
          websites: JSON.stringify(res.data.websites),
          webcount: res.data.webcount,
          sub:res.data.sub
      }))
      localStorage.setItem('PluginPressNameValue',res.data.name)
      localStorage.setItem('PluginPressEmailValue',res.data.email)
      localStorage.setItem('PluginPressAvatarValue',res.data.avatar)
      localStorage.setItem('PluginPressCountryID',res.data.countryID)
      localStorage.setItem('PluginPressAboutMeValue',res.data.aboutme)
      localStorage.setItem('PluginPressAddressValue',res.data.address)
      localStorage.setItem('PluginPressAddress2Value',res.data.address2)
      localStorage.setItem('PluginPressCityValue',res.data.city)
      localStorage.setItem('PluginPressPostalValue',res.data.postal)
      localStorage.setItem('PluginPressCompanyValue',res.data.company)
      localStorage.setItem('PluginPressStreetValue',res.data.street)
      localStorage.setItem('PluginPressWebCountValue',res.data.webcount)
      localStorage.setItem('PluginPressSubValue',res.data.sub)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (auth) {
      getProfile()
      getCountries();
    }
  }, []);

  useEffect(()=> {
    countries.length>0&&setLoading(false)
  },[countries])

  const updatePassword = (e) => {
    e.preventDefault();
    setPassLoading(true);
    if (old.length > 0) {
      if (pass === pass2) {
        if (
          isStrongPassword(pass, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          const url = `${process.env.REACT_APP_API_ROOT}/passupdate`;
          try {
            axios
              .post(
                url,
                { new: pass, old },
                {
                  headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${auth.token}`,
                  },
                }
              )
              .then((res) => {
                res.status === 200
                  ? toast.success(res.data.message)
                  : toast.warning(res.data.message);
                if (res.status !== 200) {
                  logOutNow();
                }
              });
          } catch (err) {
            toast.error("Something wrong, please try again later");
          }
          setPassLoading(false);
        } else {
          toast.warning(
            "The password must be 8 digits and contain a minimum of one lowercase letter, one uppercase letter, one number and one special character."
          );
          setPassLoading(false);
        }
      } else {
        toast.warning("Your password does not match!");
        setPassLoading(false);
      }
    } else {
      toast.warning("You have to enter your old password");
      setPassLoading(false);
    }
  };

  const updateAddress = (e) => {
    e.preventDefault();
    setAddressLoading(true);
    const url = `${process.env.REACT_APP_API_ROOT}/billingupdate`;
    try {
      axios
        .post(
          url,
          { ...billing },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            toast.success("Billing Address has been updated!");
            localStorage.setItem("PluginPressAddressValue", billing.address);
            localStorage.setItem("PluginPressAddress2Value", billing.address2);
            localStorage.setItem("PluginPressCityValue", billing.city);
            localStorage.setItem("PluginPressPostalValue", billing.postal);
            localStorage.setItem("PluginPressCompanyValue", billing.company);
            localStorage.setItem("PluginPressCountryID", billing.country_id);
          } else {
            toast.error("Billing Address has not been updated!");
          }
          setAddressLoading(false);
        });
    } catch (err) {
      logOutNow();
      setAddressLoading(false);
    }
  };

  const updateProfile = (e) => {
    e.preventDefault();
    setProfileUpdate(true);
    const url = `${process.env.REACT_APP_API_ROOT}/profileupdate`;
    try {
      if (isEmail(email)) {
        axios.post(url, { name, email, aboutMe, sub }, header).then((data) => {
          if (data.data.message === "ok") {
            localStorage.setItem(
              "PluginPressNameValue",
              data.data.profile.name
            );
            localStorage.setItem(
              "PluginPressEmailValue",
              data.data.profile.email
            );
            localStorage.setItem(
              "PluginPressAboutMeValue",
              data.data.profile.aboutme
            );
            localStorage.setItem(
                "PluginPressSubValue",
                data.data.profile.sub
            );
            toast.success("Your profile has been updated!");
            setProfileUpdate(false);
          } else {
            toast.error("You cannot use this email address");
            setProfileUpdate(false);
          }
        });
      } else {
        setProfileUpdate(false);
        toast.warning("You have to correct email address");
      }
    } catch (err) {
      setProfileUpdate(false);
      logOutNow();
    }
  };

  const handleFiles = (files) => {
    const imageCheck = files.fileList.item(0).type;
    if (imageCheck.startsWith("image/")) {
      setAvatarUpload(files.base64);
    } else {
      toast.error("Please add an image!");
    }
  };

  const resetAvatar = () => {
    setAvatarUpload("");
    setRotate(0);
    setZoom(1);
  };

  const saveImage = async () => {
    setAvatarLoading(true);
    const url = `${process.env.REACT_APP_API_ROOT}/avatarupdate`;
    try {
      const [data] = await Promise.all([
        axios.post(
          url,
          { avatar: avatarEditor.getImageScaledToCanvas().toDataURL() },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          }
        ),
      ]);
      setAvatar(`${storageUrl}/${data.data.avatar}`);
      localStorage.setItem("PluginPressAvatarValue", data.data.avatar);
      toast.success("You avatar has been updated!");
      setAvatarUpload("");
      setAvatarLoading(false);
    } catch (err) {
      logOutNow();
      setAvatarLoading(false);
    }
  };

  //Middleware redirect
  if (!auth) {
    return <Navigate to="/login" />;
  }

  async function copyClipBoard(e) {
    e.preventDefault();
    // Get the text field
    let copyText = document.getElementById("apiKey");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    toast.success("You have copied the API Key!");

    // Copy the text inside the text field
    await navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
  }

  const deleteItem = (id) => {
    const url = `${process.env.REACT_APP_API_ROOT}/website`;
    axios.post(url,{id},{
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }).then(res => {
      if(res.data.message ==="ok"){
        setWebsites(websites.filter(web => parseInt(web.id)!==parseInt(id)))
        toast.success('The website has been deleted!')
      }else{
        toast.error('The website has not been deleted!')
      }
    }).catch(err => toast.error(err.response.data.message))
  };

  const onDelete = (e) => {
    e.preventDefault();
    const id = e.currentTarget.dataset.delete;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm">
            <h1>Are you sure?</h1>
            <p>You want to delete this website?</p>
            <br/>
            <div
              style={{
                display: "grid",
                gap: "10px",
                gridTemplateColumns: "1fr 1fr",
                textAlign:"center",
                width:"50%",
                position:"relative",
                left:"60px"
              }}
            >
              <div>
                {" "}
                <button
                  onClick={onClose}
                  className="button primary"
                >
                  No
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="button"
                  style={{backgroundColor:"red"}}
                  onClick={() => {
                    deleteItem(id);
                    onClose();
                  }}
                >
                  Yes, Delete it!
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const extendWeb = () => {
    setExtending(true)
    if(auth.webcount<5){
      const url = `${process.env.REACT_APP_API_ROOT}/stripe/extendwebsite`;
      axios.get(url,header).then(res => {
            if(res.data?.url){
              window.location.href = res.data.url
            }
            setExtending(false)
          }
      ).catch(err => {
        setExtending(false)
        toast.error(err.response.data.message)
      })
    }else{
      setExtending(false)
      toast.info('You have reached maximum limit!')
    }
  }

  return loading ? <Spinner/> : (
    auth.name && (
      <>
        <Seo
            title="My Account - PluginPress.net"
        />
        {/* SECTION HEADLINE */}
        <div className="section-headline-wrap v2">
          <div className="section-headline">
            <h2>Account Settings</h2>
            <p>
              <Link to="/" style={{ color: "#fff" }}>
                Home
              </Link>
              <span className="separator">/</span>
              <span className="current-section">Account Settings</span>
            </p>
          </div>
        </div>
        {/* /SECTION HEADLINE */}
        <div className="dashboard-content">
          <div className="headline simple primary">
            <h4 className="api-key">
              API-Key: {auth?.apiKey && auth.apiKey}{" "}
              <FiCopy
                style={{
                  cursor: "pointer",
                  position: "relative",
                  top: "2px",
                  fontSize: "23px",
                }}
                alt="Copy the Api Key"
                onClick={copyClipBoard}
              />
            </h4>
            <input
              type="text"
              defaultValue={auth?.apiKey && auth.apiKey}
              id="apiKey"
              style={{ display: "none" }}
            />
          </div>
          {/* FORM BOX ITEMS */}
          <div className="form-box-items">
            {/* FORM BOX ITEM */}
            <div className="form-box-item">
              <h4>Profile Information</h4>
              <hr className="line-separator" />
              {/* PROFILE IMAGE UPLOAD */}
              {!avatarUpload ? (
                <div className="profile-image">
                  <div className="profile-image-data">
                    <figure className="user-avatar medium">
                      <img src={avatar} alt="profile-default" />
                    </figure>
                    <p className="text-header">Profile Photo</p>
                    <p className="upload-details">Minimum size 70x70px</p>
                  </div>

                  <ReactFileReader handleFiles={handleFiles} base64={true}>
                    <button className="button mid-short dark-light">
                      Upload Image...
                    </button>
                  </ReactFileReader>
                </div>
              ) : (
                <div id="profile-info-form" style={{ marginBottom: "20px" }}>
                  <div
                    className="input-container half"
                    style={{ position: "relative" }}
                  >
                    <AvatarEditor
                      image={avatarUpload}
                      ref={(editor) => setAvatarEditor(editor)}
                      width={200}
                      height={200}
                      borderRadius={100}
                      color={[255, 255, 255, 1]} // RGBA
                      scale={zoom}
                      rotate={rotate}
                    />
                    <FaWindowClose
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "25px",
                        cursor: "pointer",
                      }}
                      onClick={resetAvatar}
                    />
                  </div>
                  <div
                    className="input-container half"
                    style={{ position: "relative", top: "30px" }}
                  >
                    <label className="rl-label">Zoom</label>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={zoom}
                      value={zoom}
                      step={0.01}
                      trackStyle={{ backgroundColor: "#27ebcb" }}
                      handleStyle={{ borderColor: "#27ebcb" }}
                      onChange={(e) => setZoom(e)}
                      style={{ width: "100%" }}
                    />

                    <div style={{ marginTop: "20px" }}>
                      <label className="rl-label">Rotate</label>{" "}
                      <Slider
                        min={-180}
                        max={180}
                        defaultValue={rotate}
                        value={rotate}
                        step={1}
                        trackStyle={{ backgroundColor: "#27ebcb" }}
                        handleStyle={{ borderColor: "#27ebcb" }}
                        onChange={(e) => setRotate(e)}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ marginTop: "40px" }}>
                      <button
                        className="button mid-short primary"
                        onClick={saveImage}
                      >
                        {!avatarLoading ? (
                          <>Save</>
                        ) : (
                          <BiLoaderCircle className="rotating" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* PROFILE IMAGE UPLOAD */}
              <div id="profile-info-form">
                {/* INPUT CONTAINER */}
                <div className="input-container">
                  <label htmlFor="acc_name" className="rl-label required">
                    Full Name
                  </label>
                  <input
                      type="text"
                      id="acc_name"
                      name="acc_name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your account name here..."
                  />
                </div>
                {/* /INPUT CONTAINER */}

                {/* INPUT CONTAINER */}
                <div className="input-container">
                  <label htmlFor="new_email" className="rl-label">
                    Email
                  </label>
                  <input
                      type="email"
                      id="new_email"
                      name="new_email"
                      placeholder="Enter your email address here..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="new_email" className="rl-label">
                    Mail Subscription
                  </label>
                  <select value={parseInt(sub)} onChange={e => setSub(e.target.value)}>
                    <option value={0}>I do not want to receive any email</option>
                    <option value={1}>I would like to hear from PluginPress</option>
                  </select>
                </div>

                <div className="input-container">
                  <label htmlFor="new_email" className="rl-label">
                    About me
                  </label>
                  <textarea
                      value={aboutMe}
                      style={{height: "215px"}}
                      placeholder="Write about yourself..."
                      onChange={(e) => setAboutMe(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <button
                      className="button mid-short secondary"
                      onClick={updateProfile}
                  >
                    {!profileUpdate ? (
                        <>Update</>
                    ) : (
                        <BiLoaderCircle className="rotating"/>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* FORM BOX ITEM */}
            <div className="form-box-item">
              <h4>Biling Information</h4>
              <hr className="line-separator"/>

              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="company_name2" className="rl-label">
                  Company Name
                </label>
                <input
                    type="text"
                    form="profile-info-form"
                  value={billing.company}
                  onChange={(e) =>
                    setBilling((prevState) => ({
                      ...prevState,
                      company: e.target.value,
                    }))
                  }
                  placeholder="Enter your company name here..."
                />
              </div>
              {/* /INPUT CONTAINER */}

              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="address2" className="rl-label required">
                  Address Line 1
                </label>
                <input
                  form="profile-info-form"
                  type="text"
                  value={billing.address}
                  onChange={(e) =>
                    setBilling((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }))
                  }
                  placeholder="Enter your address here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="address2" className="rl-label required">
                  Address Line 2
                </label>
                <input
                  form="profile-info-form"
                  type="text"
                  value={billing.address2}
                  onChange={(e) =>
                    setBilling((prevState) => ({
                      ...prevState,
                      address2: e.target.value,
                    }))
                  }
                  placeholder="Enter your address here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="address2" className="rl-label required">
                  Street
                </label>
                <input
                  form="profile-info-form"
                  type="text"
                  value={billing.street}
                  onChange={(e) =>
                    setBilling((prevState) => ({
                      ...prevState,
                      street: e.target.value,
                    }))
                  }
                  placeholder="Enter your street here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container half">
                <label htmlFor="state_city2" className="rl-label required">
                  State/City
                </label>
                <input
                  form="profile-info-form"
                  type="text"
                  value={billing.city}
                  onChange={(e) =>
                    setBilling((prevState) => ({
                      ...prevState,
                      city: e.target.value,
                    }))
                  }
                  placeholder="Enter your City here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container half">
                <label htmlFor="zipcode2" className="rl-label required">
                  Postal Code
                </label>
                <input
                  form="profile-info-form"
                  type="text"
                  value={billing.postal}
                  onChange={(e) =>
                    setBilling((prevState) => ({
                      ...prevState,
                      postal: e.target.value,
                    }))
                  }
                  placeholder="Enter your Postal Code here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="country2" className="rl-label required">
                  Country
                </label>
                <label htmlFor="country2" className="select-block">
                  <select
                    form="profile-info-form"
                    name="country2"
                    id="country2"
                    value={billing.country_id}
                    onChange={(e) =>
                      setBilling((prevState) => ({
                        ...prevState,
                        country_id: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select your Country...</option>
                    {countries.length > 0 &&
                      countries.map((country) => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                  {/* SVG ARROW */}
                  <svg className="svg-arrow">
                    <use xlinkHref="#svg-arrow" />
                  </svg>
                  {/* /SVG ARROW */}
                </label>
              </div>
              {/* /INPUT CONTAINER */}

              {/* INPUT CONTAINER */}
              <div className="input-container">
                <button
                  className="button mid-short secondary-dark"
                  onClick={updateAddress}
                >
                  {!addressLoading ? (
                    <>Update</>
                  ) : (
                    <BiLoaderCircle className="rotating" />
                  )}
                </button>
              </div>
              {/* /INPUT CONTAINER */}
            </div>
            {/* /FORM BOX ITEM */}
            {/* /FORM BOX ITEM */}
            <div className="form-box-item">
              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label className="rl-label">Old Password</label>
                <input
                  type="password"
                  value={old}
                  onChange={(e) => setOld(e.target.value)}
                  placeholder="Enter your password here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="new_pwd" className="rl-label">
                  New Password
                </label>
                <input
                  type="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  placeholder="Enter your password here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              {/* INPUT CONTAINER */}
              <div className="input-container">
                <label htmlFor="new_pwd2" className="rl-label">
                  Repeat Password
                </label>
                <input
                  type="password"
                  value={pass2}
                  onChange={(e) => setPass2(e.target.value)}
                  placeholder="Repeat your password here..."
                />
              </div>
              {/* /INPUT CONTAINER */}
              <div className="input-container">
                <button
                  className="button mid-short dark"
                  onClick={updatePassword}
                >
                  {!passLoading ? (
                    <>Update</>
                  ) : (
                    <BiLoaderCircle className="rotating" />
                  )}
                </button>
              </div>
            </div>
            <div className="form-box-item withdraw-history">
              <h4>Websites (Your limit: {webcount})</h4>
              <p><small>It gives you an advantage to use more than one product on more than one site. (You can extend your limit to maximum 5)</small></p>
              <hr className="line-separator" />
              {/* TRANSACTION HISTORY */}
              <div className="transaction-history">
                {websites.length > 0 &&
                  websites.map((website) => (
                    <div className="transaction-history-item" key={website.id}>
                      <div className="transaction-history-item-date">
                        <p>{new Date(website.created_at).toDateString()}</p>
                      </div>
                      <div className="transaction-history-item-mail">
                        <p>{website.website}</p>
                      </div>
                      <div className="transaction-history-item-amount">
                        <p className="text-header">{website.ip}</p>
                      </div>
                      <div className="transaction-history-item-status">
                        <p
                          className="text-header secondary"
                          style={{ cursor: "pointer" }}
                          data-delete={website.id}
                          onClick={onDelete}
                        >
                          Delete
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <hr className="line-separator" />
              {parseInt(auth?.webcount)<5 && <button className="button primary" onClick={extendWeb}>{extending ? <BiLoaderCircle className="rotating"/> : "Extend the limit"}</button>}
              {/* /TRANSACTION HISTORY */}
            </div>
          </div>
          {/* /FORM BOX */}
        </div>
      </>
    )
  );
}

export default Account;
