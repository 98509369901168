import React, {useContext} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import GeneralContext from "../context/general/GeneralProvider";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

function SideMenuRight() {
    const { auth, setAuth } = useContext(AuthContext);
    const { avatar,  name, email } =
        useContext(GeneralContext);

    const logOut = () => {
        localStorage.removeItem('PluginPressTokenValue')
        localStorage.removeItem('PluginPressEmailValue')
        localStorage.removeItem('PluginPressNameValue')
        localStorage.removeItem('PluginPressAvatarValue')
        localStorage.removeItem('PluginPressAboutMeValue')
        localStorage.removeItem('PluginPressAddressValue')
        localStorage.removeItem('PluginPressAddress2Value')
        localStorage.removeItem('PluginPressCityValue')
        localStorage.removeItem('PluginPressPostalValue')
        localStorage.removeItem('PluginPressCountryID')
        localStorage.removeItem('PluginPressCompanyValue')
        setAuth(null);
        document.getElementById('hiddenLoginLink').click();
        toast.info("You have logged out!");
    };

    return (
        <div id="account-options-menu" className="side-menu right closed">
            {/* SVG PLUS */}
            <svg className="svg-plus">
                <use xlinkHref="#svg-plus" />
            </svg>
            {/* /SVG PLUS */}
            {/* SIDE MENU HEADER */}
            {auth?.token && <div className="side-menu-header">
                {/* USER QUICKVIEW */}
                <div className="user-quickview">
                    {/* USER AVATAR */}
                    <Link to="/account">
                        <div className="outer-ring">
                            <div className="inner-ring" />
                            <figure className="user-avatar">
                                <img src={avatar} alt="avatar" />
                            </figure>
                        </div>
                    </Link>
                    {/* /USER AVATAR */}
                    {/* USER INFORMATION */}
                    <p className="user-name">{name}</p>
                    <p className="user-money">{email}</p>
                    {/* /USER INFORMATION */}
                </div>
                {/* /USER QUICKVIEW */}
            </div>}
            {/* /SIDE MENU HEADER */}
            {/* SIDE MENU TITLE */}
            {auth?.token &&
            <>
                <p className="side-menu-title">Your Account</p>
                {/* /SIDE MENU TITLE */}
                {/* DROPDOWN */}
                <ul className="dropdown dark hover-effect">
                    {/* DROPDOWN ITEM */}
                    <li className="dropdown-item">
                        <Link to="/account">Account Settings</Link>
                    </li>
                    {/* /DROPDOWN ITEM */}
                    {/* DROPDOWN ITEM */}
                    <li className="dropdown-item">
                        <Link to="/my-products">My Products</Link>
                    </li>
                    {/* /DROPDOWN ITEM */}
                    {/* DROPDOWN ITEM */}
                    <li className="dropdown-item">
                        <Link to="/tickets">Tickets</Link>
                    </li>
                    {/* /DROPDOWN ITEM */}
                    {/* DROPDOWN ITEM */}
                    <li className="dropdown-item">
                        <Link to="/products">Buy Tokens</Link>
                    </li>
                    {/* /DROPDOWN ITEM */}
                </ul>
                {/* /DROPDOWN */}
            </>}

            {auth?.token ? (
                <button className="button medium secondary" onClick={logOut}>
                    Logout
                </button>
            ): (
                <>
                    <br/>
                    <br/>
                    <Link to="/login" className="button medium primary">
                        Login
                    </Link>
                    <Link to="/register" className="button medium secondary">
                        Register
                    </Link>
                </>
            )}

        </div>

    );
}

export default SideMenuRight;