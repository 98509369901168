import axios from "axios";

export const getBundles = async (search,page)=>{
    const url = `${process.env.REACT_APP_API_ROOT}/bundles?page=${page}`
    return await axios.post(url, {search, page})
}

export const getBundle = async (slug) => {
    const url = `${process.env.REACT_APP_API_ROOT}/bundle/${slug}`
    return await axios.get(url);
}