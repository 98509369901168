import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import GeneralContext from "../context/general/GeneralProvider";
const apiLink = process.env.REACT_APP_API_ROOT;

function WebSuccess() {
    const {auth,setAuth,header} = useContext(AuthContext)
    const {webcount,setWebcount} = useContext(GeneralContext)
    const navigate = useNavigate();
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if(auth?.token){
            if(query.get("key")){
                const key = query.get("key");
                const url = `${apiLink}/stripe/websuccess/${key}`
                axios.get(url,header).then(res => {
                    if(res?.data?.message !=="ok"){
                        navigate('/')

                    }else{
                        setWebcount(prevState => parseInt(prevState)+1)
                        localStorage.setItem('PluginPressWebCountValue',webcount)
                        setAuth(prevState => ({
                            ...prevState,
                            webcount: webcount
                        }))
                        navigate('/account')
                        toast.success('Your website limit has been extended!')

                    }
                }).catch(err => navigate('/'))
            }else{
                navigate('/')
            }
        }else{
            navigate('/')
        }
    }, []);

    return (
        <div></div>
    );
}

export default WebSuccess;