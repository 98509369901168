import React, {useEffect, useState} from 'react';
import {staticArticle} from "../context/article/ArticleActions";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";

function Static({keyw}) {
    const [loading, setLoading] = useState(true);
    const [text,setText] = useState({})

    const getStatic = () => {
        staticArticle(keyw).then(res => setText(res)).then(()=>setLoading(false)).catch(err=>console.log(err));
    }

    useEffect(()=>{
        getStatic()
    },[])

    return loading ? <Spinner/> : (
        <>
            <Seo title={text.title} keywords={text.keywords}/>
            <div className="section-wrap">
                <div className="section">
                    <div className="content full">
                        <article className="post blog-post">
                            <div className="post-content with-title">
                                <p className="text-header big" style={{ padding: 20 }}>
                                    {text.title}
                                </p>
                                <div className="post-paragraph" style={{ padding: 20 }} dangerouslySetInnerHTML={{__html:text.content}}/>

                                {/* /POST PARAGRAPH */}
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Static;