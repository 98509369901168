import React from 'react';

function SupportsHeadline() {
    return (
        <div className="section-headline-wrap">
            <div className="section-headline">
                <h2>Supports</h2>
                <p>
                    Home<span className="separator">/</span>Supports

                </p>
            </div>
        </div>
    );
}

export default SupportsHeadline;