import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Spinner from "../Spinner";
import LatestPostsItem from "./LatestPostsItem";
import isEmail from "validator/lib/isEmail";
import {toast} from "react-toastify";

function Side({blog}) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true)
    const {tags} = blog;

    const [email, setEmail] = useState("")

    const subscribe = () => {
        if(isEmail(email)){
            const url = `${process.env.REACT_APP_API_ROOT}/newsletter`
            axios.post(url,{email}).then(res=>{
                if(res.data.status === "ok"){
                    toast.success('We added your email address to our NewsLetter, thank you for subscribe!')
                }
            }).catch(err=>{
                if(err.response.status===422){
                    return toast.info('You have already registered your email address!')
                }
                return toast.warning(err.response.data.message)
            })
        }else{
            toast.warning('Please enter a correct email address')
        }
        setEmail("")
    }

    const latestPosts = async (exception,conn) => {
        const url = `${process.env.REACT_APP_API_ROOT}/latestposts`
        try{
            const res = await axios.post(url,{exception,conn})
            setPosts(res.data);
        }catch (err){
            console.log(err)
        }
        setLoading(false)
    }



    useEffect(()=>{
        latestPosts(blog.id,'pluginpress.net')
    },[])

    return loading ? <Spinner/> : (
        <div className="sidebar right">
            {/* DROPDOWN */}
            {/*<ul className="dropdown hover-effect">*/}
            {/*    <li className="dropdown-item">*/}
            {/*        <a href="#">*/}
            {/*            Design Inspires<span className="item-count">12</span>*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li className="dropdown-item">*/}
            {/*        <a href="#">*/}
            {/*            Software<span className="item-count">27</span>*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li className="dropdown-item">*/}
            {/*        <a href="#">*/}
            {/*            Interviews<span className="item-count">8</span>*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li className="dropdown-item">*/}
            {/*        <a href="#">*/}
            {/*            Resources / Freebies<span className="item-count">15</span>*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*    <li className="dropdown-item">*/}
            {/*        <a href="#">*/}
            {/*            Technology<span className="item-count">19</span>*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*</ul>*/}
            {/* /DROPDOWN */}
            {/* SIDEBAR ITEM */}
            <div className="sidebar-item author-items-v2">
                <h4>Latest Posts</h4>
                <hr className="line-separator" />
                {posts.length>0 && posts.map(post => (
                    <LatestPostsItem key={post.id} post={post}/>
                ))}

            </div>
            {/* /SIDEBAR ITEM */}

            {/* SIDEBAR ITEM */}
            <div className="sidebar-item newsletter">
                <h4>Newsletter</h4>
                <hr className="line-separator" />
                <p>
                    Subscribe now and receive all the lastest news and offers right
                    to your email
                </p>

                    <input type="text" placeholder="Enter your email address..."  value={email} onChange={e => setEmail(e.target.value)}/>
                    <button className="button mid dark-light" onClick={subscribe}>
                        Subscribe Now!
                    </button>

            </div>
            {/* /SIDEBAR ITEM */}
            {/* SIDEBAR ITEM */}
            {/*<div className="sidebar-item">*/}
            {/*    <h4>Advertisement</h4>*/}
            {/*    <hr className="line-separator" />*/}
            {/*    <a href="#">*/}
            {/*        <img src="images/blog/banner.jpg" alt="" />*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/* /SIDEBAR ITEM */}
            {/* SIDEBAR ITEM */}
            <div className="sidebar-item">
                <h4>Tag Cloud</h4>
                <hr className="line-separator" />
                {/* TAG LIST */}
                <div className="tag-list primary">
                    {blog?.tags.length>0&&tags.map(tag=>(
                        <Link to={`/tag/${tag.slug}`} className="tag-list-item" reloadDocument>
                            {tag.name}
                        </Link>
                    ))}

                </div>
                {/* /TAG LIST */}
            </div>
            {/* /SIDEBAR ITEM */}
        </div>
    );
}

export default Side;