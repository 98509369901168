import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Spinner from "../components/Spinner";
import axios from "axios";
import {toast} from "react-toastify";

function Activate() {
    const params = useParams();
    const navigate  = useNavigate();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const activation  = () => {
        const url = `${process.env.REACT_APP_API_ROOT}/activate/${params.token}`;
        axios.get(url).then(res => {
            toast.success('Congratulations! Your account has been activated.')
            navigate('/login')
        }).catch(err=>{
            console.log(err);
            setMessage('Link is not validate')
            toast.error('Link is not validate.')
            navigate('/login')
        })
    }

    useEffect(()=> {
        activation();
    },[])


    return loading ? <Spinner/> : (
        <div><h1 style={{color:"#0a0a0a"}}>{message}</h1></div>
    );
}

export default Activate;