import React from 'react';
import {Link} from "react-router-dom";
const rootURL = process.env.REACT_APP_URL_ROOT;
function BundlesListItem({bundle}) {
    const {name,prices,products,description,slug} = bundle;
    let cover = "";
    bundle.images.map((image) => (cover = image.thumb));

    let pr = prices[0].price;
    let cut = prices[0].cut>0 ? prices[0].cut.toString() : "";


    return (
        <div className="product-item">
            <Link to={`/bundle/${slug}`} reloadDocument>
                {/* PRODUCT PREVIEW IMAGE */}
                <figure className="product-preview-image small">
                    <img src={`${rootURL}/${cover}`} alt="product-image" style={{height:"100%", width:"120px"}} />
                </figure>
                {/* /PRODUCT PREVIEW IMAGE */}
            </Link>
            {/* PRODUCT INFO */}
            <div className="product-info" style={{paddingLeft:"50px"}}>
                <Link to={`/bundle/${slug}`} reloadDocument>
                    <p className="text-header">{name}</p>
                </Link>
                <br/>

                <Link to={`/bundle/${slug}`} reloadDocument>
                    <p className="category primary" dangerouslySetInnerHTML={{__html:`${description.substring(0,80)}...`}}/>
                </Link>
            </div>
            {/* /PRODUCT INFO */}
            {/* AUTHOR DATA */}
            <div className="author-data">
                {/* USER RATING */}
                <div className="user-rating">
                    {products?.length>0 && products.map(product => <div style={{display:"block",fontSize:"11px"}}><Link to={`/product/${product.slug}`} style={{color:"#0a0a0a"}}>{product.name}</Link></div>)}
                </div>
                {/* /USER RATING */}
                {/* METADATA */}
                <p style={{fontSize:"11px",width:"200px"}}/>
                {/* /METADATA */}
            </div>
            {/* /AUTHOR DATA */}
            {/* ITEM METADATA */}
            <div className="item-metadata">
                <p className="text-header">

                </p>
                <p className="text-header tiny" style={{position:"relative",top:"20px"}}>
                    Bundle Price
                </p>
                <p className="text-header tiny">

                </p>
            </div>
            {/* ITEM METADATA */}


            {/* PRICE INFO */}
            <div className="price-info">
                <p className="price medium" style={{marginLeft:"50px"}}>
                    <span>{pr>0&&'£'}</span>{pr>0 ? (cut ? <>{pr}<span style={{textDecorationLine:"line-through"}}>{cut}</span></> : pr) : "Free"}
                </p>
            </div>
            {/* /PRICE INFO */}
        </div>
    );
}

export default BundlesListItem;