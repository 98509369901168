import React from 'react';

function TagHeadline({title}) {
    return (
        <div className="section-headline-wrap">
            <div className="section-headline">
                <h2>Tag: {title}</h2>
                <p>Home<span className="separator">/</span>Tag<span className="separator">/</span><span className="current-section">{title}</span></p>
            </div>
        </div>
    );
}

export default TagHeadline;