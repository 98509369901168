import React, {useContext, useState} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import {Link, Navigate} from "react-router-dom";
import {BiLoaderCircle} from "@react-icons/all-files/bi/BiLoaderCircle";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";
import {toast} from "react-toastify";
import {pluginPressRegister} from "../context/auth/AuthActions";
import Seo from "../components/Seo";
import { Verify } from 'react-puzzle-captcha';
import 'react-puzzle-captcha/dist/react-puzzle-captcha.css';

function Register(props) {
    const {auth} = useContext(AuthContext)
    const [email, setEmail] = useState("");
    const [name,setName] = useState("");
    const [password,setPasword] = useState("");
    const [password_confirmation, setPassConfirm] = useState("")
    const [loading,setLoading] = useState(false);
    const [puzzle, setPuzzle] = useState(true)
    const [terms, setTerms] = useState(false)

    const registerNow = async (e) => {
        e.preventDefault()
        setLoading(true)
        if(!terms){
            setLoading(false)
            return toast.error('You have to read and accept terms and conditions!')
        }
        if(puzzle){
            setLoading(false)
            return toast.error('You have to solve captcha puzzle!')
        }
        if(!isEmail(email)){
            setLoading(false)
            return toast.warning('You have to enter a valid email address')
        }
        if(name.length<5){
            setLoading(false)
            return toast.warning('Please enter a valid name')
        }
        if(!isStrongPassword(password)){
            setLoading(false)
            return  toast.warning(
                "The password must be 8 digits and contain a minimum of one lowercase letter, one uppercase letter, one number and one special character."
            );
        }

        if(password !== password_confirmation){
            setLoading(false)
            return toast.warning('Password and repeat password do not match!')
        }

        try{
            const [res] = await Promise.all([pluginPressRegister(name, email, password, password_confirmation)])
            if(res.status === "ok"){
                toast.success('You have registered successfully! We have sent an email, please activate your account!')
               document.getElementById("hiddenRegisterLink").click()

            }else{
                toast.error('Something wrong, please check your inputs or try again later!')
            }
            setLoading(false)
        }catch(err){
            setLoading(false)
            console.log(err)
            toast.error(err.response.data.message)
        }

    }
    const onTerms = (e) => {
        setTerms(!terms)
    }

    //Middleware redirect
    if(auth){
        return <Navigate to="/account"/>
    }
    return (
       <>
           <Seo
               title={`Create an Account - PluginPress.net`}
           />
           <div className="section-wrap">
               <Link to="/login" id="hiddenRegisterLink" style={{display:"none"}}/>
               <div className="form-popup" style={{marginTop:"50px", marginBottom:"50px"}}>

                   {/* FORM POPUP HEADLINE */}
                   <div className="form-popup-headline primary">
                       <h2>Register Account</h2>
                       <p>Register now and start to use pro versions of your plugins!</p>
                   </div>
                   {/* /FORM POPUP HEADLINE */}
                   {/* FORM POPUP CONTENT */}
                   <div className="form-popup-content">
                       <form id="register-form4">
                           <label htmlFor="email_address6" className="rl-label required">
                               Email Address
                           </label>
                           <input
                               type="email"
                               value={email}
                               onChange={e => setEmail(e.target.value)}
                               placeholder="Enter your email address here..."
                           />
                           <label htmlFor="username6" className="rl-label required">
                               Full Name
                           </label>
                           <input
                               type="text"
                               value={name}
                               onChange={e => setName(e.target.value)}
                               placeholder="Enter your username here..."
                           />
                           <label htmlFor="password6" className="rl-label required">
                               Password
                           </label>
                           <input
                               type="password"
                               value={password}
                               onChange={e => setPasword(e.target.value)}
                               placeholder="Enter your password here..."
                           />
                           <label htmlFor="repeat_password6" className="rl-label required">
                               Repeat Password
                           </label>
                           <input
                               type="password"
                               value={password_confirmation}
                               onChange={e => setPassConfirm(e.target.value)}
                               placeholder="Repeat your password here..."
                           />
                           <input type="checkbox" id="terms" name="terms" checked={terms} onClick={onTerms}/>
                           <label htmlFor="remember" className="label-check" onClick={onTerms}>
                               <span className="checkbox primary primary"><span></span></span>
                               I read and accepted <Link to="/terms-and-conditions">Terms and Conditions</Link>
                           </label>

                           <Verify
                               onSuccess={() => setPuzzle(false)}
                               onFail={() => setPuzzle(true)}
                           />
                           <hr className="line-separator top"/>

                           <button className="button mid dark" onClick={registerNow}>
                               {!loading ? <>Register <span className="primary">Now!</span></> :
                                   <span className="primary"><BiLoaderCircle className="rotating"/></span>}
                           </button>
                       </form>
                   </div>
                   {/* /FORM POPUP CONTENT */}
               </div>
           </div>
       </>

    );
}

export default Register;