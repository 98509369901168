import { createContext, useReducer, useState } from "react";
import pluginsReducer from "./PluginsReducer";
import PluginsReducer from "./PluginsReducer";

const PluginsContext = createContext({})

export const PluginsProvider = ({children}) => {
    const [view, setView] = useState(
        localStorage.getItem("PP_View") ? localStorage.getItem("PP_View") : "grid"
    );

    const initialState = {
        plugins: [],
        totalPage: 1,
        plugin:{},
        loading: true,
    };

    const [state, dispatch] = useReducer(pluginsReducer,initialState)

    return <PluginsContext.Provider
        value={{
            ...state,
            view,
            setView,
            dispatch
        }}>
        {children}
    </PluginsContext.Provider>
}

export default PluginsContext;