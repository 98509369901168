import { createContext, useReducer, useState } from "react";
import productsReducer from "./ProductsReducer";
const ProductsContext = createContext({});

export const ProductProvider = ({ children }) => {
  const [view, setView] = useState(
    localStorage.getItem("PP_View") ? localStorage.getItem("PP_View") : "grid"
  );

  const initialState = {
    products: [],
    lastProducts:[],
    totalPage: 1,
    product:{},
    loading: true,
  };

  const [state, dispatch] = useReducer(productsReducer, initialState);

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        view,
        setView,
        dispatch,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsContext;
