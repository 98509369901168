import React from 'react';

function PluginsHeadline(props) {
    return (
        <div className="section-headline-wrap">
            <div className="section-headline">
                <h2>Plugins</h2>
                <p>
                    Home<span className="separator">/</span>Plugins

                </p>
            </div>
        </div>
    );
}

export default PluginsHeadline;