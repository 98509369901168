import React, {useContext, useEffect} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
const apiLink = process.env.REACT_APP_API_ROOT;
function WebCancel() {
    const {auth,header} = useContext(AuthContext)
    const navigate = useNavigate();
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if(auth?.token){
            if(query.get("key")){
                const key = query.get("key");
                const url = `${apiLink}/stripe/cancel/${key}`
                axios.get(url,header).then(res => {
                    if(res?.data?.message !=="ok"){
                        navigate('/')
                    }else{
                        navigate('/account')
                        toast.warning('You canceled the order!')
                    }
                }).catch(err => navigate('/'))
            }else{
                navigate('/')
            }
        }else{
            navigate('/')
        }
    }, []);


    return (
        <div></div>
    );
}

export default WebCancel;