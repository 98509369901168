import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/auth/AuthProvider";
import TicketsContext from "../context/tickets/TicketsProvider";
import { Link, useParams } from "react-router-dom";
import { getTicket } from "../context/tickets/TicketsActions";
import Blank from "./Blank";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";
import starFiled from "../assets/star-filled.png";
import { BiLoaderCircle } from "@react-icons/all-files/bi/BiLoaderCircle";
import axios from "axios";
import { toast } from "react-toastify";
import TicketMessageUser from "../components/ticket/TicketMessageUser";
import TicketMessageAdmin from "../components/ticket/TicketMessageAdmin";

function Ticket() {
  const {  auth, header } = useContext(AuthContext);
  const { loading, dispatch, ticket } = useContext(TicketsContext);
  const params = useParams();
  const [blank, setBlank] = useState(false);
  const [msgCount, setMsgCount] = useState(0);
  const [open, setOpen] = useState(0);
  const [ticketing, setTicketing] = useState(false);
  const [messages, setMessages] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [openForm, setOpenForm] = useState({
    wp_info: "",
    ftp_info: "",
    cp_info: "",
  });
  const [desc, setDesc] = useState("");
  const [message,setMessage] = useState("");
  const [sendingMsg, setSendingMsg] = useState(false)

  const getTicketByID = () => {
    getTicket(params.id, header)
      .then((res) => {
        dispatch({ type: "GET_TICKET", payload: res });
        setMsgCount(res.data.messages.length);
        setOpen(res.data.opened);
        setMessages(res.data.messages);
        setOpenForm({
          wp_info: res.data.wp_info,
          ftp_info: res.data.ftp_info,
          cp_info: res.data.cp_info,
        });
      })
      .catch((err) => {
        setBlank(true);
      });
  };

  useEffect(() => {
    getTicketByID();
  }, [trigger]);

  const updateTicket = () => {
    setTicketing(true);
    const url = `${process.env.REACT_APP_API_ROOT}/openticket`;
    if (desc.length > 5) {
      axios
        .post(url, { ...openForm, id:params.id,desc }, header)
        .then((res) => {
          toast.success(res.data.message);
          setTicketing(false);
          setTrigger((prevState) => prevState + 1);
          setOpen(1)
        })
        .catch((err) => {
          console.log(err);
          setTicketing(false);
        });
    } else {
      toast.info("You must at least write your message!");
      setTicketing(false);
    }
  };

  const updateDetails = () => {
      setTicketing(true);
      const url = `${process.env.REACT_APP_API_ROOT}/updateticket`;

      axios
          .post(url, { ...openForm, id:params.id}, header)
          .then((res) => {
              toast.success(res.data.message);
              setTicketing(false);
          })
          .catch((err) => {
              console.log(err);
              setTicketing(false);
          });
  }

  const sendMessage = () => {
        setSendingMsg(true)
        const url = `${process.env.REACT_APP_API_ROOT}/sendticketmessage`;
        if(message.length>0){
            axios.post(url,{id:params.id,message}, header).then(res => {
                    setSendingMsg(false);
                    setMessage('')
                    setTrigger(prevState => prevState+1)
                }).catch(err=>{
                setSendingMsg(false)
                console.log(err)
            })
        }else{
            toast.warning('You have write a message!')
            setSendingMsg(false)
        }

  }

  if (blank) {
    return <Blank />;
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Seo
        title={`${ticket.support.title} - Ticket Service - PluginPress.net`}
      />
      <div className="section-headline-wrap">
        <div className="section-headline">
          <h2>{ticket.support.title}</h2>
          <p>
            Home<span className="separator">/</span>
            <Link to="/tickets" style={{ color: "#fff" }} reloadDocument={true}>
              Tickets
            </Link>
            <span className="separator">/</span>
            <span className="current-section">{ticket.support.title}</span>
          </p>
        </div>
      </div>
      {/*Content*/}
      <div className="dashboard-content">
        {/* HEADLINE */}
        <div className="headline buttons two primary">
          <h4>Your Inbox ({msgCount})</h4>

        </div>
        {/* /HEADLINE */}
        {/* INBOX MESSAGES PREVIEW */}
        <div className="inbox-messages-preview">
          {/* SOL */}
          <div className="inbox-messages">
            <div style={{ padding: "20px" }}>
              <div className="input-container ticket-form">
                <label htmlFor="new_email" className="rl-label">
                  Wordpress Admin Panel Info
                </label>
                <textarea
                  style={{ height: "100px" }}
                  value={openForm.wp_info}
                  onChange={(e) =>
                      setOpenForm((prevState) => ({
                          ...prevState,
                          wp_info: e.target.value,
                      }))
                  }
                  placeholder="Create an admin user and give details here..."
                />
              </div>
              {ticket.support.type !== "basic" && (
                <>
                  <div className="input-container ticket-form">
                    <label htmlFor="new_email" className="rl-label">
                      FTP Info
                    </label>
                    <textarea
                      style={{ height: "100px" }}
                      value={openForm.ftp_info}
                      onChange={(e) =>
                          setOpenForm((prevState) => ({
                              ...prevState,
                              ftp_info: e.target.value,
                          }))
                      }
                      placeholder="Give FTP details here..."
                    />
                  </div>
                  <div className="input-container ticket-form">
                    <label htmlFor="new_email" className="rl-label">
                      Control Panel or SSH info
                    </label>
                    <textarea
                        value={openForm.cp_info}
                        onChange={(e) =>
                            setOpenForm((prevState) => ({
                                ...prevState,
                                cp_info: e.target.value,
                            }))
                        }
                      style={{ height: "100px" }}
                      placeholder="Give Server Admin details here..."
                    />
                  </div>
                </>
              )}
                {
                    open===0 && (
                        <div className="input-container ticket-form">
                            <label htmlFor="new_email" className="rl-label">
                                Message
                            </label>
                            <textarea
                                style={{ height: "100px" }}
                                value={desc}
                                onChange={e=> setDesc(e.target.value)}
                                placeholder="Explain your problem..."
                            />
                        </div>
                    )
                }

              {ticket.solved === 0 && (
                <div className="input-container">
                  <button
                    className="button mid-short secondary"
                    onClick={open === 0 ? updateTicket : updateDetails}
                  >
                    {!ticketing ? (
                      <>{open === 0 ? "Send Details" : "Update Details"}</>
                    ) : (
                      <BiLoaderCircle className="rotating" />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* /SOL */}
          {/* SAG */}
          <div className="inbox-message-preview">
            <div className="inbox-message-preview-header">
              <p className="text-header">
                Messages
                <img src={starFiled} alt="star-icon" />
              </p>
            </div>
            {open === 0 ? (
              <div style={{ padding: "25px", textAlign: "center" }}>
                Firstly, enter your details and start conversation.
              </div>
            ) : (
              <>
                <div className="inbox-message-preview-body">
                    {
                        ticket.messages.length>0 && ticket.messages.map( msg => (
                            parseInt(auth.id) === parseInt(msg.user_id) ? <TicketMessageUser msg={msg} /> : <TicketMessageAdmin msg ={msg}/>
                        ))
                    }

                </div>
                  {ticket.solved === 0 && <div className="inbox-reply-form">
                      <input
                          type="text"
                          id="reply"
                          name="reply"
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                          placeholder="Write your message here..."
                      />
                      <button className="button secondary" onClick={sendMessage}>
                          {sendingMsg ? <BiLoaderCircle className="rotating" /> : 'Send Message'}
                      </button>
                  </div>}
              </>
            )}
          </div>
          {/* /SAG */}
        </div>
        {/* /INBOX MESSAGES PREVIEW */}
      </div>
    </>
  );
}

export default Ticket;
