import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import Toggle from 'react-toggle'
import { confirmAlert } from "react-confirm-alert";
import AuthContext from "../../context/auth/AuthProvider";
import axios from "axios";
import {toast} from "react-toastify";
import GeneralContext from "../../context/general/GeneralProvider";
import {FaCoins} from "@react-icons/all-files/fa/FaCoins";
const rootURL = process.env.REACT_APP_URL_ROOT;
const apiLink = process.env.REACT_APP_API_ROOT;
function ProductsListItem({product,validate}) {
    const {header,auth} = useContext(AuthContext)
    const {numberWithDot} = useContext(GeneralContext)
    const {id,name,prices, plugin,type,description,slug,subscriptions} = product;
    let activation = 1;
    let sub_id = "subxx"
    if(subscriptions.length>0){
        subscriptions.map(sub => {
            if(sub?.product_id === id){
                activation = sub.auto
                sub_id = sub.sub_id
            }
        })
    }
    const [active,setActive] = useState(activation);
    let cover = "";
    product.images.map((image) => (cover = image.thumb));

    let pr = 0;
    let cut = "";
    let credits = 0;

    if(prices.length>0){
        if(type === "sub"){
            prices.map(pri => {
                if(pri.sub === "monthly"){
                    pr = pri.price
                    cut = pri.cut>0 ? pri.cut.toString() : "";
                }
            })
        }else{
            pr = prices[0].price;
            cut = prices[0].cut>0 ? prices[0].cut.toString() : "";
            credits = prices[0].credits;
        }
    }

    const subUpdate = (e) => {
        e.preventDefault();
        if(active){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="confirm">
                            <h1>Are you sure?</h1>
                            <p>You want to cancel your subscription?</p>
                            <br/>
                            <div
                                style={{
                                    display: "grid",
                                    gap: "10px",
                                    gridTemplateColumns: "1fr 1fr",
                                    textAlign:"center",
                                    width:"50%",
                                    position:"relative",
                                    left:"60px"
                                }}
                            >
                                <div>
                                    {" "}
                                    <button
                                        onClick={onClose}
                                        className="button primary"
                                    >
                                        No
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="button"
                                        style={{backgroundColor:"red"}}
                                        onClick={() => {
                                            cancelSub();
                                            onClose();
                                        }}
                                    >
                                        Yes, cancel it
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                },
            });
        }else{
           resumeSub()
        }

    }

    const cancelSub = () => {
        const url = `${apiLink}/stripe/cancelsub`;

        if(auth.token){
            axios.post(url,{sub_id},header).then(()=>{
                toast.success('Your subscription has been canceled');
                setActive(0)
            }).catch(err=>console.log(err));
        }

    }

    const resumeSub = () => {
        const url = `${apiLink}/stripe/resumesub`;
        if(auth.token){
            axios.post(url,{sub_id},header).then(()=>{
                toast.success('Your subscription has been activated');
                setActive(1)
            }).catch(err=>console.log(err));
        }

    }

    return (
        <div className="product-item">
            <Link to={`/product/${slug}`} reloadDocument>
                {/* PRODUCT PREVIEW IMAGE */}
                <figure className="product-preview-image small">
                    <img src={`${rootURL}/${cover}`} alt="product-image" style={{height:"100%", width:"120px"}} />
                </figure>
                {/* /PRODUCT PREVIEW IMAGE */}
            </Link>
            {/* PRODUCT INFO */}
            <div className="product-info" style={{paddingLeft:"50px"}}>
                <Link to={`/product/${slug}`} reloadDocument>
                    <p className="text-header">{name}</p>
                </Link>
                <br/>

                <Link to={`/product/${slug}`} reloadDocument>
                    <p className="category primary" dangerouslySetInnerHTML={{__html:`${description.substring(0,80)}...`}}/>
                </Link>
            </div>
            {/* /PRODUCT INFO */}
            {/* AUTHOR DATA */}
            <div className="author-data">
                {/* USER RATING */}
                <div className="user-rating">
                    <Link to={`/plugin/${plugin.slug}`} reloadDocument>
                        <figure className="user-avatar small">
                            <img src={`${rootURL}/${plugin.icon}`} alt="user-avatar"  />
                        </figure>
                    </Link>
                    <Link to={`/plugin/${plugin.slug}`}>
                        <p className="text-header tiny" style={{width:"200px"}}>{plugin.name}</p>
                    </Link>
                </div>
                {/* /USER RATING */}
                {/* METADATA */}
                <p style={{fontSize:"11px",width:"200px"}} dangerouslySetInnerHTML={{__html:`${plugin.description.substring(0,80)}...`}}/>
                {/* /METADATA */}
            </div>
            {/* /AUTHOR DATA */}
            {/* ITEM METADATA */}
            <div className="item-metadata">
                <p className="text-header">

                </p>
                <p className="text-header tiny" style={{position:"relative",top:"20px"}}>
                    {type==="normal" ? (
                        credits === 0 ? "Lifetime pass" : <><FaCoins style={{position:"relative", top:"2px", color:"#f8b204"}}/> {numberWithDot(credits)}</>
                    ) : `Valid Until: ${new Date(validate).toDateString()}`}
                </p>
                <p className="text-header tiny">

                </p>
            </div>
            {/* ITEM METADATA */}


            {/* PRICE INFO */}
            <div className="price-info">
                <p className="price medium" style={{marginLeft:"50px"}}>
                    {type!=="sub" ? <> <span>{pr>0&&'£'}</span>{type!=="sub"&&pr>0 ? (cut ? <>{pr}<span style={{textDecorationLine:"line-through"}}>{cut}</span></> : pr) : "Free"}</>
                    : <div style={{fontSize:"12px", textAlign:"left", lineHeight:"12px", position:"relative", width:"100%"}}>
                            <Toggle
                                id={sub_id}
                                defaultChecked={active===1}
                                checked={active===1}
                                onChange={subUpdate} /><label style={{position:"absolute", right:"-80px", top:"7px", fontWeight:"bold"}}>Auto Renew</label>
                    </div>}
                </p>
            </div>
            {/* /PRICE INFO */}
        </div>
    );
}

export default ProductsListItem;
