import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {GiCutDiamond} from "@react-icons/all-files/gi/GiCutDiamond";
import {FaGift} from "@react-icons/all-files/fa/FaGift";
import Spinner from "../components/Spinner";
import GeneralContext from "../context/general/GeneralProvider";
const apiLink = process.env.REACT_APP_API_ROOT;
const rootURL = process.env.REACT_APP_URL_ROOT;

function Success() {
    const {auth,header} = useContext(AuthContext)
    const {setCoinTrigger} = useContext(GeneralContext)
    const [item,setItem] = useState({})
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true)
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if(auth?.token){
            if(query.get("key")){
                const key = query.get("key");
                const url = `${apiLink}/stripe/success/${key}`
                axios.get(url,header).then(res => {
                    if(res?.data?.message !=="ok"){
                        navigate('/')

                    }else{
                        setItem(res.data.item)
                        setCoinTrigger(prevState => prevState+1);
                        setLoading(false)
                    }
                }).catch(err => navigate('/'))
            }else{
                navigate('/')
            }
        }else{
            navigate('/')
        }
    }, []);


    return loading ? <Spinner/> :(
        <div className="section-wrap">
            <div className="section">
                {/* FORM BOX ITEM */}
                <div className="form-box-item">
                    <h1 style={{color:"#0a0a0a"}}>Thank You For Your Order<br/><FaGift style={{position:"relative", top:"5px"}}/></h1>
                    <div style={{marginTop:"10px",textAlign:"center"}}>
                        <h2 style={{color:"#0a0a0a", marginBottom:"10px"}}>{item?.name}</h2>
                        <img src={item?.images?.length>0 && `${rootURL}/${item.images[0].thumb}`}/>
                    </div>
                    <div style={{textAlign:"center", marginTop:"10px"}}><Link to={(item.tipo==="product") ? "/my-products" : "/tickets"} className="button mid dark spaced"><span className="primary"><GiCutDiamond style={{position:"relative", top:"2px"}}/> Check your {item.tipo==="product" ? "products" :"tickets"}</span></Link></div>
                </div>
                {/* /FORM BOX ITEM */}

            </div>
        </div>

    );

}

export default Success;