import { createContext, useReducer } from "react";
import ticketsReducer from "./TicketsReducer";
import SupportsContext from "../supports/SupportsProvider";

const TicketsContext = createContext({})

export const TicketsProvider = ({children}) => {
    const initialState = {
        tickets: [],
        totalPage: 1,
        ticket:{},
        loading: true,
    }

    const [state, dispatch] = useReducer(ticketsReducer,initialState)

    return <TicketsContext.Provider
        value={{
            ...state,
            dispatch
        }}
    >
        {children}
    </TicketsContext.Provider>
}

export default TicketsContext