import React, {useContext, useState} from "react";
import AuthContext from "../context/auth/AuthProvider";
import {Navigate} from "react-router-dom";
import {BiLoaderCircle} from "@react-icons/all-files/bi/BiLoaderCircle";
import axios from "axios";
import isEmail from "validator/lib/isEmail";
import {toast} from "react-toastify";
import Seo from "../components/Seo";

function ForgotPassword() {
  const { auth } = useContext(AuthContext);
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const forgotPassword = async (e) => {
    e.preventDefault()
    setLoading(true)
    if(isEmail(email)){
      const url = `${process.env.REACT_APP_API_ROOT}/forgotpassword`

      try{
        const res = await axios.post(url,{email});
        toast.success(res.data.message)
        setEmail("")
      }catch (err){

        toast.error(err.response.data.message)
      }
      setLoading(false)
    }else{
      setLoading(false)
      toast.warning('You have to enter a valid email address')
    }

  }

  //Middleware redirect
  if (auth) {
    return <Navigate to="/account"/>
  }

  return (
    <>
      <Seo
          title={`Forgot password - PluginPress.net`}
      />
      <div className="section-wrap">
        <div
            className="form-popup"
            style={{ marginTop: "50px", marginBottom: "50px" }}
        >

          {/* FORM POPUP CONTENT */}
          <div className="form-popup-content">
            <h4 className="popup-title">Restore your Password</h4>
            {/* LINE SEPARATOR */}
            <hr className="line-separator short" />
            {/* /LINE SEPARATOR */}
            <p className="spaced">
              Please enter your registered email address to reset your password. After sending the password reset request, please also check your spam folder if you don't receive the email.
            </p>
            <div id="restore-pwd-form">
              <label htmlFor="email_address" className="rl-label">
                Email Address
              </label>
              <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter your email address..."
              />

              <button className="button mid dark no-space" onClick={forgotPassword}>
                {!loading ? <>Reset your <span className="primary">Password!</span></> : <span className="primary"><BiLoaderCircle className="rotating"/></span>}
              </button>
            </div>
          </div>
          {/* /FORM POPUP CONTENT */}
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
