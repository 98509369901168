export const defaultScript = (path) => {
    const script = document.createElement("script");
    script.src = path;
    script.async = false;
    script.type = "text/javascript";
    document.body.appendChild(script);
    return script;
}
export const prependScripts = (path) => {
    const script = document.createElement("script");
    script.src = path;
    script.async = false;
    document.body.prepend(script);
    return script;
}

export const removeScripts = (script) => {
    document.body.removeChild(script);
}

export const arrUrl = [
    `${process.env.PUBLIC_URL}/js/vendor/jquery-3.1.0.min.js`,
    `${process.env.PUBLIC_URL}/js/vendor/jquery.tooltipster.min.js`,
    `${process.env.PUBLIC_URL}/js/vendor/imgLiquid-min.js`,
    `${process.env.PUBLIC_URL}/js/vendor/jquery.xmtab.min.js`,
    `${process.env.PUBLIC_URL}/js/vendor/owl.carousel.min.js`,
    // `${process.env.PUBLIC_URL}/js/vendor/twitter/jquery.tweet.min.js`,
    `${process.env.PUBLIC_URL}/js/side-menu.js`,
    // `${process.env.PUBLIC_URL}/js/liquid.js.js`,
    `${process.env.PUBLIC_URL}/js/vendor/jquery.xmalert.min.js`,
    `${process.env.PUBLIC_URL}/js/image-slides.js`,
    `${process.env.PUBLIC_URL}/js/post-tab.js`,
    `${process.env.PUBLIC_URL}/js/vendor/jquery.xmaccordion.min.js`,
    `${process.env.PUBLIC_URL}/js/vendor/jquery.xmpiechart.min.js`,
    `${process.env.PUBLIC_URL}/js/home.js`,
    `${process.env.PUBLIC_URL}/js/item-v1.js`,
    `${process.env.PUBLIC_URL}/js/tooltip.js`,
    // `${process.env.PUBLIC_URL}/js/bundle.js`,



    // `${process.env.PUBLIC_URL}/js/footer.js`

]
