import React, {useContext, useState} from 'react';
import {toast} from "react-toastify";
import isEmail from "validator/lib/isEmail";
import { Verify } from 'react-puzzle-captcha';
import 'react-puzzle-captcha/dist/react-puzzle-captcha.css';
import AuthContext from "../context/auth/AuthProvider";
import axios from "axios";

function Contact() {
    const {auth} = useContext(AuthContext)
    const [disabled, setDisabled] = useState(!auth?.token);
    const [form, setForm] = useState({
        name: auth?.name,
        subject:"",
        company: auth?.company,
        email:auth?.email,
        message:""
    })

    const onChange = (e) => {
        setForm(prevState => ({
            ...prevState,
            [e.target.id] : e.target.value
        }))
    }

    const sendMessage = (e) => {
        e.preventDefault();
        if(disabled){
            return toast.error('Please solve captcha puzzle!')
        }
        if(!isEmail(form.email)){
            return toast.warning('Please enter a valid email address!')
        }
        if(form.name.length<3){
            return toast.warning('Please enter a valid name!')
        }
        if(form.message.length<5){
            return toast.warning('Please enter your message!')
        }
        if(form.subject.length<2){
            return toast.warning('Please enter a subject!')
        }

        axios.post(`${process.env.REACT_APP_API_ROOT}/mail/contact`,{data:form}).then(res => {
            console.log(res.data.message)
            toast.success('Your message has been send! You will be contacted as soon as possible.')
        }).catch(err => console.log(err));
    }
    return (
        <>
            <div className="section-headline-wrap">
                <div className="section-headline">
                    <h2>Contact</h2>
                    <p>Home<span className="separator">/</span>Contact</p>
                </div>
            </div>
            <div className="section-wrap">
                <div className="section">
                    <div className="form-box-item padded contact-form">
                        <h4>Contact Information</h4>
                        <hr className="line-separator"/>
                        {/* INPUT CONTAINER */}
                        <div className="input-container">
                            <label htmlFor="company_name2" className="rl-label required">
                                Full Name
                            </label>
                            <input
                                type="text"
                                form="checkout-form"
                                id="name"
                                name="name"
                                onChange={onChange}
                                value={form.name}
                                placeholder="Enter your full name here..."
                            />
                        </div>
                        {/* /INPUT CONTAINER */}
                        {/* INPUT CONTAINER */}
                        <div className="input-container">
                            <label htmlFor="company_name2" className="rl-label">
                                Company Name
                            </label>
                            <input
                                type="text"
                                form="checkout-form"
                                id="company"
                                name="company"
                                onChange={onChange}
                                value={form.company}
                                placeholder="Enter your company name here..."
                            />
                        </div>
                        {/* /INPUT CONTAINER */}
                        {/* INPUT CONTAINER */}
                        <div className="input-container">
                            <label htmlFor="email_address2" className="rl-label required">
                                Email Address
                            </label>
                            <input
                                type="text"
                                form="checkout-form"
                                id="email"
                                name="email"
                                onChange={onChange}
                                value={form.email}
                                placeholder="Enter your email address here..."
                            />
                        </div>
                        {/* /INPUT CONTAINER */}
                        <div className="input-container">
                            <label htmlFor="email_address2" className="rl-label required">
                               Subject
                            </label>
                            <input
                                type="text"
                                form="checkout-form"
                                id="subject"
                                name="subject"
                                onChange={onChange}
                                value={form.subject}
                                placeholder="Enter a subject..."
                            />
                        </div>


                        {/* INPUT CONTAINER */}
                        <div className="input-container">
                            <label htmlFor="notes2" className="rl-label">
                                Message
                            </label>
                            <textarea
                                form="checkout-form"
                                id="message"
                                name="message"
                                placeholder="Enter your message here..."
                                onChange={onChange}
                                value={form.message}
                            />
                        </div>
                        {/* /INPUT CONTAINER */}
                        <hr className="line-separator top"/>
                        {!auth?.token && <>
                            <Verify
                                onSuccess={() => setDisabled(false)}
                                onFail={() => setDisabled(true)}
                            />
                            <hr className="line-separator top"/>
                        </>}
                        <button form="checkout-form" className="button big dark" onClick={sendMessage}>Send <span
                            className="primary">Message!</span></button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Contact;