import React from 'react';
import {BiLoaderCircle} from "@react-icons/all-files/bi/BiLoaderCircle";

function Spinner() {
    return (
        <div className="spinner">
            <BiLoaderCircle style={{fontSize:"80px"}} className="rotating"/>
        </div>
    );
}

export default Spinner;