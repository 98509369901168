import { Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import SideMenuRight from "./components/SideMenuRight";
import MainMenu from "./components/MainMenu";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import React, { useEffect, useState } from "react";
import { arrUrl, defaultScript } from "./context/script/script";
import HowTo from "./pages/HowTo";
import Products from "./pages/Products";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Account from "./pages/Account";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { ProductProvider } from "./context/products/ProductsProvider";
import Product from "./pages/Product";
import Cancel from "./pages/Cancel";
import Success from "./pages/Success";
import { BundlesProvider } from "./context/bundles/BundlesProvider";
import Bundles from "./pages/Bundles";
import Bundle from "./pages/Bundle";
import WebCancel from "./pages/WebCancel";
import WebSuccess from "./pages/WebSuccess";
import Plugins from "./pages/Plugins";
import { PluginsProvider } from "./context/plugins/PluginProvider";
import Plugin from "./pages/Plugin";
import MyProducts from "./pages/MyProducts";
import Static from "./pages/Static";
import Blogs from "./pages/Blogs";
import { ArticleProvider } from "./context/article/ArticleProvider";
import Blog from "./pages/Blog";
import { SeoProvider } from "./context/seo/SeoProvider";
import Category from "./pages/Category";
import Tag from "./pages/Tag";
import { SupportsProvider } from "./context/supports/SupportsProvider";
import Supports from "./pages/Supports";
import Support from "./pages/Support";
import Tickets from "./pages/Tickets";
import { TicketsProvider } from "./context/tickets/TicketsProvider";
import Ticket from "./pages/Ticket";
import Contact from "./pages/Contact";
import Consent from "./components/Consent";
import Activate from "./pages/Activate";


function App() {
  const [scripts, setScripts] = useState([]);
  const defaultScripts = (url) => {
    const script = defaultScript(url);
    setScripts((prevState) => [...prevState, script]);
  };
  useEffect(() => {
    arrUrl.map((url) => defaultScripts(url));
  }, []);

  return (
    <>
      <Header />
      <SideMenu />
      <SideMenuRight />
      <MainMenu />
      <Routes>
        <Route
          path="/"
          element={
            <ProductProvider>
              <Home />
            </ProductProvider>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/blog/:slug"
          element={
            <ArticleProvider>
              <Blog />
            </ArticleProvider>
          }
        />
        <Route
          path="/blogs"
          element={
            <ArticleProvider>
              <Blogs />
            </ArticleProvider>
          }
        />
        <Route path="/my-products" element={<MyProducts />} />
        <Route
          path="/tickets"
          element={
            <TicketsProvider>
              <Tickets />
            </TicketsProvider>
          }
        />
        <Route
          path="/ticket/:id"
          element={
            <TicketsProvider>
              <Ticket />
            </TicketsProvider>
          }
        />
        <Route path="/how-to-shop" element={<HowTo />} />
        <Route
          path="/terms-and-conditions"
          element={<Static keyw="pp_terms" />}
        />
        <Route path="/privacy-policy" element={<Static keyw="pp_privacy" />} />
        <Route
          path="/bundles"
          element={
            <BundlesProvider>
              <Bundles />
            </BundlesProvider>
          }
        />
        <Route
          path="/bundle/:slug"
          element={
            <BundlesProvider>
              <Bundle />
            </BundlesProvider>
          }
        />
        <Route
          path="/products"
          element={
            <ProductProvider>
              <Products />
            </ProductProvider>
          }
        />
        <Route
          path="/product/:slug"
          element={
            <ProductProvider>
              <Product />
            </ProductProvider>
          }
        />
        <Route
          path="/plugins"
          element={
            <PluginsProvider>
              <Plugins />
            </PluginsProvider>
          }
        />
        <Route
          path="/plugin/:slug"
          element={
            <PluginsProvider>
              <Plugin />
            </PluginsProvider>
          }
        />
        <Route
          path="/supports"
          element={
            <SupportsProvider>
              <Supports />
            </SupportsProvider>
          }
        />
        <Route
          path="/support/:slug"
          element={
            <SupportsProvider>
              <Support />
            </SupportsProvider>
          }
        />
        <Route
          path="/tag/:slug"
          element={
            <SeoProvider>
              <Tag />
            </SeoProvider>
          }
        />
        <Route
          path="/category/:slug"
          element={
            <SeoProvider>
              <Category />
            </SeoProvider>
          }
        />

        <Route path="/account" element={<Account />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/webcancel" element={<WebCancel />} />
        <Route path="/success" element={<Success />} />
        <Route path="/websuccess" element={<WebSuccess />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:code" element={<ResetPassword />} />
        <Route path="/activate/:token" element={<Activate />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <div className="shadow-film closed" />
      {/* SVG ARROW */}
      <svg style={{ display: "none" }}>
        <symbol
          id="svg-arrow"
          viewBox="0 0 3.923 6.64014"
          preserveAspectRatio="xMinYMin meet"
        >
          <path
            d="M3.711,2.92L0.994,0.202c-0.215-0.213-0.562-0.213-0.776,0c-0.215,0.215-0.215,0.562,0,0.777l2.329,2.329
			L0.217,5.638c-0.215,0.215-0.214,0.562,0,0.776c0.214,0.214,0.562,0.215,0.776,0l2.717-2.718C3.925,3.482,3.925,3.135,3.711,2.92z"
          />
        </symbol>
      </svg>
      {/* /SVG ARROW */}
      {/* SVG STAR */}
      <svg style={{ display: "none" }}>
        <symbol
          id="svg-star"
          viewBox="0 0 10 10"
          preserveAspectRatio="xMinYMin meet"
        >
          <polygon
            points="4.994,0.249 6.538,3.376 9.99,3.878 7.492,6.313 8.082,9.751 4.994,8.129 1.907,9.751 
	2.495,6.313 -0.002,3.878 3.45,3.376 "
          />
        </symbol>
      </svg>
      {/* /SVG STAR */}
      {/* SVG PLUS */}
      <svg style={{ display: "none" }}>
        <symbol
          id="svg-plus"
          viewBox="0 0 13 13"
          preserveAspectRatio="xMinYMin meet"
        >
          <rect x={5} width={3} height={13} />
          <rect y={5} width={13} height={3} />
        </symbol>
      </svg>
      {/* /SVG PLUS */}
      <ToastContainer
        style={{ top: "80px", zIndex: "999999", fontFamily: "Titillium Web" }}
      />
      <Consent/>
    </>
  );
}

export default App;
