import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/auth/AuthProvider";
import axios from "axios";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";
import {Link, Navigate} from "react-router-dom";
import ProductsListItem from "../components/myproducts/ProductsListItem";


function MyProducts() {
  const {auth, header } = useContext(AuthContext);
  const [owmers, setOwners] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMyProducts = () => {
    const url = `${process.env.REACT_APP_API_ROOT}/myproducts`;
    axios
      .get(url, header)
      .then((res) => {
        setOwners(res.data);
        console.log(res.data);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMyProducts();
  }, []);

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Seo title="My Products - PluginPress.net" />
      <div className="section-headline-wrap v2">
        <div className="section-headline">
          <h2>My Products</h2>
          <p>
            <Link to="/" style={{ color: "#fff" }}>
              Home
            </Link>
            <span className="separator">/</span>
            <span className="current-section">My Products</span>
          </p>
        </div>
      </div>
      <div className="section-wrap">
        {/*{owmers.length>0&&owmers.map(o => <h1 key={o.id}>{o.product.name}</h1>)}*/}
        <div className="section">
          <div className="content full">
            {/* HEADLINE */}
            {/* /HEADLINE */}
            {/* PRODUCT SHOWCASE */}
            <div className="product-showcase">
              {/* PRODUCT LIST */}
              <div className="product-list list full">
                  {
                      owmers.length>0 ? owmers.map(o => <ProductsListItem key={o.id} product={o.product} type={o.type} validate={o.validate}/>): <div style={{textAlign:"center"}}>You do not have any product. Check <Link to="/products">product</Link> page...</div>
                  }

              </div>
              {/* /PRODUCT LIST */}
            </div>
            {/* /PRODUCT SHOWCASE */}
            <div className="clearfix" />
            {/* /PAGER */}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProducts;
