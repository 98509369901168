import React from 'react';
import {Link} from "react-router-dom";
import Seo from "../Seo";
const rootURL = process.env.REACT_APP_URL_ROOT;
function Article({article}) {
    const {title,images,categories,keywords,content} = article;
    let cover = "";
    images.map((image) => (cover = image.thumb));
    let category = {};
    categories.map(cat => category = cat);

    return (
        <article className="post blog-post">
            <Seo
                title={`${title} - Products - PluginPress.net`}
                keywords={keywords}
                desc={`${content.substring(0,270)}...`}
                image={`${rootURL}${cover}`}
            />
            {/* POST IMAGE */}
            <div className="post-image">
                <figure className="product-preview-image large liquid">
                    <img src={`${rootURL}${cover}`} alt={title} />
                </figure>
            </div>
            {/* /POST IMAGE */}
            {/* POST CONTENT */}
            <div className="post-content with-title">
                <p className="text-header big">
                    {title}
                </p>
                <div className="meta-line">
                    <Link to={`/category/${category.slug}`}>
                        <p className="category primary">{category.name}</p>
                    </Link>
                    {/* METADATA */}
                    {/*<div className="metadata">*/}
                    {/*    /!* META ITEM *!/*/}
                    {/*    <div className="meta-item">*/}
                    {/*        <span className="icon-bubble" />*/}
                    {/*        <p>05</p>*/}
                    {/*    </div>*/}
                    {/*    /!* /META ITEM *!/*/}
                    {/*    /!* META ITEM *!/*/}
                    {/*    <div className="meta-item">*/}
                    {/*        <span className="icon-eye" />*/}
                    {/*        <p>68</p>*/}
                    {/*    </div>*/}
                    {/*    /!* /META ITEM *!/*/}
                    {/*</div>*/}
                    {/* /METADATA */}
                    <p>{new Date(article.created_at).toDateString()}</p>
                </div>
                {/* POST PARAGRAPH */}
                <div className="post-paragraph" dangerouslySetInnerHTML={{__html:content}}/>

                {/* /POST PARAGRAPH */}
                {/* POST PARAGRAPH */}
                {/* /POST PARAGRAPH */}
            </div>
            {/* /POST CONTENT */}
            <hr className="line-separator" />
            {/* SHARE */}
            <div className="share-links-wrap">
                <p className="text-header small">Share this:</p>
                {/* SHARE LINKS */}
                <ul className="share-links hoverable">
                    <li>
                        <a href="#" className="fb" />
                    </li>
                    <li>
                        <a href="#" className="twt" />
                    </li>
                    <li>
                        <a href="#" className="db" />
                    </li>
                    <li>
                        <a href="#" className="rss" />
                    </li>
                    <li>
                        <a href="#" className="gplus" />
                    </li>
                </ul>
                {/* /SHARE LINKS */}
            </div>
            {/* /SHARE */}
        </article>
    );
}

export default Article;