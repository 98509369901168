import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
const rootURL = process.env.REACT_APP_URL_ROOT;
function BundleProductItem({product,setTotal}) {
    const {name,prices, plugin,type,description,slug} = product;
    let cover = "";
    product.images.map((image) => (cover = image.thumb));

    let pr = 0;
    let cut = "";

    if(prices?.length>0){
        if(type === "sub"){
            prices.map(pri => {
                if(pri.sub === "monthly"){
                    pr = pri.price
                    cut = pri.cut>0 ? pri.cut.toString() : "";
                }
            })
        }else{
            pr = prices[0].price;
            cut = prices[0].cut>0 ? prices[0].cut.toString() : "";
        }
    }

    useEffect(()=>{
        setTotal(prevState => prevState+pr);
    },[])

    return (
        <div className="product-item">
            <Link to={`/product/${slug}`} reloadDocument>
                {/* PRODUCT PREVIEW IMAGE */}
                <figure className="product-preview-image small">
                    <img src={`${rootURL}/${cover}`} alt="product-image" style={{height:"100%", width:"120px"}} />
                </figure>
                {/* /PRODUCT PREVIEW IMAGE */}
            </Link>
            {/* PRODUCT INFO */}
            <div className="product-info" style={{paddingLeft:"50px"}}>
                <Link to={`/product/${slug}`} reloadDocument>
                    <p className="text-header">{name}</p>
                </Link>
                <br/>

                <Link to={`/product/${slug}`} reloadDocument>
                    <p className="category primary" dangerouslySetInnerHTML={{__html:`${description.substring(0,80)}...`}}/>
                </Link>
            </div>
            {/* /PRODUCT INFO */}

            {/* ITEM METADATA */}
            <div className="item-metadata">
                <p className="text-header">

                </p>
                <p className="text-header tiny" style={{position:"relative",top:"20px"}}>
                    {type==="normal" ? "Lifetime pass" : "Monthly Subscription"}
                </p>
                <p className="text-header tiny">

                </p>
            </div>
            {/* ITEM METADATA */}


            {/* PRICE INFO */}
            <div className="price-info">
                <p className="price medium" style={{marginLeft:"50px"}}>
                    <span>{pr>0&&'£'}</span>{pr>0 ? (cut ? <>{pr}<span style={{textDecorationLine:"line-through"}}>{cut}</span></> : pr) : "Free"}
                </p>
            </div>
            {/* /PRICE INFO */}
        </div>
    );
}

export default BundleProductItem;