import React from "react";
import { Helmet } from "react-helmet";
import metaImage from '../assets/meta.png'

function Seo({
  title = "Enhance Your Plugins, Empower Your WordPress: Discover the Premium Features of PluginPress.net!",
  desc,
  keywords="WordPress, Plugins, wordpress plugin, movie database plugin, ai plugin, ai wordpress, movie plugin, movie wordpress, game database, movie database plugin" ,
  image=metaImage,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="robots" content="index, follow" data-rh="true" />
      <meta name="description" content={desc} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} data-rh="true" />
      <meta property="twitter:title" content={title} data-rh="true" />
      <meta property="og:type" content="website" data-rh="true" />
      <meta
        property="og:url"
        content="https://pluginpress.net/"
        data-rh="true"
      />
      <meta
        property="twitter:url"
        content="https://pluginpress.net/"
        data-rh="true"
      />
      <meta property="og:description" content={desc} data-rh="true" />
      <meta property="twitter:description" content={desc} data-rh="true" />
      <meta property="og:image" content={image} data-rh="true" />
      <meta property="twitter:card" content={image} data-rh="true" />
      <meta property="twitter:image" content={image} data-rh="true" />
    </Helmet>
  );
}

export default Seo;
