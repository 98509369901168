import React, {useContext, useEffect, useState} from 'react';
import PluginsContext from "../context/plugins/PluginProvider";
import {getPlugins} from "../context/plugins/PluginsActions";
import Spinner from "../components/Spinner";
import PluginsHeadline from "../components/plugins/PluginsHeadline";
import Pagination from "../components/Pagination";
import PluginsGridItem from "../components/plugins/PluginsGridItem";
import Seo from "../components/Seo";

function Plugins() {
    const {dispatch,loading, plugins,totalPage} = useContext(PluginsContext)
    const [page,setPage] = useState(1);
    const [search, setSearch] = useState("")

    const getAllPlugins = () => {
        getPlugins(search,page).then(res => {
            dispatch({type:'GET_PLUGINS', payload:res})

        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getAllPlugins()
    },[search,page])

    return loading ? <Spinner/> : (
       <>
           <Seo
               title="Plugins - PluginPress.net"
           />
           <PluginsHeadline/>
           {/* SECTION */}
           <div className="section-wrap">
               <div className="section">
                   {/* PRODUCT SHOWCASE */}
                   <div className="product-showcase">
                       {/* PRODUCT LIST */}
                       <div className={`product-list grid column4-wrap`}>

                           {loading ? <Spinner/> : plugins.map(plugin => (


                               <PluginsGridItem key={plugin.id} plugin={plugin}/>


                               )
                           )}

                       </div>
                       {/* /PRODUCT LIST */}
                   </div>
                   {/* /PRODUCT SHOWCASE */}
                   <div className="clearfix" />
                   {/* PAGER */}
                   <Pagination page={page} setPage={setPage} totalPage={totalPage}/>
                   {/* /PAGER */}
               </div>
           </div>
           {/* /SECTION */}
       </>
    );
}

export default Plugins;