import React, { useContext } from "react";
import ProductsContext from "../../context/products/ProductsProvider";

function ProductsNavBar() {
  const { view, setView } = useContext(ProductsContext);

  return (
    <div className="sidebar-nav-wrap">
      <div className="sidebar-nav">
        {/* SIDEBAR MENU */}
        {/*<ul className="sidebar-menu">*/}
        {/*  /!* SIDEBAR MENU ITEM *!/*/}
        {/*  <li className="sidebar-menu-item">*/}
        {/*    /!* SVG ARROW *!/*/}
        {/*    <svg className="svg-arrow">*/}
        {/*      <use xlinkHref="#svg-arrow" />*/}
        {/*    </svg>*/}
        {/*    /!* /SVG ARROW *!/*/}
        {/*    <a href="#">Filter Products</a>*/}
        {/*    /!* SIDEBAR MENU DROPDOWN *!/*/}
        {/*    <div className="sidebar-menu-dropdown">*/}
        {/*      <form id="shop_search_form" name="shop_search_form">*/}
        {/*        /!* CHECKBOX *!/*/}
        {/*        <input*/}
        {/*          type="checkbox"*/}
        {/*          id="filter1"*/}
        {/*          name="filter1"*/}
        {/*          defaultChecked=""*/}
        {/*        />*/}
        {/*        <label htmlFor="filter1">*/}
        {/*          <span className="checkbox primary">*/}
        {/*            <span />*/}
        {/*          </span>*/}
        {/*          Cartoon Characters*/}
        {/*          <span className="quantity">350</span>*/}
        {/*        </label>*/}
        {/*        /!* /CHECKBOX *!/*/}
        {/*        /!* CHECKBOX *!/*/}
        {/*        <input*/}
        {/*          type="checkbox"*/}
        {/*          id="filter2"*/}
        {/*          name="filter2"*/}
        {/*          defaultChecked=""*/}
        {/*        />*/}
        {/*        <label htmlFor="filter2">*/}
        {/*          <span className="checkbox primary">*/}
        {/*            <span />*/}
        {/*          </span>*/}
        {/*          Flat Vector*/}
        {/*          <span className="quantity">68</span>*/}
        {/*        </label>*/}
        {/*        /!* /CHECKBOX *!/*/}
        {/*        /!* CHECKBOX *!/*/}
        {/*        <input*/}
        {/*          type="checkbox"*/}
        {/*          id="filter3"*/}
        {/*          name="filter3"*/}
        {/*          defaultChecked=""*/}
        {/*        />*/}
        {/*        <label htmlFor="filter3">*/}
        {/*          <span className="checkbox primary">*/}
        {/*            <span />*/}
        {/*          </span>*/}
        {/*          People*/}
        {/*          <span className="quantity">350</span>*/}
        {/*        </label>*/}
        {/*        /!* /CHECKBOX *!/*/}
        {/*        /!* CHECKBOX *!/*/}
        {/*        <input type="checkbox" id="filter4" name="filter4" />*/}
        {/*        <label htmlFor="filter4">*/}
        {/*          <span className="checkbox primary">*/}
        {/*            <span />*/}
        {/*          </span>*/}
        {/*          Animals*/}
        {/*          <span className="quantity">68</span>*/}
        {/*        </label>*/}
        {/*        /!* /CHECKBOX *!/*/}
        {/*        /!* CHECKBOX *!/*/}
        {/*        <input type="checkbox" id="filter5" name="filter5" />*/}
        {/*        <label htmlFor="filter5">*/}
        {/*          <span className="checkbox primary">*/}
        {/*            <span />*/}
        {/*          </span>*/}
        {/*          Objects*/}
        {/*          <span className="quantity">350</span>*/}
        {/*        </label>*/}
        {/*        /!* /CHECKBOX *!/*/}
        {/*        /!* CHECKBOX *!/*/}
        {/*        <input*/}
        {/*          type="checkbox"*/}
        {/*          id="filter6"*/}
        {/*          name="filter6"*/}
        {/*          defaultChecked=""*/}
        {/*        />*/}
        {/*        <label htmlFor="filter6">*/}
        {/*          <span className="checkbox primary">*/}
        {/*            <span />*/}
        {/*          </span>*/}
        {/*          Backgrounds*/}
        {/*          <span className="quantity">68</span>*/}
        {/*        </label>*/}
        {/*        /!* /CHECKBOX *!/*/}
        {/*      </form>*/}
        {/*    </div>*/}
        {/*    /!* /SIDEBAR MENU DROPDOWN *!/*/}
        {/*  </li>*/}
        {/*  /!* /SIDEBAR MENU ITEM *!/*/}
        {/*  /!* SIDEBAR MENU ITEM *!/*/}
        {/*  <li className="sidebar-menu-item">*/}
        {/*    /!* SVG ARROW *!/*/}
        {/*    <svg className="svg-arrow">*/}
        {/*      <use xlinkHref="#svg-arrow" />*/}
        {/*    </svg>*/}
        {/*    /!* /SVG ARROW *!/*/}
        {/*    <a href="#">File Type</a>*/}
        {/*    /!* SIDEBAR MENU DROPDOWN *!/*/}
        {/*    <div className="sidebar-menu-dropdown">*/}
        {/*      /!* CHECKBOX *!/*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        id="ft1"*/}
        {/*        name="ft1"*/}
        {/*        form="shop_search_form"*/}
        {/*      />*/}
        {/*      <label htmlFor="ft1">*/}
        {/*        <span className="checkbox primary">*/}
        {/*          <span />*/}
        {/*        </span>*/}
        {/*        Photoshop PSD*/}
        {/*        <span className="quantity">72</span>*/}
        {/*      </label>*/}
        {/*      /!* /CHECKBOX *!/*/}
        {/*      /!* CHECKBOX *!/*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        id="ft2"*/}
        {/*        name="ft2"*/}
        {/*        form="shop_search_form"*/}
        {/*        defaultChecked=""*/}
        {/*      />*/}
        {/*      <label htmlFor="ft2">*/}
        {/*        <span className="checkbox primary">*/}
        {/*          <span />*/}
        {/*        </span>*/}
        {/*        Illustrator AI*/}
        {/*        <span className="quantity">254</span>*/}
        {/*      </label>*/}
        {/*      /!* /CHECKBOX *!/*/}
        {/*      /!* CHECKBOX *!/*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        id="ft3"*/}
        {/*        name="ft3"*/}
        {/*        form="shop_search_form"*/}
        {/*        defaultChecked=""*/}
        {/*      />*/}
        {/*      <label htmlFor="ft3">*/}
        {/*        <span className="checkbox primary">*/}
        {/*          <span />*/}
        {/*        </span>*/}
        {/*        EPS*/}
        {/*        <span className="quantity">138</span>*/}
        {/*      </label>*/}
        {/*      /!* /CHECKBOX *!/*/}
        {/*      /!* CHECKBOX *!/*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        id="ft4"*/}
        {/*        name="ft4"*/}
        {/*        form="shop_search_form"*/}
        {/*        defaultChecked=""*/}
        {/*      />*/}
        {/*      <label htmlFor="ft4">*/}
        {/*        <span className="checkbox primary">*/}
        {/*          <span />*/}
        {/*        </span>*/}
        {/*        SVG*/}
        {/*        <span className="quantity">96</span>*/}
        {/*      </label>*/}
        {/*      /!* /CHECKBOX *!/*/}
        {/*      /!* CHECKBOX *!/*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        id="ft5"*/}
        {/*        name="ft5"*/}
        {/*        form="shop_search_form"*/}
        {/*      />*/}
        {/*      <label htmlFor="ft5">*/}
        {/*        <span className="checkbox primary">*/}
        {/*          <span />*/}
        {/*        </span>*/}
        {/*        InDesign INDD*/}
        {/*        <span className="quantity">102</span>*/}
        {/*      </label>*/}
        {/*      /!* /CHECKBOX *!/*/}
        {/*    </div>*/}
        {/*    /!* /SIDEBAR MENU DROPDOWN *!/*/}
        {/*  </li>*/}
        {/*  /!* /SIDEBAR MENU ITEM *!/*/}
        {/*  /!* SIDEBAR MENU ITEM *!/*/}
        {/*  <li className="sidebar-menu-item">*/}
        {/*    /!* SVG ARROW *!/*/}
        {/*    <svg className="svg-arrow">*/}
        {/*      <use xlinkHref="#svg-arrow" />*/}
        {/*    </svg>*/}
        {/*    /!* /SVG ARROW *!/*/}
        {/*    <a href="#">Price Range</a>*/}
        {/*    /!* SIDEBAR MENU DROPDOWN *!/*/}
        {/*    <div className="sidebar-menu-dropdown big">*/}
        {/*      <input*/}
        {/*        type="hidden"*/}
        {/*        className="price-range-slider"*/}
        {/*        defaultValue={500}*/}
        {/*        form="shop_search_form"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    /!* /SIDEBAR MENU DROPDOWN *!/*/}
        {/*  </li>*/}
        {/*  /!* /SIDEBAR MENU ITEM *!/*/}
        {/*</ul>*/}
        {/* /SIDEBAR MENU */}
        {/* SIDEBAR FILTERS */}
        <div className="sidebar-filters">
          {/*<form id="shop_filter_form" name="shop_filter_form">*/}
          {/*  <label htmlFor="price_filter" className="select-block">*/}
          {/*    <select name="price_filter" id="price_filter">*/}
          {/*      <option value={0}>Price (High to Low)</option>*/}
          {/*      <option value={1}>Price (Low to High)</option>*/}
          {/*    </select>*/}
          {/*    /!* SVG ARROW *!/*/}
          {/*    <svg className="svg-arrow">*/}
          {/*      <use xlinkHref="#svg-arrow" />*/}
          {/*    </svg>*/}
          {/*    /!* /SVG ARROW *!/*/}
          {/*  </label>*/}
          {/*  <label htmlFor="itemspp_filter" className="select-block">*/}
          {/*    <select name="itemspp_filter" id="itemspp_filter">*/}
          {/*      <option value={0}>12 Items Per Page</option>*/}
          {/*      <option value={1}>6 Items Per Page</option>*/}
          {/*    </select>*/}
          {/*    /!* SVG ARROW *!/*/}
          {/*    <svg className="svg-arrow">*/}
          {/*      <use xlinkHref="#svg-arrow" />*/}
          {/*    </svg>*/}
          {/*    /!* /SVG ARROW *!/*/}
          {/*  </label>*/}
          {/*</form>*/}
          {/* VIEW SELECTORS */}
          <div className="view-selectors">
            <button
              className={`view-selector grid ${
                view === "grid" ? "active" : ""
              }`}
              onClick={() => {
                setView("grid");
                localStorage.setItem("PP_View", "grid");
              }}
            />
            <button
              className={`view-selector list ${
                  view === "list" ? "active" : ""
              }`}
              onClick={() => {
                setView("list");
                localStorage.setItem("PP_View", "list");
              }}
            />
          </div>
        </div>
        {/* /SIDEBAR FILTERS */}
      </div>
    </div>
  );
}

export default ProductsNavBar;
