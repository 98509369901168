import {createContext, useReducer} from "react";
import articleReducer from "./ArticleReducer";

const ArticleContext = createContext({})

export const ArticleProvider = ({children}) => {

    const initialState = {
        blogs: [],
        loading: true,
        blog: {},
        totalPage: 1,
    };

    const [state,dispatch] = useReducer(articleReducer,initialState)

    return <ArticleContext.Provider
        value={{
            ...state,
            dispatch
        }}
    >{children}
    </ArticleContext.Provider>
}

export default ArticleContext