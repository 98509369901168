import React from 'react';
import {Link} from "react-router-dom";

const rootURL = process.env.REACT_APP_URL_ROOT;

function SupportsGriditem({support}) {
    const {title,description,slug,prices,type,product} = support;
    let cover = "";
    support.images.map((image) => (cover = image.thumb));

    let pr = 0;
    let cut = "";

    if(prices.length>0){
        pr = prices[0].price;
        cut = prices[0].cut>0 ? prices[0].cut.toString() : "";
    }

    let Type = "Silver"
    if(type==="premium"){
        Type="Gold"
    }else if(type==="vip"){
        Type="Diamond"
    }


    return (
        <div className="product-item column">
            {/* PRODUCT PREVIEW ACTIONS */}
            <div className="product-preview-actions">
                {/* PRODUCT PREVIEW IMAGE */}
                <figure className="product-preview-image big">
                    <img src={`${rootURL}/${cover}`} alt="product-image" />
                </figure>
                {/* /PRODUCT PREVIEW IMAGE */}
                {/* PREVIEW ACTIONS */}
                <div className="preview-actions">
                    {/* PREVIEW ACTION */}
                    <div className="preview-action">
                        <Link to={`/support/${slug}`} reloadDocument>
                            <div className="circle tiny primary">
                                <span className="icon-tag" />
                            </div>
                        </Link>
                        <Link to={`/support/${slug}`} reloadDocument>
                            <p>Go to Item</p>
                        </Link>
                    </div>
                    {/* /PREVIEW ACTION */}
                    {/* PREVIEW ACTION */}
                    <div className="preview-action">
                        <a href="#">
                            <div className="circle tiny secondary">
                                <span className="icon-heart" />
                            </div>
                        </a>
                        <a href="#">
                            <p>Favourites +</p>
                        </a>
                    </div>
                    {/* /PREVIEW ACTION */}
                </div>
                {/* /PREVIEW ACTIONS */}
            </div>
            {/* /PRODUCT PREVIEW ACTIONS */}
            {/* PRODUCT INFO */}
            <div className="product-info" style={{width:"90%"}}>
                <Link to={`/support/${slug}`} reloadDocument>
                    <p className="text-header">{title}</p>
                </Link>

                <p className="price big">
                    <span>{pr>0&&'£'}</span>{pr>0 ? (cut ? <>{pr}<span style={{textDecorationLine:"line-through"}}>{cut}</span></> : pr) : "Free"}
                </p>
            </div>


            {/* /USER RATING */}
        </div>
    );
}

export default SupportsGriditem;