import { createContext, useReducer } from "react";
import supportsReducer from "./SupportsReducer";


const SupportsContext = createContext({})

export const SupportsProvider = ({children}) => {
    const initialState = {
        supports: [],
        totalPage: 1,
        support:{},
        loading: true,
    };

    const [state, dispatch] = useReducer(supportsReducer, initialState);

    return <SupportsContext.Provider
    value={{
        ...state,
        dispatch
    }}
    >
        {children}
    </SupportsContext.Provider>
}

export default SupportsContext;