import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import logoMobile from "../assets/logo_mobile.png";
import AuthContext from "../context/auth/AuthProvider";
import { toast } from "react-toastify";
import GeneralContext from "../context/general/GeneralProvider";
import pullIcon from '../assets/pull-icon.png'
import {arrUrl, defaultScript} from "../context/script/script";
import {FaCoins} from "@react-icons/all-files/fa/FaCoins";
import axios from "axios";

function Header() {
  const { auth, setAuth ,header} = useContext(AuthContext);
  const { avatar,  name, email,coins,setCoins, coinTrigger ,numberWithDot} =
      useContext(GeneralContext);
  const [scripts, setScripts] = useState([]);
  const defaultScripts = (url) => {
    const script = defaultScript(url);
    setScripts((prevState) => [...prevState, script]);
  };
  useEffect(() => {
    arrUrl.map((url) => defaultScripts(url));
    if(auth?.token){
      setInterval(() => {
        axios.get(`${process.env.REACT_APP_API_ROOT}/mytoken`,header).then(res => {
          setCoins(res.data.coins)
          localStorage.setItem('PluginPressCoinsValue',res.data.coins);
        }).catch(err => console.log(err));
      },10000)
    }

  }, []);

  useEffect(() => {
    arrUrl.map((url) => defaultScripts(url));
    if(auth?.token) {
      axios.get(`${process.env.REACT_APP_API_ROOT}/mytoken`, header).then(res => {
        setCoins(res.data.coins)
        localStorage.setItem('PluginPressCoinsValue', res.data.coins);
      }).catch(err => console.log(err));
    }
  },[coinTrigger])

  const openUserMenu = () => {
    const el = document.getElementById("userMenu");
    el.classList.remove("closed");
    el.classList.add("open");
    el.classList.add("active");
  };

  const closeUserMenu = () => {
    const el = document.getElementById("userMenu");
    el.classList.remove("open");
    el.classList.remove("active");
    el.classList.add("closed");
  };

  const logOut = () => {
    localStorage.removeItem('PluginPressTokenValue')
    localStorage.removeItem('PluginPressEmailValue')
    localStorage.removeItem('PluginPressNameValue')
    localStorage.removeItem('PluginPressAvatarValue')
    localStorage.removeItem('PluginPressAboutMeValue')
    localStorage.removeItem('PluginPressAddressValue')
    localStorage.removeItem('PluginPressAddress2Value')
    localStorage.removeItem('PluginPressCityValue')
    localStorage.removeItem('PluginPressPostalValue')
    localStorage.removeItem('PluginPressCountryID')
    localStorage.removeItem('PluginPressCompanyValue')
    setAuth(null);
    document.getElementById('hiddenLoginLink').click();
    toast.info("You have logged out!");
  };



  return (
    <div className="header-wrap">
      <Link to="/login" id="hiddenLoginLink" style={{display:"none"}} reloadDocument/>
      <header>
        {/* LOGO */}
        <Link to="/" >
          <figure className="logo">
            <img src={logo} alt="logo" />
          </figure>
        </Link>
        {/* /LOGO */}
        {/* MOBILE MENU HANDLER */}
        <div className="mobile-menu-handler left primary">
          <img src={pullIcon} alt="pull-icon" />
        </div>
        {/* /MOBILE MENU HANDLER */}
        {/* LOGO MOBILE */}
        <Link to="/">
          <figure className="logo-mobile">
            <img src={logoMobile} alt="logo-mobile" />
          </figure>
        </Link>
        {/* /LOGO MOBILE */}
        {/* MOBILE ACCOUNT OPTIONS HANDLER */}
        <div className="mobile-account-options-handler right secondary">
          <span className="icon-user" />
        </div>
        {/* /MOBILE ACCOUNT OPTIONS HANDLER */}
        {/* USER BOARD */}
        <div className="user-board">
          {/* USER QUICKVIEW */}
          {auth && (
            <div
              className="user-quickview"
              onMouseEnter={openUserMenu}
              onMouseLeave={closeUserMenu}
            >
              {/* USER AVATAR */}
              <a href="author-profile.html">
                <div className="outer-ring">
                  <div className="inner-ring" />
                  <figure className="user-avatar">
                    <img src={avatar} alt="avatar" />
                  </figure>
                </div>
              </a>
              {/* /USER AVATAR */}
              {/* USER INFORMATION */}
              <p className="user-name">{email}</p>
              {/* SVG ARROW */}
              <svg className="svg-arrow">
                <use xlinkHref="#svg-arrow" />
              </svg>
              {/* /SVG ARROW */}
              <p className="user-money">{name}</p>
              {/* /USER INFORMATION */}
              {/* DROPDOWN */}
              <ul className="dropdown small hover-effect closed" id="userMenu">
                <li className="dropdown-item">
                  <Link to="/account" reloadDocument={true}>Account Settings</Link>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-triangle" />
                  <Link to="/my-products" reloadDocument={true}>My Products</Link>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-triangle" />
                  <Link to="/tickets" reloadDocument={true}>Tickets</Link>
                </li>

                <li className="dropdown-item">
                  <Link to="/products" reloadDocument={true}>Buy Tokens</Link>
                </li>


                <li className="dropdown-item">
                  <a href="#" onClick={logOut}>
                    Logout
                  </a>
                </li>
              </ul>
              {/* /DROPDOWN */}
            </div>
          )}
          {/* /USER QUICKVIEW */}
          {/* ACCOUNT INFORMATION */}
          {auth && (
            <div className="account-information">
              <Link to="/products">
                <div className="account-wishlist-quickview">
                  <span><FaCoins style={{color:"gold"}}/></span>
                  <span style={{color:"#fff", position:"relative", bottom:"3px", marginLeft:"3px"}}>{numberWithDot(coins)}</span>
                </div>
              </Link>

            </div>
          )}
          {/* /ACCOUNT INFORMATION */}
          {/* ACCOUNT ACTIONS */}
          {!auth && (
            <div className="account-actions">
              <Link to="/register" className="button primary">
                Register
              </Link>
              <Link to="/login" className="button secondary">
                Login
              </Link>
            </div>
          )}
          {/* /ACCOUNT ACTIONS */}
        </div>
        {/* /USER BOARD */}
      </header>
    </div>
  );
}

export default Header;
