import React, {useContext, useState} from "react";
import AuthContext from "../context/auth/AuthProvider";
import {Link, Navigate} from "react-router-dom";
import {BiLoaderCircle} from "@react-icons/all-files/bi/BiLoaderCircle"
import {pluginPressLogin} from "../context/auth/AuthActions";
import {toast} from "react-toastify";
import Seo from "../components/Seo";

function Login() {
  const { auth, setAuth } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)



  const loginNow = (e) => {
      e.preventDefault()
      console.log(email)
      setLoading(true)
      if(email.length>0 && password.length>0){
          try{
              pluginPressLogin(email,password).then(data=>{
                  if(data.status === "ok"){
                      localStorage.setItem('PluginPressWebSitesValue',JSON.stringify(data.websites))
                      localStorage.setItem('PluginPressTokenValue',data.token)
                      localStorage.setItem('PluginPressCoinsValue',data.coins)
                      localStorage.setItem('PluginPressEmailValue',data.email)
                      localStorage.setItem('PluginPressNameValue',data.name)
                      localStorage.setItem('PluginPressAvatarValue',data.avatar)
                      localStorage.setItem('PluginPressAboutMeValue',data.aboutme)
                      localStorage.setItem('PluginPressAddressValue',data.address)
                      localStorage.setItem('PluginPressAddress2Value',data.address2)
                      localStorage.setItem('PluginPressStreetValue',data.street)
                      localStorage.setItem('PluginPressCityValue',data.city)
                      localStorage.setItem('PluginPressPostalValue',data.postal)
                      localStorage.setItem('PluginPressCountryID',data.countryID)
                      localStorage.setItem('PluginPressCompanyValue',data.company)
                      localStorage.setItem('PluginPressWebCountValue',data.webcount)
                      localStorage.setItem('PluginPressSubValue',data.sub)
                      localStorage.setItem('PluginPressApiKey',data.apiKey)
                      localStorage.setItem('PluginPressIDValue',data.id)
                      setAuth({
                          ...data

                      })
                      toast.success('You have logged in!')

                  }else{
                      toast.error(data.message)
                  }

              }).then(()=>{
                  document.getElementById('hiddenProfileLink').click();
                  setLoading(false)
              }).catch(err => {
                  toast.error(err.response.data.message)
                  setLoading(false)
              })


          }catch(err){
              toast.error(err.response.data.message)
              setLoading(false)
          }
      }else{
          toast.info('You have to fill password and email fields')
          setLoading(false)
      }
  }



  //Middleware redirect
  if (auth?.token) {
   return !loading && <Navigate to="/account"/>;
  }

  return (
    <>
        <Seo
            title={`Log in to your Account - PluginPress.net`}
        />
        <div className="section-wrap">
            <Link to="/account" id="hiddenProfileLink" reloadDocument style={{display:"none"}}/>
            <div className="form-popup" style={{marginTop:"50px", marginBottom:"50px"}}>
                <div className="form-popup-headline secondary">
                    <h2>Login to your Account</h2>
                    <p>Enter now to your account</p>
                </div>
                <div className="form-popup-content">
                    <div id="login-form">
                        <label htmlFor="email" className="rl-label">
                            Email
                        </label>
                        <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Enter your username here..."
                        />
                        <label htmlFor="password" className="rl-label">
                            Password
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Enter your password here..."
                        />

                        <p>
                            Forgot your password?{" "}
                            <Link to="/forgotpassword" className="primary">
                                Click here!
                            </Link>
                        </p>
                        <button className="button mid dark" onClick={loginNow}>
                            {!loading ? <>Login <span className="primary">Now!</span></> : <span className="primary"><BiLoaderCircle className="rotating"/></span>}
                        </button>

                    </div>

                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    </>
  );
}

export default Login;
