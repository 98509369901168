import { createContext, useReducer, useState } from "react";
import bundlesReducer from "./BundlesReducer";

const BundlesContext = createContext({});

export const BundlesProvider = ({ children }) => {
  const [view, setView] = useState(
    localStorage.getItem("PB_View") ? localStorage.getItem("PB_View") : "grid"
  );

  const initialState = {
    bundles: [],
    loading: true,
    bundle: {},
    totalPage: 1,
  };

  const [state, dispatch] = useReducer(bundlesReducer, initialState);

  return (
    <BundlesContext.Provider
      value={{
        ...state,
        dispatch,
        view,
        setView,
      }}
    >
      {children}
    </BundlesContext.Provider>
  );
};

export default BundlesContext;
