import {createContext, useReducer} from "react";
import seoReducer from "./SeoReducer";

const SeoContext = createContext({})

export const SeoProvider = ({children}) => {
    const initialState = {
        blogs: [],
        loading: true,
        title:"",
        totalPage: 1,
    };

    const [state,dispatch] = useReducer(seoReducer,initialState)

    return <SeoContext.Provider
        value={{
            ...state,
            dispatch
        }}
    >
        {children}
    </SeoContext.Provider>

}

export default SeoContext