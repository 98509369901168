import React, {useContext, useEffect, useState} from "react";
import ProductsContext from "../context/products/ProductsProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import { getProduct } from "../context/products/ProductsActions";
import { arrUrl, defaultScript } from "../context/script/script";
import {FaWordpressSimple} from "@react-icons/all-files/fa/FaWordpressSimple";
import {priceTranslator} from "../context/script/priceTranslator";
import Spinner from "../components/Spinner";
import {FaCcStripe} from "@react-icons/all-files/fa/FaCcStripe";
import {FaWordpress} from "@react-icons/all-files/fa/FaWordpress";
import axios from "axios";
import AuthContext from "../context/auth/AuthProvider";
import {BiLoader} from "@react-icons/all-files/bi/BiLoader";
import {toast} from "react-toastify";
import {FaGift} from "@react-icons/all-files/fa/FaGift";
import {FaPlug} from "@react-icons/all-files/fa/FaPlug";
import Seo from "../components/Seo";
import {FaCoins} from "@react-icons/all-files/fa/FaCoins";
import GeneralContext from "../context/general/GeneralProvider";
import NewsLetter from "../components/NewsLetter";



const apiLink = process.env.REACT_APP_API_ROOT;
const rootURL = process.env.REACT_APP_URL_ROOT;

function Product() {
  const {header,auth} = useContext(AuthContext)
  const { dispatch, product } = useContext(ProductsContext);
  const {numberWithDot} = useContext(GeneralContext)
  const [loading, setLoading] = useState(true)
  const params = useParams();
  const [price,setPrice] = useState("");
  const [priceCut, setPriceCut] = useState("");
  const [pricePack,setPricePack] = useState({})
  const [scripts, setScripts] = useState([]);
  const [purchasing, setPurchasing] = useState(false)
  const [credits, setCredits] = useState(0)
  const navigate = useNavigate()
  const defaultScripts = (url) => {
    const script = defaultScript(url);
    setScripts((prevState) => [...prevState, script]);
  };


  const getProductBySlug = () => {
    getProduct(params.slug)
      .then((res) => dispatch({ type: "GET_PRODUCT", payload: res }))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
      getProductBySlug()
  },[])

  useEffect(() => {
    if(Object.keys(product).length>0){
      setPrice(product?.prices?.length>0 ? product.prices[0].price.toString() : "Free")
      setPriceCut(product?.prices?.length>0 && product?.prices[0].cut ? product?.prices[0].cut: "")
      setPricePack(product?.prices?.length>0 ? product.prices[0] :{})
      setCredits(product?.prices?.length>0 ? product.prices[0].credits :0)
      arrUrl.map((url) => defaultScripts(url));
      setLoading(false)
      if(product.publish===0){
        navigate('/');
      }
    }
  }, [product]);






  const handlePrice = (e) => {
    const pr = e.target.value
    setPrice(pr)
    let priceSelected = product.prices.find(item => parseInt(item.price) === parseInt(pr));
    setPricePack(priceSelected)
    setPriceCut(priceSelected?.cut ? priceSelected.cut : "");

  }






const getStripe = () => {
  setPurchasing(true)
  const url = `${process.env.REACT_APP_API_ROOT}/stripe/create`
  if(auth?.token){
    axios.post(url, {price:JSON.stringify(pricePack),type:"product"},header).then(res => {
      if(res?.data?.url){
        window.location.href = res.data.url
        // console.log(res.data)
        setPurchasing(false)
      }else{
        if(res.data.message === "exist"){
          toast.info('You have this product already!')
          setPurchasing(false)
        }
        if(res.data.message === "subbed"){
          toast.info('You have already subscribed this service!')
          setPurchasing(false)
          navigate('/my-products')
        }
      }
    })
  }else{
    toast.info('You have to log in')
    navigate('/login')
  }
}

  const offCal = (pr,ct) => {
    return 100 - Math.floor((parseInt(pr)*100)/ct)
  }

const claimProduct = () => {
  if(auth?.token){
    setPurchasing(true)
    const url = `${apiLink}/claimproduct/${product.id}`;
    axios.get(url,header).then(res => {
      if(res.data.message === "ok"){
        toast.success('You have added this product!');
      }else{
        toast.info(res.data.message);
      }
      setPurchasing(false)
    }).catch(err => console.log(err))
    setPurchasing(false)
  }else{
    toast.warn('You have to register to claim this product')
    navigate('/register')
  }
}



  return loading ? <Spinner/> :(
    <>
      <Seo
          title={`${product.name} - Products - PluginPress.net`}
          keywords={product.keywords}
      />

      {/* SECTION HEADLINE */}
      <div className="section-headline-wrap">
        <div className="section-headline">
          <h2>{product.name}</h2>
          <p>
            Home<span className="separator">/</span>Products
            <span className="separator">/</span>
            <span className="current-section">{product.name}</span>
          </p>
        </div>
      </div>
      {/* /SECTION HEADLINE */}
      {/* SECTION */}
      <div className="section-wrap">
        <div className="section">
          {/* SIDEBAR */}
          <div className="sidebar right">
            {/* SIDEBAR ITEM */}
            <div className="sidebar-item">
              <p className="price large">
                {Object.keys(product).length>0 && product.prices.length>0 ? (
                    <>
                      <span>£</span>{price} {priceCut&&<><br/><span style={{color:"red"}}>-%{offCal(price, priceCut)}&nbsp;</span><span
                        style={{textDecorationLine: "line-through"}}>£{priceCut}</span></>}
                    </>
                ) : "Free"}
              </p>
              <hr className="line-separator" />
              {Object.keys(product).length>0&&product.prices.length>1 ? (
                  <label className="select-block">
                    <select
                        id="sub-select"
                        value={price.toString()}
                        onChange={handlePrice}
                        style={{backgroundColor: "#2b373a", color: "#00d6b4"}}
                    >
                      {product.prices.map(pr => (
                          <option value={pr.price.toString()} key={pr.id}>{priceTranslator(pr)}</option>
                      ))}
                    </select>
                    <svg className="svg-arrow">
                      <use xlinkHref="#svg-arrow"/>
                    </svg>
                  </label>
              ):
                  credits>0 && <div style={{padding:"10px 20px", fontSize:"30px", fontWeight:"800",textAlign:"center", marginBottom:"10px", color:"#2b373a"}}>
                    <FaCoins style={{color:"#f8b204", position:"relative", top:"3px"}}/> {numberWithDot(credits)}
                  </div>
              }
              <hr className="line-separator" />

              {price>0 ?<button className="button mid dark spaced" onClick={getStripe}>
                <span className="primary">{purchasing ? <BiLoader className="rotating"/> : <><FaCcStripe style={{fontSize:"30px", position:"relative", top:"5px"}}/> <span style={{position:"relative",bottom:"5px"}}>Purchase Now!</span></> }</span>
              </button> : <button className="button mid dark spaced" onClick={claimProduct}><span className="primary">{purchasing ? <BiLoader className="rotating"/> :<><FaGift style={{fontSize:"25px", position:"relative", top:"5px"}}/> <span style={{position:"relative",bottom:"0"}}>Claim it!</span></>}</span></button>}
              <div className="clearfix" />
            </div>
            {/* /SIDEBAR ITEM */}
            {/* SIDEBAR ITEM */}
            <div className="sidebar-item author-bio">
              {/* USER AVATAR */}
              <Link to={`/plugin/${Object.keys(product).length>0&&product.plugin.slug}`} className="user-avatar-wrap medium">
                <figure className="user-avatar medium">
                  <img src={`${rootURL}/${Object.keys(product).length>0&&product.plugin.icon}`} alt="" />
                </figure>
              </Link>
              {/* /USER AVATAR */}
              <p className="text-header">{Object.keys(product).length>0&&product.plugin.name}</p>


              <a href={product?.plugin?.wordpress&&product.plugin.wordpress} className="button mid dark spaced" target="_blank">
                <FaWordpressSimple style={{position:"relative",top:"1.5px"}}/> Go to <span className="primary">Wordpress Page</span>
              </a>
              <Link to={product?.plugin?.slug &&`/plugin/${product.plugin.slug}`} className="button mid dark spaced">
                <FaPlug style={{position:"relative",top:"1.5px"}}/> Go to <span className="primary">Plugin Page</span>
              </Link>

            </div>
            {/* /SIDEBAR ITEM */}



          </div>
          {/* /SIDEBAR */}
          {/* CONTENT */}
          <div className="content left">
            {/* POST */}
            <article className="post">
              {/* POST IMAGE */}
              <div className="post-image">
                <figure className="product-preview-image large liquid">
                  <img src={Object.keys(product).length>0&&product.images.length>0&&`${rootURL}/${product.images[0].thumb.replaceAll('_thumb','')}` } alt="" />
                </figure>
                {/* SLIDE CONTROLS */}
                {
                  Object.keys(product).length>0 && product.images.length>1 &&(
                        <div className="slide-control-wrap">
                          <div className="slide-control rounded left">
                            {/* SVG ARROW */}
                            <svg className="svg-arrow">
                              <use xlinkHref="#svg-arrow"/>
                            </svg>
                            {/* /SVG ARROW */}
                          </div>
                          <div className="slide-control rounded right">
                            {/* SVG ARROW */}
                            <svg className="svg-arrow">
                              <use xlinkHref="#svg-arrow"/>
                            </svg>
                            {/* /SVG ARROW */}
                          </div>
                        </div>
                    )
                }
                {/* /SLIDE CONTROLS */}
                <a href={product.demo} className="button mid primary" target="_blank">
                  <FaWordpress style={{position: "relative", top: "2.5px"}}/> Go to Live Demo
                </a>
              </div>
              {/* /POST IMAGE */}
              {/* POST IMAGE SLIDES */}
              {product?.images.length>1 && (
                  <div className="post-image-slides">
                    {/* IMAGE SLIDES WRAP */}
                    <div className="image-slides-wrap full">
                      {/* IMAGE SLIDES */}
                      <div
                          className="image-slides"
                          data-slide-visible-full="8"
                          data-slide-visible-small="2"
                          data-slide-count={Object.keys(product).length > 0 && product.images.length.toString()}
                      >
                        {Object.keys(product).length > 0 && product.images.map((image, key) => (

                            <div className={`image-slide${key === 0 ? " selected" : ""}`} key={image.id}
                                 style={{background: `url(${rootURL}/${image.thumb})`}}>
                              <div className="overlay"/>
                              <figure className="product-preview-image thumbnail liquid">
                                <img src={`${rootURL}/${image.path}`} alt="" style={{visibility: "hidden"}}/>
                              </figure>
                            </div>

                        ))}
                      </div>
                      {/* IMAGE SLIDES */}
                    </div>
                    {/* IMAGE SLIDES WRAP */}
                  </div>
              )}
              {/* /POST IMAGE SLIDES */}
              <hr className="line-separator"/>
              {/* POST CONTENT */}
              <div className="post-content">
                <div dangerouslySetInnerHTML={{__html: product.description}}/>
                <div className="clearfix"/>
              </div>

            </article>
            {/* /POST */}
            {/* POST TAB */}
            {product?.documentations.length>0 && product?.faqs.length>0 && (
                <div className="post-tab">
                  {/* TAB HEADER */}
                  <div className="tab-header primary">
                    {/* TAB ITEM */}
                    <div className="tab-item selected">
                      <p className="text-header">Docs</p>
                    </div>
                    {/* /TAB ITEM */}
                    {/* TAB ITEM */}
                    <div className="tab-item">
                      <p className="text-header">FAQ</p>
                    </div>
                    {/* /TAB ITEM */}
                  </div>
                  {/* /TAB HEADER */}
                  {/* TAB CONTENT */}
                  <div className="tab-content">
                    {/* ITEM-FAQ */}
                    <div className="accordion item-faq primary">
                      {product?.documentations?.length > 0 ? product.documentations.map(doc => (
                          <div className="accordion-item" key={doc.id}>
                            <h6 className="accordion-item-header">
                              {doc.title}
                            </h6>
                            {/* SVG ARROW */}
                            <svg className="svg-arrow">
                              <use xlinkHref="#svg-arrow"/>
                            </svg>
                            {/* /SVG ARROW */}
                            <div className="accordion-item-content">
                              <p dangerouslySetInnerHTML={{__html: doc.content}}/>
                            </div>
                          </div>
                      )) : <p>Documentation content is not ready yet....</p>}
                    </div>
                    {/* /ITEM-FAQ */}
                  </div>
                  {/* /TAB CONTENT */}
                  {/* TAB CONTENT */}
                  <div className="tab-content">
                    {/* ITEM-FAQ */}
                    <div className="accordion item-faq primary">
                      {product?.faqs?.length > 0 ? product.faqs.map(faq => (
                          <div className="accordion-item" key={faq.id}>
                            <h6 className="accordion-item-header">
                              {faq.title}
                            </h6>
                            {/* SVG ARROW */}
                            <svg className="svg-arrow">
                              <use xlinkHref="#svg-arrow"/>
                            </svg>
                            {/* /SVG ARROW */}
                            <div className="accordion-item-content">
                              <p dangerouslySetInnerHTML={{__html: faq.content}}/>
                            </div>
                          </div>
                      )) : <p>FAQ content is not ready yet....</p>}
                    </div>
                    {/* /ITEM-FAQ */}
                  </div>
                  {/* /TAB CONTENT */}
                </div>
            )}
            {/* /POST TAB */}
          </div>
          {/* CONTENT */}
        </div>
      </div>
      {/* /SECTION */}
      <NewsLetter/>
    </>
  );
}

export default Product;
