import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../context/auth/AuthProvider";
import SupportsContext from "../context/supports/SupportsProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {arrUrl, defaultScript} from "../context/script/script";
import {getSupport} from "../context/supports/SupportsActions";
import axios from "axios";
import {toast} from "react-toastify";
import Spinner from "../components/Spinner";
import Seo from "../components/Seo";
import {priceTranslator} from "../context/script/priceTranslator";
import {BiLoader} from "@react-icons/all-files/bi/BiLoader";
import {FaCcStripe} from "@react-icons/all-files/fa/FaCcStripe";
import {FaPlug} from "@react-icons/all-files/fa/FaPlug";
import NewsLetter from "../components/NewsLetter";

const apiLink = process.env.REACT_APP_API_ROOT;
const rootURL = process.env.REACT_APP_URL_ROOT;

function Support() {
    const {header,auth} = useContext(AuthContext)
    const {dispatch, support} = useContext(SupportsContext)
    const [loading, setLoading] = useState(true)
    const params = useParams();
    const [price,setPrice] = useState("");
    const [priceCut, setPriceCut] = useState("");
    const [pricePack,setPricePack] = useState({})
    const [scripts, setScripts] = useState([]);
    const [purchasing, setPurchasing] = useState(false)
    const navigate = useNavigate()
    const defaultScripts = (url) => {
        const script = defaultScript(url);
        setScripts((prevState) => [...prevState, script]);
    };

    const getSupportBySlug = () => {
        getSupport(params.slug)
            .then((res) => dispatch({ type: "GET_SUPPORT", payload: res }))
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getSupportBySlug()
    }, []);

    useEffect(() => {
        if(Object.keys(support).length>0){
            setPrice(support?.prices?.length>0 ? support.prices[0].price.toString() : "Free")
            setPriceCut(support?.prices?.length>0 && support?.prices[0].cut ? support?.prices[0].cut: "")
            setPricePack(support?.prices?.length>0 ? support.prices[0] :{})
            arrUrl.map((url) => defaultScripts(url));
            setLoading(false)
            if(support.publish===0){
                navigate('/');
            }
        }
    }, [support]);

    const handlePrice = (e) => {
        const pr = e.target.value
        setPrice(pr)
        let priceSelected = support.prices.find(item => parseInt(item.price) === parseInt(pr));
        setPricePack(priceSelected)
        setPriceCut(priceSelected?.cut ? priceSelected.cut : "");

    }

    const getStripe = () => {
        setPurchasing(true)
        const url = `${process.env.REACT_APP_API_ROOT}/stripe/create`
        if(auth?.token){
            axios.post(url, {price:JSON.stringify(pricePack),type:"support"},header).then(res => {
                if(res?.data?.url){
                    window.location.href = res.data.url
                    // console.log(res.data)
                    setPurchasing(false)
                }else{
                    if(res.data.message === "exist"){
                        toast.info('You have this product already!')
                        setPurchasing(false)
                    }
                    if(res.data.message === "subbed"){
                        toast.info('You have already subscribed this service!')
                        setPurchasing(false)
                        navigate('/tickets')
                    }
                }
            })
        }else{
            toast.info('You have to log in!')
            navigate('/login')
        }
    }


    return loading ? <Spinner/> :(
        <>
            <Seo
                title={`${support.title} - Support Service - PluginPress.net`}
                keywords={support.keywords}
            />
            <div className="section-headline-wrap">
                <div className="section-headline">
                    <h2>{support.title}</h2>
                    <p>
                        Home<span className="separator">/</span>Supports
                        <span className="separator">/</span>
                        <span className="current-section">{support.title}</span>
                    </p>
                </div>
            </div>
            <div className="section-wrap">
                <div className="section">
                    <div className="sidebar right">
                        {/* SIDEBAR ITEM */}
                        <div className="sidebar-item">
                            <p className="price large">
                                {Object.keys(support).length>0 && support.prices.length>0 ? (
                                    <>
                                        <span>£</span>{price}{priceCut&&<span style={{textDecorationLine:"line-through"}}>£{priceCut}</span>}
                                    </>
                                ) : "Free"}
                            </p>
                            <hr className="line-separator" />
                            {Object.keys(support).length>0&&support.prices.length>1&&(
                                <label className="select-block">
                                    <select
                                        id="sub-select"
                                        value={price.toString()}
                                        onChange={handlePrice}
                                        style={{backgroundColor: "#2b373a", color: "#00d6b4"}}
                                    >
                                        {support.prices.map(pr => (
                                            <option value={pr.price.toString()} key={pr.id}>{priceTranslator(pr)}</option>
                                        ))}
                                    </select>
                                    <svg className="svg-arrow">
                                        <use xlinkHref="#svg-arrow"/>
                                    </svg>
                                </label>
                            )}


                            <button className="button mid dark spaced" onClick={getStripe}>
                                <span className="primary">{purchasing ? <BiLoader className="rotating"/> : <><FaCcStripe style={{fontSize:"30px", position:"relative", top:"5px"}}/> <span style={{position:"relative",bottom:"5px"}}>Purchase Now!</span></> }</span>
                            </button>
                            <div className="clearfix" />
                        </div>
                        {/* /SIDEBAR ITEM */}
                        {/* SIDEBAR ITEM */}
                        <div className="sidebar-item author-bio">
                            {/* USER AVATAR */}
                            <Link to={`/product/${Object.keys(support).length>0&&support.product.slug}`} className="user-avatar-wrap medium">
                                <figure className="user-avatar medium">
                                    <img src={`${rootURL}/${Object.keys(support).length>0&&support.product.plugin.icon}`} alt="" />
                                </figure>
                            </Link>
                            {/* /USER AVATAR */}
                            <p className="text-header">{Object.keys(support).length>0&&support.product.name}</p>


                            <Link to={support?.product?.slug &&`/product/${support.product.slug}`} className="button mid dark spaced">
                                <FaPlug style={{position:"relative",top:"1.5px"}}/> Go to <span className="primary">Product Page</span>
                            </Link>

                        </div>
                        {/* /SIDEBAR ITEM */}
                    </div>
                    <div className="content left">
                        <article className="post">
                            <div className="post-image">
                                <figure className="product-preview-image large liquid">
                                    <img src={Object.keys(support).length>0&&support.images.length>0&&`${rootURL}/${support.images[0].thumb.replaceAll('_thumb','')}` } alt="" />
                                </figure>
                                {/* SLIDE CONTROLS */}
                                {support?.images.length > 1 && <div className="slide-control-wrap">
                                    <div className="slide-control rounded left">
                                        {/* SVG ARROW */}
                                        <svg className="svg-arrow">
                                            <use xlinkHref="#svg-arrow"/>
                                        </svg>
                                        {/* /SVG ARROW */}
                                    </div>
                                    <div className="slide-control rounded right">
                                        {/* SVG ARROW */}
                                        <svg className="svg-arrow">
                                            <use xlinkHref="#svg-arrow"/>
                                        </svg>
                                        {/* /SVG ARROW */}
                                    </div>
                                </div>}
                                {/* /SLIDE CONTROLS */}

                            </div>
                            {/* /POST IMAGE */}
                            {/* POST IMAGE SLIDES */}
                            {support?.images.length > 1 && <div className="post-image-slides">
                                {/* IMAGE SLIDES WRAP */}
                                <div className="image-slides-wrap full">
                                    {/* IMAGE SLIDES */}
                                    <div
                                        className="image-slides"
                                        data-slide-visible-full="8"
                                        data-slide-visible-small="2"
                                        data-slide-count={Object.keys(support).length > 0 && support.images.length.toString()}
                                    >
                                        {Object.keys(support).length > 0 && support.images.map((image, key) => (

                                            <div className={`image-slide${key === 0 ? " selected" : ""}`} key={image.id}
                                                 style={{background: `url(${rootURL}/${image.thumb})`}}>
                                                <div className="overlay"/>
                                                <figure className="product-preview-image thumbnail liquid">
                                                    <img src={`${rootURL}/${image.path}`} alt=""
                                                         style={{visibility: "hidden"}}/>
                                                </figure>
                                            </div>

                                        ))}
                                    </div>
                                    {/* IMAGE SLIDES */}
                                </div>
                                {/* IMAGE SLIDES WRAP */}
                            </div>}
                            {/* /POST IMAGE SLIDES */}
                            <hr className="line-separator"/>
                            {/* POST CONTENT */}
                            <div className="post-content">
                                <div dangerouslySetInnerHTML={{__html: support.description}}/>
                                <div className="clearfix"/>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <NewsLetter/>
        </>
    );
}

export default Support;