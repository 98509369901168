import React, {useContext, useEffect, useState} from "react";
import BundlesContext from "../context/bundles/BundlesProvider";
import { getBundles } from "../context/bundles/BundlesActions";
import Spinner from "../components/Spinner";
import BundlesHeadline from "../components/bundles/BundlesHeadline";
import BundlesNavbar from "../components/bundles/BundlesNavbar";
import Pagination from "../components/Pagination";
import BundlesGridItem from "../components/bundles/BundlesGridItem";
import BundlesListItem from "../components/bundles/BundlesListItem";
import Seo from "../components/Seo";

function Bundles() {
  const { view, dispatch, loading, bundles, totalPage } =
    useContext(BundlesContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const getAllBundles = () => {
    getBundles(search, page)
      .then((res) => dispatch({ type: "GET_BUNDLES", payload: res }))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
      getAllBundles();
  },[page,search])

  return loading ? <Spinner/> : (
      <>
          <Seo
              title="Bundles - PluginPress.net"
          />
        <BundlesHeadline/>

        {/* SECTION */}
        <div className="section-wrap">
          <div className="section">
            {/* PRODUCT SHOWCASE */}
            <div className="product-showcase">
              {/* PRODUCT LIST */}
              <div className={`product-list list full`}>

                {loading ? <Spinner/> : bundles.map(bundle => (


                         <BundlesListItem key={bundle.id} bundle={bundle}/>


                    )
                )}

              </div>
              {/* /PRODUCT LIST */}
            </div>
            {/* /PRODUCT SHOWCASE */}
            <div className="clearfix" />
            {/* PAGER */}
            <Pagination page={page} setPage={setPage} totalPage={totalPage}/>
            {/* /PAGER */}
          </div>
        </div>
        {/* /SECTION */}
      </>
  );
}

export default Bundles;
