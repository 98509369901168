import React from 'react';
import {Link} from "react-router-dom";
import Seo from "../components/Seo";

function HowTo(props) {
    return (
        <>
            <Seo
                title="How to Shop"
            />
            {/* SECTION HEADLINE */}
            <div className="section-headline-wrap">
                <div className="section-headline">
                    <h2>How to Shop</h2>
                    <p>
                        Home<span className="separator">/</span>
                        <span className="current-section">How to Shop</span>
                    </p>
                </div>
            </div>
            {/* /SECTION HEADLINE */}
            {/* HT BANNER WRAP */}
            <div className="ht-banner-wrap">
                {/* HT BANNER */}
                <div className="ht-banner void violet">
                    <figure className="ht-banner-img1">
                        <img src="images/how_to_shop_01.png" alt="" />
                    </figure>
                </div>
                {/* /HT BANNER */}
                {/* HT BANNER */}
                <div className="ht-banner">
                    {/* HT BANNER CONTENT */}
                    <div className="ht-banner-content">
                        <p className="text-header">Create an account and get your Api-Key</p>
                        <p>
                            With just a few simple steps, you can gain access to our products and services that can enhance your online experience. Simply register on our site and receive your very own api-key that will enable you to fully leverage the power of our platform.
                        </p>
                        <Link to="/register" className="button mid dark">
                            Create your <span className="primary">New Account</span>
                        </Link>
                    </div>
                    {/* /HT BANNER CONTENT */}
                </div>
                {/* /HT BANNER */}
                {/* HT BANNER */}
                <div className="ht-banner void pink">
                    <figure className="ht-banner-img2">
                        <img src="images/how_to_shop_02.png" alt="" />
                    </figure>
                </div>
                {/* /HT BANNER */}
                {/* HT BANNER */}
                <div className="ht-banner">
                    {/* HT BANNER CONTENT */}
                    <div className="ht-banner-content">
                        <p className="text-header">Browse Our Shop Items</p>
                        <p>
                            With Stripe, buying the premium features of our plugins has never been easier. You can rest assured that your transaction will be safe and secure, and that your personal information will be protected every step of the way. Plus, with Stripe's easy-to-use platform, you can complete your purchase in just a few clicks, saving you time and hassle.
                        </p>
                        <Link to="/products" className="button mid dark">
                            <span className="primary">Most Popular</span> Items
                        </Link>
                    </div>
                    {/* /HT BANNER CONTENT */}
                </div>
                {/* /HT BANNER */}
                {/* HT BANNER */}
                <div className="ht-banner void blue">
                    <figure className="ht-banner-img3">
                        <img src="images/how_to_shop_03.png" alt="" />
                    </figure>
                </div>
                {/* /HT BANNER */}
                {/* HT BANNER */}
                <div className="ht-banner">
                    {/* HT BANNER CONTENT */}
                    <div className="ht-banner-content">
                        <p className="text-header">Activate Premium Settings</p>
                        <p>
                            With the api-key that you registered on our site, you can easily activate the premium settings and features that come with your plugins.
                        </p>
                        <Link to="/plugins" className="button mid dark">
                            Our <span className="primary">Plugins</span>
                        </Link>
                    </div>
                    {/* /HT BANNER CONTENT */}
                </div>
                {/* /HT BANNER */}
            </div>
            {/* /HT BANNER WRAP */}
        </>

    );
}

export default HowTo;