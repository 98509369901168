import React, {useEffect, useState} from 'react';
import clerk from '../assets/clerk.png'
import axios from "axios";
import Products from "../pages/Products";
import {Link} from "react-router-dom";

function Footer() {
    const [members, setMembers] = useState(0)
    const [products, setProducts] = useState(0)
    const [plugins, setPlugins] = useState(0)

    const getStats = () => {
        const url = `${process.env.REACT_APP_API_ROOT}/stats`
        axios.get(url).then(res => {
            setMembers(res.data.members);
            setProducts(res.data.products);
            setPlugins(res.data.plugins)
        }).catch(err=> console.log(err))
    }

    useEffect(()=>{
        getStats()
    },[])

    return (
        <footer>
            {/* FOOTER TOP */}
            <div id="footer-top-wrap">
                <div id="footer-top">
                    {/* COMPANY INFO */}
                    <div className="company-info">
                        <a href="https://clerksoftware.com" target="_blank" rel="noreferrer">
                        <figure className="logo small">
                           <img src={clerk} alt="logo-small" />
                        </figure>
                        </a>
                        <p>
                            This site is owned by Clerk Software LTD.
                        </p>
                        <ul className="company-info-list">
                            <li className="company-info-item">
                                <span className="icon-present" />
                                <p>
                                    <span>{products}</span> Products
                                </p>
                            </li>
                            {/*<li className="company-info-item">*/}
                            {/*    <span className="icon-energy" />*/}
                            {/*    <p>*/}
                            {/*        <span>{plugins}</span> Plugins*/}
                            {/*    </p>*/}
                            {/*</li>*/}
                            <li className="company-info-item">
                                <span className="icon-user" />
                                <p>
                                    <span>{members}</span> Members
                                </p>
                            </li>
                        </ul>
                        {/* SOCIAL LINKS */}
                        <ul className="social-links">
                            <li className="social-link fb">
                                <a href="https://www.facebook.com/clerksoftware" target="_blank"/>
                            </li>
                            <li className="social-link twt">
                                <a href="https://twitter.com/clerksoftware" target="_blank"/>
                            </li>
                            <li className="social-link yt">
                                <a href="https://www.youtube.com/@pluginpress" target="_blank"/>
                            </li>
                            <li className="social-link inst">
                                <a href="https://www.instagram.com/clerksoftware/" target="_blank"/>
                            </li>
                        </ul>
                        {/* /SOCIAL LINKS */}
                    </div>
                    {/* /COMPANY INFO */}
                    {/* LINK INFO */}
                    <div className="link-info">
                        <p className="footer-title">Our Community</p>
                        {/* LINK LIST */}
                        <ul className="link-list">
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/register">Register</Link>
                            </li>
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/login">Login</Link>
                            </li>
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/forgotpassword">Restore your password</Link>
                            </li>

                        </ul>
                        {/* /LINK LIST */}
                    </div>
                    {/* /LINK INFO */}
                    {/* LINK INFO */}
                    <div className="link-info">
                        <p className="footer-title">Member Links</p>
                        {/* LINK LIST */}
                        <ul className="link-list">
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/products">Products</Link>
                            </li>
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/supports">Support Packages</Link>
                            </li>
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/plugins">Plugins</Link>
                            </li>

                        </ul>
                        {/* /LINK LIST */}
                    </div>
                    {/* /LINK INFO */}
                    {/* LINK INFO */}
                    <div className="link-info">
                        <p className="footer-title">Help and FAQs</p>
                        {/* LINK LIST */}
                        <ul className="link-list">
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/how-to-shop">How to Shop</Link>
                            </li>

                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/terms-and-conditions" reloadDocument>Terms and Conditions</Link>
                            </li>

                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/privacy-policy" reloadDocument>Privacy Policy</Link>
                            </li>
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                        {/* /LINK LIST */}
                    </div>
                    {/* /LINK INFO */}
                    {/* TWITTER FEED */}
                    <div className="twitter-feed">
                        <p className="footer-title">Feed</p>
                        {/* TWEETS */}
                        <ul className="link-list">
                            <li className="link-item">
                                <div className="bullet"/>
                                <Link to="/blogs">Blogs</Link>
                            </li>
                        </ul>
                        {/* /TWEETS */}
                    </div>
                    {/* /TWITTER FEED */}
                </div>
            </div>
            {/* /FOOTER TOP */}
            {/* FOOTER BOTTOM */}
            <div id="footer-bottom-wrap">
                <div id="footer-bottom">
                    <p>
                        <span>©</span>
                        <a href="https://clerksoftware.com" target="_blank">Clerk Software LTD</a> - All Rights Reserved
                        {` ${new Date().getFullYear()}`}
                    </p>
                </div>
            </div>
            {/* /FOOTER BOTTOM */}
        </footer>

    );
}

export default Footer;