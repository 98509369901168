import React, {useContext} from "react";
import {Link} from "react-router-dom";
import GeneralContext from "../../context/general/GeneralProvider";
import {FaCoins} from "@react-icons/all-files/fa/FaCoins";
const rootURL = process.env.REACT_APP_URL_ROOT;
function ProductsListItem({product}) {
  const {numberWithDot} = useContext(GeneralContext)
  const {name,prices, plugin,type,description,slug} = product;
  let cover = "";
  product.images.map((image) => (cover = image.thumb));

  let pr = 0;
  let cut = "";
  let credits = 0;

  const offCal = (pr,ct) => {
    return 100 - Math.floor((parseInt(pr)*100)/ct)
  }


  if(prices.length>0){
    if(type === "sub"){
      prices.map(pri => {
        if(pri.sub === "monthly"){
          pr = pri.price
          cut = pri.cut>0 ? `-${offCal(pr,pri.cut)}%` : "";
        }
      })
    }else{
      pr = prices[0].price;
      cut = prices[0].cut>0 ? `-${offCal(pr,prices[0].cut)}%` : "";
      credits = prices[0].credits
    }
  }

  return (
    <div className="product-item">
      {product.featured === 1 && <span className="pin" style={{background:"red"}}>&nbsp;&nbsp;{product.ft_content}</span>}
      <Link to={`/product/${slug}`} reloadDocument>
        {/* PRODUCT PREVIEW IMAGE */}
        <figure className="product-preview-image small">
          <img src={`${rootURL}/${cover}`} alt="product-image" style={{height:"100%", width:"120px"}} />
        </figure>
        {/* /PRODUCT PREVIEW IMAGE */}
      </Link>
      {/* PRODUCT INFO */}
      <div className="product-info" style={{paddingLeft:"50px"}}>
        <Link to={`/product/${slug}`} reloadDocument>
          <p className="text-header">{name}</p>
        </Link>
        <br/>

        <Link to={`/product/${slug}`} reloadDocument>
          <p className="category primary" dangerouslySetInnerHTML={{__html:`${description.substring(0,80)}...`}}/>
        </Link>
      </div>
      {/* /PRODUCT INFO */}
      {/* AUTHOR DATA */}
      <div className="author-data">
        {/* USER RATING */}
        <div className="user-rating">
          <Link to={`/plugin/${plugin.slug}`} reloadDocument>
            <figure className="user-avatar small">
              <img src={`${rootURL}/${plugin.icon}`} alt="user-avatar"  />
            </figure>
          </Link>
          <Link to={`/plugin/${plugin.slug}`}>
            <p className="text-header tiny" style={{width:"200px"}}>{plugin.name}</p>
          </Link>
        </div>
        {/* /USER RATING */}
        {/* METADATA */}
        <p style={{fontSize:"11px",width:"200px"}} dangerouslySetInnerHTML={{__html:`${plugin.description.substring(0,80)}...`}}/>
        {/* /METADATA */}
      </div>
      {/* /AUTHOR DATA */}
      {/* ITEM METADATA */}
      <div className="item-metadata">
        <p className="text-header">

        </p>
        <p className="text-header tiny" style={{position:"relative",top:"20px"}}>
          {type==="normal" ? (
              credits === 0 ? "Lifetime pass" : <><FaCoins style={{position:"relative", top:"2px", color:"#f8b204"}}/> {numberWithDot(credits)}</>
          ) : "Monthly Subscription"}
        </p>
        <p className="text-header tiny">

        </p>
      </div>
      {/* ITEM METADATA */}


      {/* PRICE INFO */}
      <div className="price-info">
        <p className="price medium" style={{marginLeft:"50px", width:"120%"}}>
          <span>{pr>0&&'£'}</span>{pr>0 ? (cut ? <>{pr} <span style={{ color:"red"}}>{cut}</span></> : pr) : "Free"}
        </p>
      </div>
      {/* /PRICE INFO */}
    </div>
  );
}

export default ProductsListItem;
