import axios from "axios";

export const getTickets = async (page,header)=>{
    const url = `${process.env.REACT_APP_API_ROOT}/tickets?page=${page}`
    return await axios.get(url,header)
}

export const getTicket = async (id,header) => {
    const url = `${process.env.REACT_APP_API_ROOT}/ticket/${id}`
    return await axios.get(url,header);
}