const seoReducer = (state, action) => {
    switch (action.type){
        case 'GET_BLOGS':
            return {
                ...state,
                blogs: action.payload.data.blogs.data,
                totalPage: action.payload.data.blogs.last_page,
                title:action.payload.data.title,
                loading: false
            }
        case 'SET_LOADING_FALSE':
            return {
                ...state,
                loading:false,
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading:true,
            }
        default:
            return state
    }
}

export default seoReducer