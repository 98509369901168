import React, {useContext, useEffect, useState} from "react";
import ProductsHeadline from "../components/products/ProductsHeadline";
import ProductsNavBar from "../components/products/ProductsNavBar";
import ProductsGridItem from "../components/products/ProductsGridItem";
import ProductsListItem from "../components/products/ProductsListItem";
import ProductsContext from "../context/products/ProductsProvider";
import {getProducts} from "../context/products/ProductsActions";
import Spinner from "../components/Spinner";
import Pagination from "../components/Pagination";
import Seo from "../components/Seo";
import NewsLetter from "../components/NewsLetter";

function Products() {
  const {view,dispatch,loading, products,totalPage} = useContext(ProductsContext)
  const [page,setPage] = useState(1);
  const [search, setSearch] = useState("")

  const getAllProducts = () => {
    try{
      getProducts(search,page).then(res => dispatch({type:'GET_PRODUCTS', payload:res}))
    }catch (err){
      console.log(err)
    }
  }

  useEffect(() => {
    getAllProducts()
  }, [search,page]);



  return loading ? <Spinner/> :(
    <>
      <Seo
          title="All Products - PluginPress.net"
      />
      {/* SECTION HEADLINE */}
      <ProductsHeadline />
      {/* /SECTION HEADLINE */}
      {/* SIDEBAR NAV */}
      <ProductsNavBar />
      {/* /SIDEBAR NAV */}
      {/* SECTION */}
      <div className="section-wrap">
        <div className="section">
          {/* PRODUCT SHOWCASE */}
          <div className="product-showcase">
            {/* PRODUCT LIST */}
            <div className={`product-list ${view === "grid" ? "grid column4-wrap" : "list full"}`}>

              {loading ? <Spinner/> : products.map(product => (


                    view === "grid" ? <ProductsGridItem key={product.id} product={product}/> : <ProductsListItem key={product.id} product={product}/>


                  )
              )}

            </div>
            {/* /PRODUCT LIST */}
          </div>
          {/* /PRODUCT SHOWCASE */}
          <div className="clearfix" />
          {/* PAGER */}
          <Pagination page={page} setPage={setPage} totalPage={totalPage}/>
          {/* /PAGER */}
        </div>
      </div>
      {/* /SECTION */}
        <NewsLetter/>
    </>
  );
}

export default Products;
