import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {FaCoins} from "@react-icons/all-files/fa/FaCoins";
import GeneralContext from "../../context/general/GeneralProvider";
const rootURL = process.env.REACT_APP_URL_ROOT;
const storageUrl = process.env.REACT_APP_URL_ROOT;
function ProductsGridItem({product}) {
  const {numberWithDot} = useContext(GeneralContext)
  const {name,plugin,type,description, prices,slug} = product;
  let pr = 0;
  let cut = "";
  let credits = 0;

  const offCal = (pr,ct) => {
    return 100 - Math.floor((parseInt(pr)*100)/ct)
  }



  if(prices.length>0){
    if(type === "sub"){
      prices.map(pri => {
        if(pri.sub === "monthly"){
          pr = pri.price
          cut = pri.cut>0 ? `${offCal(pr,pri.cut)}%` : "";
        }
      })
    }else{
      pr = prices[0].price;
      credits = prices[0].credits
      cut = prices[0].cut>0 ? `${offCal(pr,prices[0].cut)}%` : "";
    }
  }

  let cover = "";
  product.images.map((image) => (cover = image.thumb));
  return (
      <div className="product-item column">
        {product.featured === 1 && <span className="pin" style={{background:"red"}}>&nbsp;&nbsp;{product.ft_content}</span>}
        {/* PRODUCT PREVIEW ACTIONS */}
        <div className="product-preview-actions">
          {/* PRODUCT PREVIEW IMAGE */}
          <figure className="product-preview-image">
            <img src={`${rootURL}/${cover}`} alt="product-image"/>
          </figure>
          {/* /PRODUCT PREVIEW IMAGE */}
          {/* PREVIEW ACTIONS */}
          <div className="preview-actions">
            {/* PREVIEW ACTION */}
            <div className="preview-action">
              <Link to={`/product/${slug}`} reloadDocument>
                <div className="circle tiny primary">
                  <span className="icon-tag"/>
                </div>
              </Link>
              <Link to={`/product/${slug}`} reloadDocument>
                <p>Go to Item</p>
              </Link>
            </div>
            {/* /PREVIEW ACTION */}
            {/* PREVIEW ACTION */}
            <div className="preview-action">
              <a href="#">
                <div className="circle tiny secondary">
                  <span className="icon-heart"/>
                </div>
              </a>
              <a href="#">
                <p>Favourites +</p>
              </a>
            </div>
            {/* /PREVIEW ACTION */}
          </div>
          {/* /PREVIEW ACTIONS */}
        </div>
        {/* /PRODUCT PREVIEW ACTIONS */}
        {/* PRODUCT INFO */}
        <div className="product-info">
          <Link to={`/product/${slug}`} reloadDocument>
            <p className="text-header">{name}</p>
          </Link>
          <p className="product-description">{description.replace(/(<([^>]+)>)/gi, "").substring(0, 50)}...</p>
          <p className="category primary">{type === "normal" ?
              (credits == 0 ? "Lifetime pass:" : <><FaCoins
                  style={{position: "relative", top: "2px", color: "#f8b204"}}/> {numberWithDot(credits)} Tokens</>)
              : "Monthly Subscription"}</p>
          <p className="price">
            <span>{pr > 0 && '£'}</span>{pr > 0 ? (cut ? <>{pr} <span
              style={{ color:"red"}}>-{cut}</span></> : pr) : "Free"}
          </p>
        </div>
        {/* /PRODUCT INFO */}
        <hr className="line-separator"/>
        {/* USER RATING */}
        <div className="user-rating">
          <Link to={`/plugin/${plugin.slug}`}>
            <figure className="user-avatar small">
              <img src={`${storageUrl}${plugin.icon}`} alt="user-avatar" style={{width: "100%"}}/>
            </figure>
          </Link>
          <Link to={`/plugin/${plugin.slug}`}>
            <p className="text-header tiny">{plugin.name}</p>
          </Link>

        </div>
        {/* /USER RATING */}
      </div>
  );
}

export default ProductsGridItem;
