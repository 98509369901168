import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ProductsContext from "../context/products/ProductsProvider";
import {getLastProducts, getProducts} from "../context/products/ProductsActions";
import ProductsGridItem from "../components/products/ProductsGridItem";
import NewsLetter from "../components/NewsLetter";
import Seo from "../components/Seo";

function Home(props) {
  const {view,dispatch,loading, lastProducts,totalPage} = useContext(ProductsContext)
  const [page,setPage] = useState(1);
  const [search, setSearch] = useState("")

  const getAllProducts = () => {
    try{
      getLastProducts().then(res => dispatch({type:'GET_LAST_PRODUCTS', payload:res}))
    }catch (err){
      console.log(err)
    }
  }

  useEffect(() => {
    getAllProducts()
  }, [search,page]);
  return (
    <>
      <Seo
          desc="Enhance Your Plugins, Empower Your WordPress: Discover the Premium Features of PluginPress.net!"
          keywords="Wordpress, Plugins, Wordpress Plugins, movie database, movie database plugin, premium plugins"
      />
      {/* BANNER */}
      <div className="banner-wrap">
        <section className="banner">
          <h5>Welcome to</h5>
          <h1>
            Wordpress <span>Marketplace</span>
          </h1>
          <p>
            Enhance Your Plugins, Empower Your WordPress: Discover the Premium Features of PluginPress.net!
          </p>
          <img src="images/top_items.png" alt="banner-img" />
          {/* SEARCH WIDGET */}
          {/*<div className="search-widget">*/}
          {/*  <form className="search-widget-form">*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="category_name"*/}
          {/*      placeholder="Search goods or services here..."*/}
          {/*    />*/}
          {/*    <label htmlFor="categories" className="select-block">*/}
          {/*      <select name="categories" id="categories">*/}
          {/*        <option value={0}>All Categories</option>*/}
          {/*        <option value={1}>PSD Templates</option>*/}
          {/*        <option value={2}>Hero Images</option>*/}
          {/*        <option value={3}>Shopify</option>*/}
          {/*        <option value={4}>Icon Packs</option>*/}
          {/*        <option value={5}>Graphics</option>*/}
          {/*        <option value={6}>Flyers</option>*/}
          {/*        <option value={7}>Backgrounds</option>*/}
          {/*        <option value={8}>Social Covers</option>*/}
          {/*      </select>*/}
          {/*      /!* SVG ARROW *!/*/}
          {/*      <svg className="svg-arrow">*/}
          {/*        <use xlinkHref="#svg-arrow" />*/}
          {/*      </svg>*/}
          {/*      /!* /SVG ARROW *!/*/}
          {/*    </label>*/}
          {/*    <button className="button medium dark">Search Now!</button>*/}
          {/*  </form>*/}
          {/*</div>*/}
          {/* /SEARCH WIDGET */}
        </section>
      </div>
      {/* /BANNER */}
      {/* SERVICES */}
      <div id="services-wrap">
        <section id="services">
          {/* SERVICE LIST */}
          <div className="service-list column4-wrap">
            {/* SERVICE ITEM */}
            <div className="service-item column">
              <div className="circle medium gradient" />
              <div className="circle white-cover" />
              <div className="circle dark">
                <span className="icon-present" />
              </div>
              <h3>Start to buy</h3>
              <p>
                You can buy our products easly and start to use directly.
              </p>
            </div>
            {/* /SERVICE ITEM */}
            {/* SERVICE ITEM */}
            <div className="service-item column">
              <div className="circle medium gradient" />
              <div className="circle white-cover" />
              <div className="circle dark">
                <span className="icon-lock" />
              </div>
              <h3>Secure Transaction</h3>
              <p>
                You can buy our products via Stripe.
              </p>
            </div>
            {/* /SERVICE ITEM */}
            {/* SERVICE ITEM */}
            <div className="service-item column">
              <div className="circle medium gradient" />
              <div className="circle white-cover" />
              <div className="circle dark">
                <span className="icon-like" />
              </div>
              <h3>Enter your Api-Key</h3>
              <p>
                Enter your api-key in your plugin.
              </p>
            </div>
            {/* /SERVICE ITEM */}
            {/* SERVICE ITEM */}
            <div className="service-item column">
              <div className="circle medium gradient" />
              <div className="circle white-cover" />
              <div className="circle dark">
                <span className="icon-diamond" />
              </div>
              <h3>Start to use</h3>
              <p>
                Directly activate the product you purchased.
              </p>
            </div>
            {/* /SERVICE ITEM */}
          </div>
          {/* /SERVICE LIST */}
          <div className="clearfix" />
        </section>
      </div>
      {/* /SERVICES */}
      {/* PROMO */}
      <div className="promo-banner dark left">
        <span className="icon-wallet" />
        <h5>GET YOUR API-KEY INSTANTLY!</h5>
        <h1>
          CREATE AN <span>ACCOUNT</span>
        </h1>
        <Link to="/register" className="button medium primary">
          Register NOW!
        </Link>
      </div>
      {/* /PROMO */}
      {/* PROMO */}
      <div className="promo-banner secondary right">
        <span className="icon-tag" />
        <h5>Find anything you want</h5>
        <h1>Start Buying</h1>
        <Link to="/products" className="button medium dark">
          Look at Products!
        </Link>
      </div>
      {/* /PROMO */}
      <div className="clearfix" />
      {/* PRODUCT SIDESHOW */}
      <div className="section-wrap">
        <div className="section">
          {/* PRODUCT SHOWCASE */}
          <div className="product-showcase">
            {/* HEADLINE */}
            <div className="headline primary">
              <h4>Latest Online Products</h4>

            </div>
            {/* /HEADLINE */}
            {/* PRODUCT LIST */}
            <div
              className="product-list grid column4-wrap"
            >
              {lastProducts?.length>0 && lastProducts.map(product => (
                  <ProductsGridItem key={product.id} product={product}/>
              ))}
            </div>
            {/* /PRODUCT LIST */}

          </div>
          {/* /PRODUCT SHOWCASE */}


        </div>
      </div>
      {/* /PRODUCTS SIDESHOW */}
      {/* SUBSCRIBE BANNER */}
      <NewsLetter/>
      {/* /SUBSCRIBE BANNER */}
    </>
  );
}

export default Home;
