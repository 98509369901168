import axios from "axios";


export const getTagBlogs = async (conn,slug,page) => {
    const url = `${process.env.REACT_APP_API_ROOT}/articletag/${slug}?page=${page}`
    return await axios.post(url, {conn});
}

export const getCategoryBlogs = async (conn,slug,page) => {
    const url = `${process.env.REACT_APP_API_ROOT}/articlecategory/${slug}?page=${page}`
    return await axios.post(url, {conn});
}