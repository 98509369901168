import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import AuthContext from "../context/auth/AuthProvider";
import axios from "axios";

const apiLink = process.env.REACT_APP_API_ROOT;

function Cancel() {
    const {auth,header} = useContext(AuthContext)
    const navigate = useNavigate();


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if(auth?.token){
            if(query.get("key")){
                const key = query.get("key");
                const url = `${apiLink}/stripe/cancel/${key}`
                axios.get(url,header).then(res => {
                    if(res?.data?.message !=="ok"){
                        navigate('/')
                    }
                }).catch(err => navigate('/'))
            }else{
                navigate('/')
            }
        }else{
            navigate('/')
        }
    }, []);


    return (
        <div className="section-wrap">
            <div className="section">
                {/* FORM BOX ITEM */}
                <div className="form-box-item">
                    <h1 style={{color:"#0a0a0a"}}>Order canceled</h1>
                    <div style={{textAlign:"center"}}>Continue to shop around and checkout when you're ready.</div>
                </div>
                {/* /FORM BOX ITEM */}

            </div>
        </div>

    );
}

export default Cancel;