import axios from "axios";

export const staticArticle = async (key) => {
    const url = `${process.env.REACT_APP_API_ROOT}/getstatic`
    const res = await axios.post(url,{key});
    return res.data;
}

export const getBlogs = async (conn,page) => {
    const url = `${process.env.REACT_APP_API_ROOT}/blogs?page=${page}`
    return await axios.post(url, {conn});
}

export const getBlog = async (slug) => {
    const url = `${process.env.REACT_APP_API_ROOT}/blog/${slug}`;
    return await axios.get(url);
}