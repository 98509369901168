import React from 'react';
const rootURL = process.env.REACT_APP_URL_ROOT;

function TicketMessageUser({msg}) {
    return (
        <span className="message-preview">
                    <figure className="user-avatar">
                      <img
                          src={`${rootURL}/${msg.user.avatar}`}
                          alt="user-avatar"
                      />
                    </figure>
                    <p className="text-header">{msg.user.name}</p>
                    <p className="timestamp">{new Date(msg.created_at).toDateString()} - {new Date(msg.created_at).getHours()}:{new Date(msg.created_at).getMinutes()}</p>
                    <p dangerouslySetInnerHTML={{__html:msg.message}}/>
        </span>

    );
}

export default TicketMessageUser;