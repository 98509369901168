import React, {useContext, useEffect, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import {BiLoaderCircle} from "@react-icons/all-files/bi/BiLoaderCircle";
import AuthContext from "../context/auth/AuthProvider";
import isStrongPassword from "validator/lib/isStrongPassword";
import {toast} from "react-toastify";
import axios from "axios";
import Seo from "../components/Seo";

function ResetPassword() {
    const {auth} = useContext(AuthContext)
    const params = useParams()
    const [password, setPassword] = useState("")
    const [password_confirmation, setPassword2] = useState("")
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const linkCheck =  () => {
        const url = `${process.env.REACT_APP_API_ROOT}/resetlinkcheck`
        const code = params.code;

        try{
            axios.post(url,{code}).then(res => {
                switch (res.data.status){
                    case 'notvalid':
                        toast.error('The link is not valid')
                        setDisabled(true)
                        break;
                    case 'expired':
                        toast.error('Your link expired')
                        setDisabled(true)
                        break;
                    case 'ok':
                        setDisabled(false)
                        break;
                }
            })
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        linkCheck()
    },[])

    const resetPassword = async (e) => {
        e.preventDefault()
        if(disabled){
            return toast.error('This link cannot be used!')
        }
        setLoading(true)
        if(password !== password_confirmation){
            setLoading(false)
            return toast.warning('Password and repeat password do not match!')
        }
        if(!isStrongPassword(password)){
            setLoading(false)
            return  toast.warning(
                "The password must be 8 digits and contain a minimum of one lowercase letter, one uppercase letter, one number and one special character."
            );
        }

        try{
            const url = `${process.env.REACT_APP_API_ROOT}/resetpassword`
            const res = await axios.post(url,{password, code:params.code})
            toast.success(res.data.message);
            setDisabled(true)
            setLoading(false)
        }catch(err){
            toast.error(err.response.data.message)
            setLoading(false)
        }
        setTimeout(()=>{
            document.getElementById('hiddenResetLink').click()
        },2000)
    }


    useEffect(() => {
        console.log(params.code)
    }, []);


    //Middleware redirect
    if (auth) {
        return <Navigate to="/account"/>
    }

    return (
        <>
            <Seo
                title={`Reset your password - PluginPress.net`}
            />
            <div className="section-wrap">
                <Link to="/login" id="hiddenResetLink" reloadDocument style={{display:"none"}}/>
                <div
                    className="form-popup"
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                >

                    {/* FORM POPUP CONTENT */}
                    <div className="form-popup-content">
                        <h4 className="popup-title">Reset your Password</h4>
                        {/* LINE SEPARATOR */}
                        <hr className="line-separator short" />
                        {/* /LINE SEPARATOR */}
                        <div id="restore-pwd-form">
                            <label htmlFor="email_address" className="rl-label">
                                Password
                            </label>
                            <input
                                type="password"
                                value={password}
                                disabled={disabled}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Enter your email address..."
                            />
                            <label htmlFor="email_address" className="rl-label">
                                Repeat Password
                            </label>
                            <input
                                type="password"
                                disabled={disabled}
                                value={password_confirmation}
                                onChange={e => setPassword2(e.target.value)}
                                placeholder="Enter your email address..."
                            />

                            <button className="button mid dark no-space" onClick={resetPassword}>
                                {!loading ? <>Reset your <span className="primary">Password!</span></> : <span className="primary"><BiLoaderCircle className="rotating"/></span>}
                            </button>
                        </div>
                    </div>
                    {/* /FORM POPUP CONTENT */}
                </div>
            </div>
        </>
    );
}

export default ResetPassword;