import axios from "axios";

export const pluginPressLogin  = async (email,password) => {
    const url = `${process.env.REACT_APP_API_ROOT}/login`
    const [res] = await Promise.all([axios.post(url, {email, password})])
    return res.data;
}

export const pluginPressRegister = async (name, email, password, password_confirmation) => {
    const url = `${process.env.REACT_APP_API_ROOT}/register`
    const [res] = await Promise.all([axios.post(url, {name, email, password, password_confirmation})])
    return res.data;
}

